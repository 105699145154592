.btn-state {
	position: relative;
	cursor: pointer;
	display: flex;
	align-items: center;
	overflow: hidden;
	height: 2.285rem;
	color: var(--ap-grey-300);
	pointer-events: none;

	ap-icon {
		font-size: 1.5rem;
		margin-right: 0.5rem;
		margin-left: 0.5rem;
	}

	&.validate {
		color: var(--ap-green-400);
		font-weight: bold;
	}

	&.current {
		color: var(--ap-green-600);
		font-weight: bold;

		path:not([fill="none"]) {
			stroke: green;
			stroke-width: 3px;
		}
	}

	&.next {
		color: var(--ap-black);
		border-radius: 10px;
		pointer-events: all;

		&:hover {
			background-color: var(--ap-grey-100);

			.action {
				left: 0;
				opacity: 1;
			}

			.state {
				opacity: 0;
			}
		}
	}

	.state {
		opacity: 1;
		transition: opacity 0.2s;
		display: flex;
		align-items: center;
	}

	.action {
		z-index: 10;
		transition: left 0.8s, opacity 0.8s;
		position: absolute;
		top: 0;
		left: -100%;
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 10px;
		opacity: 0;
	}
}

#new-state,
#toValidate {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: larger;

	span {
		font-weight: 600;
	}
}

#notif-container {

    width: 30rem;
    padding: 1rem;
    display: none;
    transition: right 500ms;
    overflow: hidden;
    box-shadow: 0px 0px 10px -5px #000000;
    border-radius: 10px;
    background-color: white;
    color: black;
    flex-direction: column;
    margin: 10px;

    &.active {
        display: flex;
        right: 0px;
    }

    #filter {

        display: flex;
        color: #acacac;

        margin-left: 0.25rem;
        margin-right: 0.25rem;

        [data-filter] {
            cursor: pointer;
            text-align: center;

            border-bottom: 2px solid #acacac;
            font-weight: bold;

            padding: 0.5rem;

            display: flex;
            align-items: center;
            justify-content: center;

            flex: 1 1 auto;

            .badge {
                margin-left: 5px;
                background: #F2F2F2;
                color: #acacac;
                border-radius: 5px;
            }

            &:hover,
            &.active {
                border-bottom-color: black;
                color: black;

                .badge {
                    background: black;
                    color: white;
                }
            }

        }
    }

    .notif-item {
        position: relative;
        display: flex;
        width: 100%;
        border-bottom: 1px solid #e3e3e3;
        border-radius: 10px;
        padding: 0.5rem;
        padding: 1rem;
        margin-bottom: 0.5rem;
        cursor: pointer;

        &:hover {
            background-color: #e3e3e3;
        }

        #title {
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: bold;
        }

        #description,
        #createBy,
        #subTitle {
            font-size: 0.875rem;
            font-weight: 600;
            width: 100%;
        }

        #description,
        #createBy {
            color: #717171;
        }
    }


    #close {
        right: 1rem;
        top: 1rem;
    }

}

.mobile {

    #notif-container {
        top: 0;
        width: calc(100vw - 20px);
    }

}
.price-quote {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--ap-green-600);
}

.title-page {
  font-size: 1rem;
  font-weight: 700;
  color: var(--ap-grey-800);
}
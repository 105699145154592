html {
    scrollbar-color: #787878 #f8f8f8;

    ::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: var(--ag-header-background-color, #f8f8f8);
        padding: 5px;
    }

    ::-webkit-scrollbar {
        background-color: var(--ag-header-background-color, #f8f8f8);
        padding: 5px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        border: 5px solid var(--ag-header-background-color, #f8f8f8);
        background: rgb(120, 120, 120);
    }

    ::-webkit-scrollbar-thumb:vertical {
        min-height: 50px;
    }

    ::-webkit-scrollbar-thumb:horizontal {
        min-width: 50px;
    }
}
[modal="addEditCompanies"] {



    .modal-body {
        overflow: auto;

        &::-webkit-scrollbar {
            width: 7px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.4);
            border-radius: 10rem;
            border: 1px solid #fff;
        }

        &::-webkit-scrollbar-track-piece:start {
            background: transparent;
        }

        &::-webkit-scrollbar-track-piece:end {
            background: transparent;
        }

    }

    .companies-modal-block {
        &:not(:last-child) {
            margin-bottom: 2rem;
        }

        display: flex;
        flex-direction: column;
    }

    .companies-modal-block-header {
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #DADADA;
        height: 40px;
        display: flex;
        align-items: center;

        .companies-modal-invalid-message {
            color: #dc3545;
        }
    }

    .companies-modal-block-title {
        font-size: 22px;
        font-weight: 500;
    }

    .companies-modal-block-body {
        display: flex;
        flex-direction: column;
        grid-gap: 0.5rem;
        gap: 0.5rem;
    }

    .companies-modal-inputs-row {
        display: flex;
        padding: 0.5rem 0;
        gap: 1.5rem;
    }

    .companies-modal-input-container {
        display: flex;
        flex-direction: column;
        width: 100%;

        .companies-modal-input-label {
            font-weight: 600;
        }
    }

    #company-logo-file {
        border-radius: 0.5rem !important;
    }

    #company-logo-file-label {
        cursor: pointer;
        margin-bottom: 0;
    }

    #company-logo-preview {
        height: 200px;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 1rem;
        align-items: center;
        color: #7c7c7c;
        border: 1px dashed #d1d1d1;
        border-radius: 0.5rem;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    #company-address-list {
        height: 250px;
        width: 100%;

        &.invalid {
            .ag-root-wrapper {
                border-color: #dc3545;
                color: #dc3545;
            }

            .ag-header {
                border-bottom-color: #dc3545;
            }
        }
    }

    #no-address-message {
        width: 100%;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .footer-information-text {
        font-size: 14px;
        color: #555555;
        margin-right: auto;
        margin-left: 1.25rem;
    }

    .cell-invalid {
        background-color: #dc3545;
    }

}
// (va = vertical accordion)

.va-tab-container {
    box-shadow: #0000001a 0px 1px 2px 0px;
    padding: 1.5rem 1rem;
    cursor: pointer;
    height: 100%;
    background-color: white;

}

.va-tab-title {
    font-weight: 600;
    font-size: 20px;
}

.va-tab-subtitle {
    font-weight: 600;
    font-size: 17px;
}
#changelog-container {
    code {
        color: #986200;
        font-weight: bold;
        font-size: 1rem;
    }
    h1 {
        font-size: 2rem;
    }
    h2 {
        font-size: 1.5rem;
    }
    ul {
        font-size: 0.9rem;
    }
}

[page="users-additional-informations"] {
	.edit-modal-block {
		&:not(:last-child) {
			margin-bottom: 2rem;
		}

		display: flex;
		flex-direction: column;
	}

	.edit-modal-block-header {
		margin-bottom: 1rem;
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #dadada;
		height: 40px;
		display: flex;
		align-items: center;

		.edit-modal-invalid-message {
			color: #dc3545;
		}
	}

	.edit-modal-block-title {
		font-size: 22px;
		font-weight: 500;
	}

	.edit-modal-block-body {
		display: flex;
		flex-direction: column;
		grid-gap: 0.5rem;
		gap: 0.5rem;
	}

	.edit-modal-input-container {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		align-items: center;
		white-space: nowrap;
		min-height: 2rem;

		&.disabled {
			opacity: 0.7;
		}

		.edit-modal-input-label {
			margin-right: 1rem;
		}
	}
}

#settings-page {
	#page-content {
		#tabs-nav {
			//FIXME: A corriger dans le core v3
			.tab-active {
				background-color: #3475f8 !important;
				color: white;
			}
		}
		#tabs-content {
			padding: 1rem;
			background-color: white;
			#bank-settings-tab {
				display: flex;
				flex-direction: column;
				gap: 1rem;
				height: 100%;
			}
		}
	}
}

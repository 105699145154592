#calendarList{

    list-style: none;
    padding: 0;
    height: 100%;

    .calendars-item{
        position: relative;

        .button-action{

            position: absolute;
            background-color: white;
            right: 0;
            top: 0;
            margin-top: 0.25rem;
            opacity: 0;

        }

        &:hover{
            .button-action{
                opacity: 1;
            }
        }

    }

    ul{
        list-style: none;
        padding: 0;
        padding-left:15px;

    }
}

.tui-full-calendar-weekday-schedule:not(.tui-full-calendar-weekday-schedule-time){
    border-width : 1px !important;
    border-style : solid !important;
}

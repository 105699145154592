#players-container {

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;

}

.player {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    overflow: hidden;

    .picture {
        background-color: var(--ap-blue-300);
        color: white;
        font-size: 2.5rem;
        border: 2px solid white;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.checked {
            background-color: var(--ap-blue-800);

        }
    }

    .name {
        color: var(--ap-grey-400);
        font-size: 0.85rem;

        white-space: nowrap;
        display: inline-block;
        width: 100%;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
    }

}


.deck {
    display: flex;
    justify-content: center;

    .deck-card {

        position: relative;

        height: 50px;
        width: 30px;
        border: 2px solid var(--ap-teal-500);
        background-color: var(--ap-teal-200);

        border-radius: 5px;
        line-height: 50px;
        text-align: center;
        font-weight: bold;
        color: white;
        margin-left: 2px;
        margin-right: 2px;

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            text-align: center;
            height: 100%;
            width: 100%;
            content: ' ';

        }

        &.jocker {
            &:before {
                background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMy4wLjIsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDIwOS41IDE1Ni4zIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyMDkuNSAxNTYuMzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I0ZGRkZGRjt9DQo8L3N0eWxlPg0KPGc+DQoJPHBhdGggY2xhc3M9InN0MCIgZD0iTTE3OSw5NC45Yy0yLjksMC01LjQtMS40LTcuMS0zLjVjLTEwLjUsNi45LTIyLjksMjAuMi0yMi45LDQ0LjRjMCwwLTE2LjMtMTQuNi00NC4xLTE0LjZjMCwwLTAuMSwwLTAuMSwwDQoJCXMtMC4xLDAtMC4xLDBjLTI3LjcsMC00NC4xLDE0LjYtNDQuMSwxNC42YzAtMjQuMi0xMi41LTM3LjUtMjMtNDQuNGMtMS42LDIuMi00LjIsMy42LTcuMSwzLjZjLTQuOSwwLTguOS00LTguOS04LjkNCgkJYzAtNC45LDQtOC45LDguOS04LjljNC4zLDAsOCwzLjEsOC43LDcuMmMyMS4zLDMuMiwzNi4xLDE5LjUsMzYuMSwxOS41YzAtMjQuNiwxNy45LTU0LjYsMjUuOC02Ni41Yy0zLTEuNC01LTQuNS01LTgNCgkJYzAtNC45LDQtOC45LDguOS04LjljNC45LDAsOC45LDQsOC45LDguOWMwLDMuNi0yLjIsNi43LTUuMyw4LjFjNy45LDEyLDI1LjcsNDEuOSwyNS43LDY2LjRjMCwwLDE0LjctMTYuMiwzNi0xOS41DQoJCWMwLjctNC4xLDQuNC03LjMsOC43LTcuM2M0LjksMCw4LjksNCw4LjksOC45QzE4Ny45LDkwLjksMTgzLjksOTQuOSwxNzksOTQuOXoiLz4NCjwvZz4NCjwvc3ZnPg0K") no-repeat center center;
            }
        }

        &.two {
            &:before {
                content: '2';
            }
        }
    }
}

.right-arrow{
    font-size: 4em;
    line-height: 0.5;
    text-align: center;
}

.rank-winner-1 {
    background-color: #FEE101;
}

.rank-winner-2 {
    background-color: #D7D7D7;
}

.rank-winner-3 {
    background-color: #A77044;
}

.rank-looser-1 {
    background: #3C4A3E
}

.rank-looser-2 {
    background: #4D6D7B
}

.rank-looser-3 {
    background: #74605B
}
[page="users-profile-picture"] {
    #profile-picture-file-picker {
        display: flex;
        padding: 0.5rem 0;
        grid-gap: 1.5rem;
        gap: 1.5rem;
        margin-bottom: 1rem;
    }

    #profile-picture-file {
        border-radius: 0.5rem !important;
    }

    #profile-picture-file-label {
        cursor: pointer;
        margin-bottom: 0;
    }

    #profile-picture-preview {
        height: 200px;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 1rem;
        align-items: center;
        color: #7c7c7c;
        border: 1px dashed #d1d1d1;
        border-radius: 0.5rem;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}
#trackingProductHistory {
	.modal-body {
		display: flex;
		flex-direction: column;
		gap: 20px;
		background-color: var(--ap-grey-100);

		.infos {
			margin-left: 1rem;
			margin-right: 1rem;

			.tracking-number-infos {
				:first-child {
					white-space: nowrap;
					margin: 0;
					flex-shrink: 1;
				}
				display: flex;
				align-items: center;
				gap: 10px;
			}
		}

		#historyContainer {
			display: flex;
			margin-left: 1rem;
			margin-right: 1rem;
			.lines {
				margin-top: 6px;
				transform: translateY(65px);

				.dot {
					width: 14px;
					height: 14px;
					background: var(--ap-blue);
					border-radius: 7px;
				}

				.line {
					height: 180px;
					width: 2px;
					background: var(--ap-grey-400);
					margin-left: 5.3px;
				}
			}

			.cards {
				margin-left: 12px;
				width: 100%;
				display: flex;
				flex-direction: column;
				gap: 20px;
			}
		}
	}
}

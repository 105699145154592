ap-select2-button {
    display: block;
    height: calc(1.5em + 0.75rem + 2px);

    #container_form {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: start;
    }

    #container_select {
        height: 100%;
        flex-wrap: nowrap !important;
        flex-direction: column !important;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;
    }

    .select2-container {
        height: 100%;
    }
}
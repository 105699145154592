.home-tactile {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

}

.home-header {
    display: flex;
    width: 100%;

    .society-container {
        width: 100%;
        display: flex;
        justify-content: start;

        .society-logo {
            #society-logo-img {
                width: auto;
                height: 100px;
            }
        }

        .society-app-name-container {
            display: flex;
            align-items: center;

            .society-app-name {
                margin-right: 0.5rem;
                font-size: 30px;
                font-weight: 700;

                #separator {
                    font-weight: 400;
                }
            }

            #version {
                font-size: 25px;
                font-family: monospace;
                font-weight: 600;
            }
        }
    }

    .time-container {
        width: 100%;
        display: flex;
        justify-content: center;

        #day {
            font-size: 30px;
            font-weight: 600;
        }
    }

    .user-container {
        width: 100%;
        display: flex;
        justify-content: end;

        .user-inner-container {
            margin-left: auto;

            .user-infos {
                padding: 0 0 1.5rem 0;
                display: flex;
                align-items: center;

                .user-infos-values {
                    margin-right: 1.5rem;

                    #user {
                        font-weight: 700;
                        font-size: 30px;
                    }

                    #badge {
                        font-weight: 600;
                        font-size: 20px;
                        color: #646464;
                    }
                }

                .user-infos-icon-container {
                    i {
                        font-size: 50px;
                    }
                }
            }

            .user-logout-button {
                width: fit-content;
                margin-left: auto;
            }
        }
    }
}

.home-body {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;




    .menu-container {
        padding: 1rem;
        border: 3px solid black;
        border-radius: 50px;
        min-height: 400px;
        min-width: 800px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;

        .menu-title {
            display: flex;
            position: absolute;
            background: #F1F5F8;
            top: -24px;
            left: 60px;
            font-size: 32px;
            padding: 0rem 1rem;
            font-weight: 700;

            .menu-title-icon {
                margin-right: 1rem;
                font-size: 34px
            }
        }


        .menu-item {
            padding: 1.5rem 4rem 3rem 4rem;

            .menu-item-icon-container {
                i {
                    font-size: 150px;
                    width: 100%;
                    text-align: center;
                }
            }

            .menu-item-button {
                min-width: 200px;
                font-weight: 700;
                text-align: center;
            }
        }
    }
}

.home-footer {
    display: flex;
    width: 100%;

    .home-footer-buttons {
        margin-left: auto;
        display: flex;
    }

    #connect {
        margin-left: 1.5rem;
    }
}

.home-tactile-button {
    padding: 0.65rem 2.15rem;
    font-size: 20px;
    font-weight: 700;
    border-radius: 100px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
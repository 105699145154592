ap-text-editable {
	width: 100%;
	.container {
		display: flex;
		align-items: center;
		gap: 5px;
		padding: 0;
		min-height: 38px;

		:first-child {
			flex-grow: 1;
			margin: 0;
		}

		:last-child {
			gap: 0;
		}
	}
}

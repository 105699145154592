[page="users-companies"] {

    #companies-switch-all {
        .company-switch-label {
            font-weight: 700;
        }
    }

    .companies-switches {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .company-switch {
        display: flex;
        justify-content: space-between;
        padding: 0.75rem 0.5rem;

        cursor: pointer;

        &:not(:last-child) {
            border-bottom: 1px solid #dadada;
        }
    }

    .company-switch-label {
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .footer-invalid-text {
        margin-right: auto;
        margin-left: auto;
        color: red;
    }
}
/** On charge la police avant, sinon elle n'a pas le temps de charger à l'impression **/
.pagePrint {
	font-family: "Arial" !important;
}

@media print {

	body,
	body * {
		visibility: hidden;
	}

	@page {
		margin: 1cm 1cm 1cm 1cm;
	}

	.containerPrint {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		.pagePrint {
			position: relative;
			page-break-after: always;
			page-break-inside: avoid;
			height: 100%;
		}
	}

	.tooltip,
	.popover {
		display: none;
	}

	.containerPrint,
	.containerPrint * {
		visibility: visible !important;
	}
}
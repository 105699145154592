.custom-switch {
    /* for sm */
    &.custom-switch-sm {
        .custom-control-label {
            padding-left: 1rem;
            padding-bottom: 1rem;
        }

        .custom-control-label::before {
            height: 1rem;
            width: calc(1rem + 0.75rem);
            border-radius: 2rem;
        }

        .custom-control-label::after {
            width: calc(1rem - 4px);
            height: calc(1rem - 4px);
            border-radius: calc(1rem - (1rem / 2));
        }

        .custom-control-input:checked ~ .custom-control-label::after {
            transform: translateX(calc(1rem - 0.25rem));
        }
    }

    /* for md */
    &.custom-switch-md {
        .custom-control-label {
            padding-left: 2rem;
            padding-bottom: 1.5rem;
        }

        .custom-control-label::before {
            height: 1.5rem;
            width: calc(2rem + 0.75rem);
            border-radius: 3rem;
        }

        .custom-control-label::after {
            width: calc(1.5rem - 4px);
            height: calc(1.5rem - 4px);
            border-radius: calc(2rem - (1.5rem / 2));
        }

        .custom-control-input:checked ~ .custom-control-label::after {
            transform: translateX(calc(1.5rem - 0.25rem));
        }
    }

    /* for lg */
    &.custom-switch-lg {
        .custom-control-label {
            padding-left: 3rem;
            padding-bottom: 2rem;
        }

        .custom-control-label::before {
            height: 2rem;
            width: calc(3rem + 0.75rem);
            border-radius: 4rem;
        }

        .custom-control-label::after {
            width: calc(2rem - 4px);
            height: calc(2rem - 4px);
            border-radius: calc(3rem - (2rem / 2));
        }

        .custom-control-input:checked ~ .custom-control-label::after {
            transform: translateX(calc(2rem - 0.25rem));
        }
    }

    /* for xl */
    &.custom-switch-xl {
        .custom-control-label {
            padding-left: 4rem;
            padding-bottom: 2.5rem;
        }

        .custom-control-label::before {
            height: 2.5rem;
            width: calc(4rem + 0.75rem);
            border-radius: 5rem;
        }

        .custom-control-label::after {
            width: calc(2.5rem - 4px);
            height: calc(2.5rem - 4px);
            border-radius: calc(4rem - (2.5rem / 2));
        }

        .custom-control-input:checked ~ .custom-control-label::after {
            transform: translateX(calc(2.5rem - 0.25rem));
        }
    }
}

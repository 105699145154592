.popover {
    border-radius: 10px;

    .popover-header {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
}

ap-header-dashboard {

    display: flex;
    align-items: center;

    .container-popover-infos {

        position: relative;
        display: flex;
        align-items: center;
        margin-top: 4px;
        margin-left: 0.5rem;

        .popover-infos {
            display: block;
            opacity: 0;
            position: absolute;
            top: -1rem;
            width: max-content;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
            left: 25px;
            z-index: 1;
            background-color: white;
            padding-left: 1rem;
            padding-right: 1rem;
            border-radius: 10px;
            color: black;
            font-size: 0.875rem;
            transition: opacity 500ms;
        }

        &.active {
            .popover-infos {
                opacity: 1;
            }
        }

    }

}
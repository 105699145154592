#mobile-quotes-container {
    background: #f2f2f2;
}

.mobile-tabs-container {
    .h5.d-inline {
        font-size: 15px;
        font-weight: 600;
        line-height: 1.5rem !important;
    }
}

.mobile-quote-group {

    &:not(:last-child) {
        margin-bottom: 1rem;
    }

    &.opened {
        .mobile-quote-group-header {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .mobile-quote-group-content {
            max-height: fit-content;
            transition: max-height 0.2s ease-out;
        }
    }

}

.mobile-quote-group-header {
    background-color: #3586FF;
    color: white;
    padding: 1rem;
    position: relative;
    border-radius: 10px;
}

.mobile-quote-group-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 1px 1px 0px;
}

.mobile-quote-group-detail {
    background-color: white;
    color: #343a40;
    padding: 1rem;
    border-bottom: 1px solid #f2f2f2;

}


.mobile-quote-group-category {
    font-weight: 700;
    font-size: 15px;
}

.mobile-quote-group-subcategory {
    font-weight: 600;
    font-size: 14px;
}

.mobile-quote-group-name {
    font-weight: 600;
    font-size: 14px;
    white-space: pre-line;
}

.mobile-quote-group-price {
    font-size: 16px;
    font-weight: 600;
}

.mobile-quote-group-quantity-label {
    font-size: 13px;
    opacity: 0.8;
}

.mobile-quote-group-quantity-value {
    font-size: 13px;
    font-weight: 800;
}

.mobile-quote-group-code {
    font-weight: 700;
    font-size: 15px;
}

.mobile-quote-group-label {
    font-weight: 600;
    font-size: 14px;
    white-space: pre-line;
}

.mobile-quote-accordion-icon-container {
    color: white;
    text-align: center;

}
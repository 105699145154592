#recently-accessed-container {
    background-color: white;
    border-radius: 0.25rem;
    box-shadow: 0 10px 40px 0 #3e396b12, 0 2px 9px 0 #3e396b0f;
}



@media screen and (max-width: 1800px) {
    #recently-accessed-container {
        width: 550px !important;
    }
}

.recent-item {
    background-color: #F1F5F8;
    border-radius: 0.25rem;

    &:hover {
        cursor: pointer;
        background-color: #eef7ff;
        box-shadow: 0 1px 4px 0 #8bd3ff87;
    }
}



#container ::-webkit-scrollbar-track {
    border-radius: 10px;
    padding: 5px;
    background: transparent;
}

#container ::-webkit-scrollbar {
    padding: 5px;
    background: transparent;
}

#container ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 5px solid white;
    background: rgb(120, 120, 120)
}

#container ::-webkit-scrollbar-thumb:vertical {
    min-height: 50px;
}

#container ::-webkit-scrollbar-thumb:horizontal {
    min-width: 50px;
}
.quickaccess-card {
    position: relative;
    border-radius: 0.25rem;
    box-shadow: 0 10px 40px 0 #3e396b12, 0 2px 9px 0 #3e396b0f;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    width: 270px;
    height: 120px;
    background-color: white;
    transition: 0.2s;
}

.quickaccess-icon {
    border-radius: 0.25rem;
    box-shadow: 0 10px 40px 0 #3e396b12, 0 2px 9px 0 #3e396b0f;
}

.quickaccess-icon.interventions-icon {
    color: #4085eb;
    background-color: #dce9fd;
}

.quickaccess-icon.quotes-icon {
    color: #d33c3c;
    background-color: #ffdbdb;
}

.quickaccess-icon.quotes-to-do-icon {
    color: #d33c3c;
    background-color: #ffdbdb;
}

.quickaccess-icon.stock-icon {
    color: #64DD17;
    background-color: #dcffda;
}

.quickaccess-card:hover {
    cursor: pointer;
    background-color: #f6fdff;
}

.quickaccess-link-icon {
    position: absolute;
    top: 2px;
    right: 5px;
    color: grey;
}
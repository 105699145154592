#select-multiple-modal-header {
    flex-direction: column;
    align-items: unset;
    justify-content: unset;
}

#select-multiple-modal-header-top {
    display: flex;
    align-items: center;
    justify-content: center;
}

#select-multiple-modal-header-bottom {
    padding: 1rem 0.25rem 0 0.25rem;

    #filter-search {
        display: flex;
        margin-bottom: 0.5rem;
        background-color: #f2f2f2;
        padding: 0.3rem 1rem;
        width: 100%;
        border-radius: 0.25rem;

        #search-input {
            border: 0;
            background-color: transparent;
            width: 100%;
        }
    }

    #filter-list-header {
        padding-bottom: 0.5em;
        padding-top: 0.5em;

        label {
            font-weight: 600;
            margin-bottom: 0;
        }
    }
}

#filter-list {
    label {
        font-weight: 600;
        margin-bottom: 0;
    }
}

.filter-multiple-option-container {
    padding: 0.3rem 0;

    &:not(:last-child) {
        border-bottom: 1px solid #dee2e69e;
    }
}
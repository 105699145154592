.ag-watermark {
	display: none !important;
}

.ag-input-field-input {
	color: rgb(0, 0, 0) !important;
}

.ag-theme-alpine,
.ag-theme-alpine {
	.ag-row-drag {
		margin-right: 0px !important;
	}
}

.ag-theme-custom-text-center .ag-header-cell-label {
	text-align: center;
	display: inline-grid;
}

.ag-input-text-wrapper {
	display: flex;
	align-items: center;
}

#fileIndicator {
	position: relative;
	margin: 0;
	padding-right: 6px;

	.custom-badge {
		top: 20px !important;
	}
}

.title-context-menu {
	font-weight: bold !important;
	opacity: 1 !important;
	color: #343a40 !important;
}

.ag-floating-filter-input:read-only {
	background-color: transparent;
}

// Style pour les SelectFitler
.ag-input-text-wrapper,
.ag-input-text-wrapper.ag-date-filter {
	.form-control {
		height: 24px;
		border-radius: 3px !important;
		padding: 0;
		border-color: var(--ag-border-color, #babfc7) !important;
	}

	a {
		position: relative;
		right: 15px;
		cursor: pointer;
		top: 2px;
	}
}

.ag-menu {
	border-radius: 6px !important;
	background-color: white;

	--ag-row-hover-color: #ebebeb !important;
}

.ag-theme-alpine .ag-cell.ag-cell-inline-editing {
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.ag-theme-alpine .ag-cell,
.ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
	padding-left: 10px !important;
	padding-right: 10px !important;
	/*par defaut 17px*/
}

.ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-group-cell {
	padding-left: 11px !important;
	padding-right: 11px !important;
	/*par defaut 18px*/
}

.ag-root-wrapper {
	border-radius: 10px;
}

.ag-center-cols-clipper {
	.ag-row-focus {
		box-shadow: inset 4px 0px 0px 0px #000000, inset -4px 0px 0px 0px #000000;
	}
}

.ag-pinned-right-cols-container {
	.ag-row-focus {
		box-shadow: inset -4px 0px 0px 0px #000000;
	}
}

.ag-pinned-left-cols-container {
	.ag-row-focus {
		box-shadow: inset 4px 0px 0px 0px #000000;
	}
}

.ag-cell-scrollable {
	>span {
		display: block;
		height: 100%;
		width: 100%;
		overflow-x: auto;
	}
}
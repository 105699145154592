.tracking-product-event-card {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	gap: 20px;
	border-radius: 10px;
	background-color: #ffffff;
	box-shadow: 0px 16px 15px -10px rgba(105, 96, 215, 0.0944602);
	.header {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 10px;
		padding-bottom: 0.5rem;
		border-bottom: 0.5px solid #dde2eb;

		.date {
			font-weight: bold;
		}
		.user {
			text-align: right;
			font-weight: lighter;
		}
	}
}

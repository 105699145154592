.ap-grid-sidebar {
    width: 30px;
    border-radius: 0px 10px 10px 0px;
    border: 1px solid #babfc7;
    border-left: 0px;
    padding-top: 2em;
    background-color: white;
}

.ap-grid-sidebar-btn {
    font-size: 14px;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    &:hover {
        color: #2196f3;
    }
}

.ap-grid-sidebar-btn-icon {
    font-size: 12px;
}

.ap-grid-sidebar-btn-text {
    writing-mode: vertical-lr;
    word-spacing: 2px;
}


.ap-grid-sidebar-column-panel {
    background-color: #f8f8f8;
    width: 249px;
    border: 1px solid #babfc7;
    border-left: 0px;
    padding-top: 0.5em;
    display: flex;
    flex-direction: column;
}

.ap-grid-sidebar-column-list {
    flex-grow: 1;
    list-style-type: none;
    font-size: 13px;
    overflow: auto;
    scrollbar-color: #787878 #f8f8f8;
}

#ap-grid-column-alphabetize {
    border: 0;

    background: transparent;

    &:hover {
        color: #2196f3;
    }
}

#ap-grid-column-search {
    border-color: #babfc7 !important;
    border-radius: 3px !important;

    padding-bottom: 1px;
    padding-left: 6px;
    padding-right: 2px;
    padding-top: 1px;

    height: 24px;

    font-size: 13px;


    &:focus {
        box-shadow: 0 0 1px 0.05rem #2196f366;
        border-color: #2196f3;
    }
}

#ap-grid-column-header {
    font-size: 13px;
}

.ap-grid-sidebar-column-panel ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: var(--ag-header-background-color, #f8f8f8);
    padding: 5px;
}

.ap-grid-sidebar-column-panel ::-webkit-scrollbar {
    background-color: var(--ag-header-background-color, #f8f8f8);
    padding: 5px;
}

.ap-grid-sidebar-column-panel ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 5px solid var(--ag-header-background-color, #f8f8f8);
    background: rgb(120, 120, 120)
}

.ap-grid-sidebar-column-panel ::-webkit-scrollbar-thumb:vertical {
    min-height: 50px;
}

.ap-grid-sidebar-column-panel ::-webkit-scrollbar-thumb:horizontal {
    min-width: 50px;
}
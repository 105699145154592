.card-counter {
    box-shadow: 2px 2px 10px #DADADA;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 20px 10px;
    background-color: #fff;
    height: 100px;
    width: 270px;
    border-radius: 5px;
    transition: .3s linear all;
    position: relative;
}

.card-counter:hover {
    box-shadow: 4px 4px 20px #DADADA;
    transition: .3s linear all;
}

.card-counter.blue {
    background-color: #007bff;
    color: #FFF;
}

.card-counter.red {
    background-color: #ef5350;
    color: #FFF;
}

.card-counter.green {
    background-color: #66bb6a;
    color: #FFF;
}

.card-counter.light-blue {
    background-color: #26c6da;
    color: #FFF;
}

.card-counter.orange {
    background-color: #eeae4f;
    color: #FFF;
}

.card-counter.grey {
    background-color: #98a0a1;
    color: #FFF;
}

.card-counter i {
    position: absolute;
    top: 0;
    font-size: 4.5em;
    opacity: 0.2;
}

.card-counter .count-numbers {
    position: absolute;
    right: 35px;
    top: 20px;
    font-size: 32px;
    display: block;
}

.card-counter .count-name {
    position: absolute;
    right: 35px;
    top: 65px;
    font-style: italic;
    text-transform: capitalize;
    opacity: 0.5;
    display: block;
    font-size: 18px;
}
ap-mobile-sort {
    padding: 0.5rem 0.5rem 1rem 1rem;
}

ap-mobile-filter {
    padding: 0.5rem 1rem 1rem 0.5rem;
}

ap-mobile-filter,
ap-mobile-sort {
    width: 100%;

    button {
        width: 100%;
        border-radius: 0.25rem;
        box-shadow: 0 1px 5px 0 #3e396b12, 0 0px 2px 0 #3e396b0f;

        span {
            font-size: 14px;
            font-weight: 600;
        }

    }
}


.mobile-modal-dialog {
    position: absolute;
    bottom: 0;
    width: 100%;
    top: initial;

    .modal-content {
        max-height: 80vh;
    }
}

.mobile-modal-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;
}

#mobile-modal-body {
    padding: 1rem;
    background-color: white !important;
}

.mobile-modal-close {
    font-size: 18px !important;
    margin-right: auto !important;
    margin-left: 0 !important;
    opacity: 1;
}

.mobile-modal-reset {
    font-weight: 700;
    font-size: 14px;
    margin-left: auto;
}

.mobile-modal-apply {
    border-radius: 5px !important;
    width: 100%;
    font-size: 14px;
    padding: 0.75rem;
    font-weight: 400;
}

.mobile-sort-option {
    display: flex;
    padding: 1rem;
    align-items: center;

    label {
        margin: 0;
        font-size: 14px;
        font-weight: 700;
    }

    input {
        margin-left: auto;
        height: 1.2em;
        width: 1.2em;
    }
}

.filter-container {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    &:not(:last-child) {
        border-bottom: 1px solid #dee2e69e;
    }
}

.filter-label {
    font-weight: 600;
    font-size: 14px;
}

.filter-input-text {
    display: flex;
    margin-bottom: 0.5rem;
    background-color: #f2f2f2;
    padding: 0.3rem 1rem;
    border-radius: 0.25rem;
    border: 0;
    font-size: 14px;
    width: 100%;
}

.filter-date-prefix {
    font-size: 13px;
    font-weight: 600;
}

.filter-input-date {
    border: 0;
    background-color: #f2f2f2;
    border-radius: 0.25rem;
    padding: 0.3rem 1rem;
    font-size: 14px;
    width: fit-content;
    max-width: 40%;
}

.filter-radio-label {
    font-size: 13px;
    font-weight: 600
}

.filter-select {
    border: 0;
    background-color: #f2f2f2;
    padding: 0.3rem 1rem;
    border-radius: 0.25rem;
    font-size: 14px;
    width: 100%;
}

.filter-selection {
    /* IE, Edge */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;

    &::-webkit-scrollbar {
        /* Chrome, Safari, Opera */
        display: none;
    }
}

.filter-selected-item {
    border-radius: 1rem;
    background-color: black;
    width: fit-content;
    color: #f2f2f2;
    font-size: 14px;
    padding: 0.3rem 1rem;
    white-space: nowrap;
}

.filter-select-multiple-button {
    font-size: 14px;
    font-weight: bold;
}
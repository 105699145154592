.page-mobile {

    .page-card {
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.04) 0px 1px 1px 0px;
        margin-bottom: 0.5rem;
    }

    .page-card-value {
        font-size: 13px;
    }

    .page-card-label {
        font-size: 13px;
    }

    .tab-enable-icon {
        font-size: 13px;
    }

}
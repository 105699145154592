.modal-mobile {
    .modal-dialog {
        margin: 0;
        display: flex;
        align-items: flex-end;
        min-height: 100%
    }

    .modal-content {
        border-radius: 15px 15px 0 0;
    }

    .modal-title {
        font-size: 1rem;
    }
}
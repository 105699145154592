@font-face {
  font-family: "autoprog-icons";
  font-style: normal;
  font-weight: 400;
  src: url("./autoprog-icons.eot");
  src: url("./autoprog-icons.eot?#iefix") format("embedded-opentype"), url("./autoprog-icons.woff2") format("woff2"), url("./autoprog-icons.woff") format("woff"), url("./autoprog-icons.ttf") format("truetype"), url("./autoprog-icons.svg") format("svg");
}
.icon {
  display: inline-block;
}

.icon:before {
  font-family: "autoprog-icons" !important;
  font-style: normal;
  font-weight: normal !important;
  vertical-align: top;
}

:root {
  --icon-24v:"\F101";
  --icon-BR:"\F102";
  --icon-Mitsubishi-Electric-large:"\F103";
  --icon-Mitsubishi_Electric_icon:"\F104";
  --icon-ai-1:"\F105";
  --icon-aiguillage:"\F106";
  --icon-alarm:"\F107";
  --icon-allen-bradley-icon:"\F108";
  --icon-allen-bradley-large:"\F109";
  --icon-analyse-grain:"\F10A";
  --icon-analyse:"\F10B";
  --icon-api:"\F10C";
  --icon-appareil-1:"\F10D";
  --icon-appareil-2:"\F10E";
  --icon-appareil-3:"\F10F";
  --icon-asi:"\F110";
  --icon-aside-dock:"\F111";
  --icon-aside:"\F112";
  --icon-asp-1:"\F113";
  --icon-attention:"\F114";
  --icon-au-1:"\F115";
  --icon-au-2:"\F116";
  --icon-au-3:"\F117";
  --icon-au-4:"\F118";
  --icon-au-5:"\F119";
  --icon-au-6:"\F11A";
  --icon-au-7:"\F11B";
  --icon-au-8:"\F11C";
  --icon-autoprog-2021-icon-bold:"\F11D";
  --icon-autoprog-2021-icon:"\F11E";
  --icon-autoprog-2021:"\F11F";
  --icon-autoprog-icon:"\F120";
  --icon-autoprog-new-full-2018:"\F121";
  --icon-autoprog-new-full-reverse-2018:"\F122";
  --icon-autoprog-old:"\F123";
  --icon-badge:"\F124";
  --icon-balance:"\F125";
  --icon-bars:"\F126";
  --icon-bascule:"\F127";
  --icon-battery-0:"\F128";
  --icon-battery-10:"\F129";
  --icon-battery-100:"\F12A";
  --icon-battery-20:"\F12B";
  --icon-battery-30:"\F12C";
  --icon-battery-40:"\F12D";
  --icon-battery-50:"\F12E";
  --icon-battery-60:"\F12F";
  --icon-battery-70:"\F130";
  --icon-battery-80:"\F131";
  --icon-battery-90:"\F132";
  --icon-beckhoff-full:"\F133";
  --icon-beckhoff-icon:"\F134";
  --icon-bell:"\F491";
  --icon-bihl-wiedemann-icon:"\F136";
  --icon-bin:"\F137";
  --icon-binoculars:"\F138";
  --icon-biohazard-2:"\F139";
  --icon-biohazard:"\F13A";
  --icon-blocked:"\F13B";
  --icon-boite-1:"\F13C";
  --icon-boite-3D:"\F13D";
  --icon-boite-change-1:"\F13E";
  --icon-boite-change-2:"\F13F";
  --icon-boite-droite-1:"\F140";
  --icon-boite-droite-2:"\F141";
  --icon-boite-gauche-1:"\F142";
  --icon-boite-gauche-2:"\F143";
  --icon-bolt:"\F144";
  --icon-box-add:"\F145";
  --icon-box-remove:"\F146";
  --icon-bright:"\F147";
  --icon-brightness-contrast:"\F148";
  --icon-bubble:"\F149";
  --icon-bubble2:"\F14A";
  --icon-bullhorn:"\F14B";
  --icon-business:"\F14C";
  --icon-calculator:"\F14D";
  --icon-calendar:"\F499";
  --icon-call:"\F14F";
  --icon-cancel-circle:"\F150";
  --icon-cancel:"\F151";
  --icon-canopen-icon:"\F152";
  --icon-canopen-vector-logo:"\F153";
  --icon-cellule-interdit:"\F154";
  --icon-cellule-new:"\F155";
  --icon-cellule:"\F156";
  --icon-chariot:"\F157";
  --icon-chart:"\F158";
  --icon-checkall:"\F159";
  --icon-checkbox-checked:"\F15A";
  --icon-checkbox-partial:"\F15B";
  --icon-checkbox-unchecked:"\F15C";
  --icon-checkmark:"\F15D";
  --icon-checkmark2:"\F15E";
  --icon-circuit:"\F15F";
  --icon-clipboard:"\F4A2";
  --icon-clock:"\F4A3";
  --icon-clock2:"\F162";
  --icon-coffee:"\F163";
  --icon-cog:"\F164";
  --icon-cogs:"\F165";
  --icon-coin-dollar:"\F166";
  --icon-colors:"\F167";
  --icon-combobox:"\F168";
  --icon-compass:"\F4AA";
  --icon-compass2:"\F16A";
  --icon-compresseur-2:"\F16B";
  --icon-compresseur:"\F16C";
  --icon-construction:"\F16D";
  --icon-control-joystick:"\F16E";
  --icon-cord:"\F16F";
  --icon-corn:"\F170";
  --icon-cutwire:"\F171";
  --icon-destination-interdit:"\F172";
  --icon-destination:"\F173";
  --icon-devicenet-icon:"\F174";
  --icon-devicenet-new:"\F175";
  --icon-diagram:"\F176";
  --icon-document:"\F177";
  --icon-droplet:"\F178";
  --icon-echantillon-grain:"\F179";
  --icon-ecl-1:"\F17A";
  --icon-egg-2:"\F17B";
  --icon-egg:"\F17C";
  --icon-el-1:"\F17D";
  --icon-ethercat-full:"\F17E";
  --icon-ethercat-icon:"\F17F";
  --icon-ethernet-ip-icon:"\F180";
  --icon-ethernet-ip-new:"\F181";
  --icon-ethernet-version-1:"\F182";
  --icon-ethernet-version-2:"\F183";
  --icon-extracteur:"\F184";
  --icon-fan-1:"\F185";
  --icon-fan-2:"\F186";
  --icon-filt-1:"\F187";
  --icon-filter-fill:"\F188";
  --icon-filter-line:"\F189";
  --icon-filter-off-fill:"\F18A";
  --icon-filter-off-line:"\F18B";
  --icon-filter:"\F18C";
  --icon-fire:"\F18D";
  --icon-floor-brush:"\F18E";
  --icon-fond-plat-1:"\F18F";
  --icon-forced-fan:"\F190";
  --icon-grain-alone-2:"\F191";
  --icon-grain-alone:"\F192";
  --icon-grain-dual-2:"\F193";
  --icon-grain-dual:"\F194";
  --icon-grain-familly:"\F195";
  --icon-grain-feld:"\F196";
  --icon-graph:"\F197";
  --icon-gsm-0:"\F198";
  --icon-gsm-1:"\F199";
  --icon-gsm-2:"\F19A";
  --icon-gsm-3:"\F19B";
  --icon-gsm-4:"\F19C";
  --icon-hand:"\F19D";
  --icon-history:"\F19E";
  --icon-humidite:"\F19F";
  --icon-ifm:"\F1A0";
  --icon-infraliseur:"\F1A1";
  --icon-input:"\F1A2";
  --icon-keyboard:"\F4EB";
  --icon-leaf:"\F1A4";
  --icon-liens:"\F1A5";
  --icon-loader:"\F1A6";
  --icon-location:"\F1A7";
  --icon-location2:"\F1A8";
  --icon-lock:"\F1A9";
  --icon-magic-wand:"\F1AA";
  --icon-magnifying-glass:"\F1AB";
  --icon-manual:"\F1AC";
  --icon-menu:"\F1AD";
  --icon-menu_new:"\F1AE";
  --icon-meter-0:"\F1AF";
  --icon-meter-1:"\F1B0";
  --icon-methanisation-1:"\F1B1";
  --icon-methanisation-2:"\F1B2";
  --icon-mobile:"\F1B3";
  --icon-mobile2:"\F1B4";
  --icon-modbus-icon:"\F1B5";
  --icon-mode_real:"\F1B6";
  --icon-mode_simulate:"\F1B7";
  --icon-module-thermo:"\F1B8";
  --icon-move-down:"\F1B9";
  --icon-move-up:"\F1BA";
  --icon-neb-1:"\F1BB";
  --icon-nebule:"\F1BC";
  --icon-network-add:"\F1BD";
  --icon-network-lost:"\F1BE";
  --icon-network-rem:"\F1BF";
  --icon-network-warnig:"\F1C0";
  --icon-network:"\F1C1";
  --icon-newspaper:"\F4FE";
  --icon-not-saved:"\F1C3";
  --icon-objectif:"\F1C4";
  --icon-omron-icon:"\F1C5";
  --icon-omron-large:"\F1C6";
  --icon-open-door:"\F1C7";
  --icon-outline-file-invoice-dollar:"\F1C8";
  --icon-page-break:"\F1C9";
  --icon-pagebreak:"\F1CA";
  --icon-paint-format:"\F1CB";
  --icon-paint-trace:"\F1CC";
  --icon-passage:"\F1CD";
  --icon-passage_diagonale:"\F1CE";
  --icon-pause2:"\F1CF";
  --icon-phoenix-contact-full:"\F1D0";
  --icon-phoenix-contact-icon:"\F1D1";
  --icon-phone:"\F1D2";
  --icon-pie-chart:"\F1D3";
  --icon-play3:"\F1D4";
  --icon-plus:"\F1D5";
  --icon-plus_slim:"\F1D6";
  --icon-post-it:"\F1D7";
  --icon-poule:"\F1D8";
  --icon-power-cord:"\F1D9";
  --icon-power:"\F1DA";
  --icon-pression:"\F1DB";
  --icon-printer-alt-outilne:"\F1DC";
  --icon-printer-outiline:"\F1DD";
  --icon-printer:"\F1DE";
  --icon-printer_alt:"\F1DF";
  --icon-printer_none:"\F1E0";
  --icon-profibus-large:"\F1E1";
  --icon-profibus:"\F1E2";
  --icon-profinet-large:"\F1E3";
  --icon-profinet:"\F1E4";
  --icon-proximity-command-left:"\F1E5";
  --icon-proximity-command-right:"\F1E6";
  --icon-proximity-command-stop:"\F1E7";
  --icon-proximity-command-up:"\F1E8";
  --icon-qrcode:"\F1E9";
  --icon-registre:"\F1EA";
  --icon-remove-filter:"\F1EB";
  --icon-repartiteur:"\F1EC";
  --icon-reprise-interdit:"\F1ED";
  --icon-reprise:"\F1EE";
  --icon-ri-account-circle-fill:"\F1EF";
  --icon-ri-account-circle-line:"\F1F0";
  --icon-ri-add-fill:"\F1F1";
  --icon-ri-add-line:"\F1F2";
  --icon-ri-alert-fill:"\F1F3";
  --icon-ri-alert-line:"\F1F4";
  --icon-ri-arrow-drop-down-fill:"\F1F5";
  --icon-ri-arrow-drop-down-line:"\F1F6";
  --icon-ri-arrow-go-back-fill:"\F1F7";
  --icon-ri-arrow-go-back-line:"\F1F8";
  --icon-ri-arrow-left-right-fill:"\F1F9";
  --icon-ri-arrow-left-right-line:"\F1FA";
  --icon-ri-arrow-right-fill:"\F1FB";
  --icon-ri-arrow-right-line:"\F1FC";
  --icon-ri-arrow-right-s-fill:"\F1FD";
  --icon-ri-arrow-right-s-line:"\F1FE";
  --icon-ri-barcode-fill:"\F1FF";
  --icon-ri-barcode-line:"\F200";
  --icon-ri-briefcase-4-fill:"\F201";
  --icon-ri-briefcase-4-line:"\F202";
  --icon-ri-bug-2-fill:"\F203";
  --icon-ri-bug-2-line:"\F204";
  --icon-ri-calendar-event-fill:"\F205";
  --icon-ri-calendar-event-line:"\F206";
  --icon-ri-calendar-todo-fill:"\F207";
  --icon-ri-calendar-todo-line:"\F208";
  --icon-ri-close-fill:"\F209";
  --icon-ri-close-line:"\F20A";
  --icon-ri-contrast-drop-fill:"\F20B";
  --icon-ri-contrast-drop-line:"\F20C";
  --icon-ri-creative-commons-nd-fill:"\F20D";
  --icon-ri-creative-commons-nd-line:"\F20E";
  --icon-ri-dashboard-3-fill:"\F20F";
  --icon-ri-dashboard-3-line:"\F210";
  --icon-ri-database-2-fill:"\F211";
  --icon-ri-database-2-line:"\F212";
  --icon-ri-delete-back-2-fill:"\F213";
  --icon-ri-delete-back-2-line:"\F214";
  --icon-ri-delete-bin-5-fill:"\F215";
  --icon-ri-delete-bin-5-line:"\F216";
  --icon-ri-door-open-fill:"\F217";
  --icon-ri-door-open-line:"\F218";
  --icon-ri-download-2-fill:"\F219";
  --icon-ri-download-2-line:"\F21A";
  --icon-ri-earth-fill:"\F21B";
  --icon-ri-earth-line:"\F21C";
  --icon-ri-eraser-fill:"\F21D";
  --icon-ri-eraser-line:"\F21E";
  --icon-ri-exchange-dollar-fill:"\F21F";
  --icon-ri-exchange-dollar-line:"\F220";
  --icon-ri-exchange-euro-fill:"\F221";
  --icon-ri-exchange-euro-line:"\F222";
  --icon-ri-external-link-fill:"\F223";
  --icon-ri-external-link-line:"\F224";
  --icon-ri-eye-fill:"\F225";
  --icon-ri-eye-line:"\F226";
  --icon-ri-eye-off-fill:"\F227";
  --icon-ri-eye-off-line:"\F228";
  --icon-ri-file-3-fill:"\F229";
  --icon-ri-file-3-line:"\F22A";
  --icon-ri-file-bill-dollar-fill:"\F22B";
  --icon-ri-file-bill-dollar-line:"\F22C";
  --icon-ri-file-bill-euro-fill:"\F22D";
  --icon-ri-file-bill-euro-line:"\F22E";
  --icon-ri-file-code-fill:"\F22F";
  --icon-ri-file-code-line:"\F230";
  --icon-ri-file-edit-fill:"\F231";
  --icon-ri-file-edit-line:"\F232";
  --icon-ri-file-settings-fill:"\F233";
  --icon-ri-file-settings-line:"\F234";
  --icon-ri-file-text-fill:"\F235";
  --icon-ri-file-text-line:"\F236";
  --icon-ri-game-fill:"\F237";
  --icon-ri-game-line:"\F238";
  --icon-ri-global-fill:"\F239";
  --icon-ri-global-line:"\F23A";
  --icon-ri-hand-coin-fill:"\F23B";
  --icon-ri-hand-coin-line:"\F23C";
  --icon-ri-hard-drive-2-fill:"\F23D";
  --icon-ri-hard-drive-2-line:"\F23E";
  --icon-ri-heart-fill:"\F23F";
  --icon-ri-heart-line:"\F240";
  --icon-ri-history-fill:"\F241";
  --icon-ri-history-line:"\F242";
  --icon-ri-home-2-fill:"\F243";
  --icon-ri-home-2-line:"\F244";
  --icon-ri-image-fill:"\F245";
  --icon-ri-image-line:"\F246";
  --icon-ri-information-fill:"\F247";
  --icon-ri-information-line:"\F248";
  --icon-ri-lightbulb-flash-fill:"\F249";
  --icon-ri-lightbulb-flash-line:"\F24A";
  --icon-ri-line-chart-fill:"\F24B";
  --icon-ri-line-chart-line:"\F24C";
  --icon-ri-list-check-2:"\F24D";
  --icon-ri-list-check:"\F24E";
  --icon-ri-list-settings-fill:"\F24F";
  --icon-ri-list-settings-line:"\F250";
  --icon-ri-loader-2-fill:"\F251";
  --icon-ri-loader-2-line:"\F252";
  --icon-ri-lock-fill:"\F253";
  --icon-ri-lock-line:"\F254";
  --icon-ri-lock-unlock-fill:"\F255";
  --icon-ri-lock-unlock-line:"\F256";
  --icon-ri-map-pin-fill:"\F257";
  --icon-ri-map-pin-line:"\F258";
  --icon-ri-money-dollar:"\F259";
  --icon-ri-money-euro:"\F25A";
  --icon-ri-more-2-fill:"\F25B";
  --icon-ri-more-2-line:"\F25C";
  --icon-ri-more-fill:"\F25D";
  --icon-ri-more-line:"\F25E";
  --icon-ri-notification-3-fill:"\F25F";
  --icon-ri-notification-3-line:"\F260";
  --icon-ri-palette-fill:"\F261";
  --icon-ri-palette-line:"\F262";
  --icon-ri-pencil-fill:"\F263";
  --icon-ri-pencil-line:"\F264";
  --icon-ri-pie-chart-fill:"\F265";
  --icon-ri-pie-chart-line:"\F266";
  --icon-ri-pin-distance-fill:"\F267";
  --icon-ri-pin-distance-line:"\F268";
  --icon-ri-price-tag-3-fill:"\F269";
  --icon-ri-price-tag-3-line:"\F26A";
  --icon-ri-printer-fill:"\F26B";
  --icon-ri-printer-line:"\F26C";
  --icon-ri-qr-code-fill:"\F26D";
  --icon-ri-qr-code-line:"\F26E";
  --icon-ri-rocket-2-fill:"\F26F";
  --icon-ri-rocket-2-line:"\F270";
  --icon-ri-save-fill:"\F271";
  --icon-ri-save-line:"\F272";
  --icon-ri-scissors-cut-fill:"\F273";
  --icon-ri-scissors-cut-line:"\F274";
  --icon-ri-sd-card-mini-fill:"\F275";
  --icon-ri-sd-card-mini-line:"\F276";
  --icon-ri-server-fill:"\F277";
  --icon-ri-server-line:"\F278";
  --icon-ri-settings-3-fill:"\F279";
  --icon-ri-settings-3-line:"\F27A";
  --icon-ri-shield-check-fill:"\F27B";
  --icon-ri-shield-check-line:"\F27C";
  --icon-ri-shopping-bag-3-fill:"\F27D";
  --icon-ri-shopping-bag-3-line:"\F27E";
  --icon-ri-shopping-cart-2-fill:"\F27F";
  --icon-ri-shopping-cart-2-line:"\F280";
  --icon-ri-stack-fill:"\F281";
  --icon-ri-stack-line:"\F282";
  --icon-ri-star-fill:"\F283";
  --icon-ri-star-line:"\F284";
  --icon-ri-stock-fill:"\F285";
  --icon-ri-stock-line:"\F286";
  --icon-ri-sword-fill:"\F287";
  --icon-ri-sword-line:"\F288";
  --icon-ri-t-shirt-air-fill:"\F289";
  --icon-ri-t-shirt-air-line:"\F28A";
  --icon-ri-terminal-box-fill:"\F28B";
  --icon-ri-terminal-box-line:"\F28C";
  --icon-ri-terminal-window-fill:"\F28D";
  --icon-ri-terminal-window-line:"\F28E";
  --icon-ri-thumb-up-fill:"\F28F";
  --icon-ri-thumb-up-line:"\F290";
  --icon-ri-time-fill:"\F291";
  --icon-ri-time-line:"\F292";
  --icon-ri-timer-fill:"\F293";
  --icon-ri-timer-line:"\F294";
  --icon-ri-tools-fill:"\F295";
  --icon-ri-tools-line:"\F296";
  --icon-ri-truck-fill:"\F297";
  --icon-ri-truck-line:"\F298";
  --icon-ri-upload-2-fill:"\F299";
  --icon-ri-upload-2-line:"\F29A";
  --icon-ri-user-settings-fill:"\F29B";
  --icon-ri-user-settings-line:"\F29C";
  --icon-ri-vip-crown-2-fill:"\F29D";
  --icon-ri-vip-crown-2-line:"\F29E";
  --icon-ri-volume-mute-fill:"\F29F";
  --icon-ri-volume-mute-line:"\F2A0";
  --icon-ri-volume-up-fill:"\F2A1";
  --icon-ri-volume-up-line:"\F2A2";
  --icon-rockwell-icon:"\F2A3";
  --icon-rockwell-large:"\F2A4";
  --icon-sablier:"\F2A5";
  --icon-saved:"\F2A6";
  --icon-schneider:"\F2A7";
  --icon-search:"\F2A8";
  --icon-serie:"\F2A9";
  --icon-siemens-icon:"\F2AA";
  --icon-siemens:"\F2AB";
  --icon-silo-1:"\F2AC";
  --icon-silo-2:"\F2AD";
  --icon-spinner4:"\F2AE";
  --icon-spoon-and-fork-crossed:"\F2AF";
  --icon-stats-bars:"\F2B0";
  --icon-stats-bars2:"\F2B1";
  --icon-stats-dots:"\F2B2";
  --icon-stop:"\F2B3";
  --icon-stopwatch:"\F2B4";
  --icon-svg:"\F2B5";
  --icon-switch-actived-perced-full:"\F2B6";
  --icon-switch-actived-perced:"\F2B7";
  --icon-switch-actived:"\F2B8";
  --icon-switch-unactived-perced-full:"\F2B9";
  --icon-switch-unactived-perced:"\F2BA";
  --icon-switch-unactived:"\F2BB";
  --icon-switch:"\F2BC";
  --icon-table:"\F2BD";
  --icon-table2:"\F2BE";
  --icon-tablet:"\F2BF";
  --icon-tapis-2:"\F2C0";
  --icon-tapis-parametrage-2:"\F2C1";
  --icon-tapis-parametrage:"\F2C2";
  --icon-tapis:"\F2C3";
  --icon-target:"\F2C4";
  --icon-teamviewer:"\F2C5";
  --icon-temp-ext:"\F2C6";
  --icon-temp-int:"\F2C7";
  --icon-terminal:"\F2C8";
  --icon-text-color:"\F2C9";
  --icon-thermometer:"\F2CA";
  --icon-tools:"\F2CB";
  --icon-transaction:"\F2CC";
  --icon-transporteur-1:"\F2CD";
  --icon-transporteur-1s-1:"\F2CE";
  --icon-transporteur-2s-1:"\F2CF";
  --icon-trappe-1:"\F2D0";
  --icon-trappe-2:"\F2D1";
  --icon-trappe-3:"\F2D2";
  --icon-trappe-4:"\F2D3";
  --icon-trappe-5:"\F2D4";
  --icon-trappe-6:"\F2D5";
  --icon-trappe-7:"\F2D6";
  --icon-tree:"\F2D7";
  --icon-tremie:"\F2D8";
  --icon-uncheckall:"\F2D9";
  --icon-unlocked:"\F2DA";
  --icon-usb-cable:"\F2DB";
  --icon-usb-icon:"\F2DC";
  --icon-valider:"\F2DD";
  --icon-vipa-full:"\F2DE";
  --icon-vipa-icon:"\F2DF";
  --icon-vis:"\F2E0";
  --icon-wago-full:"\F2E1";
  --icon-wago-icon-circle:"\F2E2";
  --icon-wago-icon:"\F2E3";
  --icon-warning:"\F2E4";
  --icon-water61:"\F2E5";
  --icon-weidmuller-full:"\F2E6";
  --icon-weidmuller-icon:"\F2E7";
  --icon-wikipedia-logo:"\F2E8";
  --icon-y-command-down:"\F2E9";
  --icon-brands-500px:"\F2EA";
  --icon-brands-accessible-icon:"\F2EB";
  --icon-brands-accusoft:"\F2EC";
  --icon-brands-acquisitions-incorporated:"\F2ED";
  --icon-brands-adn:"\F2EE";
  --icon-brands-adobe:"\F2EF";
  --icon-brands-adversal:"\F2F0";
  --icon-brands-affiliatetheme:"\F2F1";
  --icon-brands-algolia:"\F2F2";
  --icon-brands-alipay:"\F2F3";
  --icon-brands-amazon-pay:"\F2F4";
  --icon-brands-amazon:"\F2F5";
  --icon-brands-amilia:"\F2F6";
  --icon-brands-android:"\F2F7";
  --icon-brands-angellist:"\F2F8";
  --icon-brands-angrycreative:"\F2F9";
  --icon-brands-angular:"\F2FA";
  --icon-brands-app-store-ios:"\F2FB";
  --icon-brands-app-store:"\F2FC";
  --icon-brands-apper:"\F2FD";
  --icon-brands-apple-pay:"\F2FE";
  --icon-brands-apple:"\F2FF";
  --icon-brands-artstation:"\F300";
  --icon-brands-asymmetrik:"\F301";
  --icon-brands-atlassian:"\F302";
  --icon-brands-audible:"\F303";
  --icon-brands-autoprefixer:"\F304";
  --icon-brands-avianex:"\F305";
  --icon-brands-aviato:"\F306";
  --icon-brands-aws:"\F307";
  --icon-brands-bandcamp:"\F308";
  --icon-brands-behance-square:"\F309";
  --icon-brands-behance:"\F30A";
  --icon-brands-bimobject:"\F30B";
  --icon-brands-bitbucket:"\F30C";
  --icon-brands-bitcoin:"\F30D";
  --icon-brands-bity:"\F30E";
  --icon-brands-black-tie:"\F30F";
  --icon-brands-blackberry:"\F310";
  --icon-brands-blogger-b:"\F311";
  --icon-brands-blogger:"\F312";
  --icon-brands-bluetooth-b:"\F313";
  --icon-brands-bluetooth:"\F314";
  --icon-brands-btc:"\F315";
  --icon-brands-buromobelexperte:"\F316";
  --icon-brands-buysellads:"\F317";
  --icon-brands-canadian-maple-leaf:"\F318";
  --icon-brands-cc-amazon-pay:"\F319";
  --icon-brands-cc-amex:"\F31A";
  --icon-brands-cc-apple-pay:"\F31B";
  --icon-brands-cc-diners-club:"\F31C";
  --icon-brands-cc-discover:"\F31D";
  --icon-brands-cc-jcb:"\F31E";
  --icon-brands-cc-mastercard:"\F31F";
  --icon-brands-cc-paypal:"\F320";
  --icon-brands-cc-stripe:"\F321";
  --icon-brands-cc-visa:"\F322";
  --icon-brands-centercode:"\F323";
  --icon-brands-centos:"\F324";
  --icon-brands-chrome:"\F325";
  --icon-brands-cloudscale:"\F326";
  --icon-brands-cloudsmith:"\F327";
  --icon-brands-cloudversify:"\F328";
  --icon-brands-codepen:"\F329";
  --icon-brands-codiepie:"\F32A";
  --icon-brands-confluence:"\F32B";
  --icon-brands-connectdevelop:"\F32C";
  --icon-brands-contao:"\F32D";
  --icon-brands-cpanel:"\F32E";
  --icon-brands-creative-commons-by:"\F32F";
  --icon-brands-creative-commons-nc-eu:"\F330";
  --icon-brands-creative-commons-nc-jp:"\F331";
  --icon-brands-creative-commons-nc:"\F332";
  --icon-brands-creative-commons-nd:"\F333";
  --icon-brands-creative-commons-pd-alt:"\F334";
  --icon-brands-creative-commons-pd:"\F335";
  --icon-brands-creative-commons-remix:"\F336";
  --icon-brands-creative-commons-sa:"\F337";
  --icon-brands-creative-commons-sampling-plus:"\F338";
  --icon-brands-creative-commons-sampling:"\F339";
  --icon-brands-creative-commons-share:"\F33A";
  --icon-brands-creative-commons-zero:"\F33B";
  --icon-brands-creative-commons:"\F33C";
  --icon-brands-critical-role:"\F33D";
  --icon-brands-css3-alt:"\F33E";
  --icon-brands-css3:"\F33F";
  --icon-brands-cuttlefish:"\F340";
  --icon-brands-d-and-d-beyond:"\F341";
  --icon-brands-d-and-d:"\F342";
  --icon-brands-dashcube:"\F343";
  --icon-brands-delicious:"\F344";
  --icon-brands-deploydog:"\F345";
  --icon-brands-deskpro:"\F346";
  --icon-brands-dev:"\F347";
  --icon-brands-deviantart:"\F348";
  --icon-brands-dhl:"\F349";
  --icon-brands-diaspora:"\F34A";
  --icon-brands-digg:"\F34B";
  --icon-brands-digital-ocean:"\F34C";
  --icon-brands-discord:"\F34D";
  --icon-brands-discourse:"\F34E";
  --icon-brands-dochub:"\F34F";
  --icon-brands-docker:"\F350";
  --icon-brands-draft2digital:"\F351";
  --icon-brands-dribbble-square:"\F352";
  --icon-brands-dribbble:"\F353";
  --icon-brands-dropbox:"\F354";
  --icon-brands-drupal:"\F355";
  --icon-brands-dyalog:"\F356";
  --icon-brands-earlybirds:"\F357";
  --icon-brands-ebay:"\F358";
  --icon-brands-edge:"\F359";
  --icon-brands-elementor:"\F35A";
  --icon-brands-ello:"\F35B";
  --icon-brands-ember:"\F35C";
  --icon-brands-empire:"\F35D";
  --icon-brands-envira:"\F35E";
  --icon-brands-erlang:"\F35F";
  --icon-brands-ethereum:"\F360";
  --icon-brands-etsy:"\F361";
  --icon-brands-expeditedssl:"\F362";
  --icon-brands-facebook-f:"\F363";
  --icon-brands-facebook-messenger:"\F364";
  --icon-brands-facebook-square:"\F365";
  --icon-brands-facebook:"\F366";
  --icon-brands-fantasy-flight-games:"\F367";
  --icon-brands-fedex:"\F368";
  --icon-brands-fedora:"\F369";
  --icon-brands-figma:"\F36A";
  --icon-brands-firefox:"\F36B";
  --icon-brands-first-order-alt:"\F36C";
  --icon-brands-first-order:"\F36D";
  --icon-brands-firstdraft:"\F36E";
  --icon-brands-flickr:"\F36F";
  --icon-brands-flipboard:"\F370";
  --icon-brands-fly:"\F371";
  --icon-brands-font-awesome-alt:"\F372";
  --icon-brands-font-awesome-flag:"\F373";
  --icon-brands-font-awesome-logo-full:"\F374";
  --icon-brands-font-awesome:"\F375";
  --icon-brands-fonticons-fi:"\F376";
  --icon-brands-fonticons:"\F377";
  --icon-brands-fort-awesome-alt:"\F378";
  --icon-brands-fort-awesome:"\F379";
  --icon-brands-forumbee:"\F37A";
  --icon-brands-foursquare:"\F37B";
  --icon-brands-free-code-camp:"\F37C";
  --icon-brands-freebsd:"\F37D";
  --icon-brands-fulcrum:"\F37E";
  --icon-brands-galactic-republic:"\F37F";
  --icon-brands-galactic-senate:"\F380";
  --icon-brands-get-pocket:"\F381";
  --icon-brands-gg-circle:"\F382";
  --icon-brands-gg:"\F383";
  --icon-brands-git-square:"\F384";
  --icon-brands-git:"\F385";
  --icon-brands-github-alt:"\F386";
  --icon-brands-github-square:"\F387";
  --icon-brands-github:"\F388";
  --icon-brands-gitkraken:"\F389";
  --icon-brands-gitlab:"\F38A";
  --icon-brands-gitter:"\F38B";
  --icon-brands-glide-g:"\F38C";
  --icon-brands-glide:"\F38D";
  --icon-brands-gofore:"\F38E";
  --icon-brands-goodreads-g:"\F38F";
  --icon-brands-goodreads:"\F390";
  --icon-brands-google-drive:"\F391";
  --icon-brands-google-play:"\F392";
  --icon-brands-google-plus-g:"\F393";
  --icon-brands-google-plus-square:"\F394";
  --icon-brands-google-plus:"\F395";
  --icon-brands-google-wallet:"\F396";
  --icon-brands-google:"\F397";
  --icon-brands-gratipay:"\F398";
  --icon-brands-grav:"\F399";
  --icon-brands-gripfire:"\F39A";
  --icon-brands-grunt:"\F39B";
  --icon-brands-gulp:"\F39C";
  --icon-brands-hacker-news-square:"\F39D";
  --icon-brands-hacker-news:"\F39E";
  --icon-brands-hackerrank:"\F39F";
  --icon-brands-hips:"\F3A0";
  --icon-brands-hire-a-helper:"\F3A1";
  --icon-brands-hooli:"\F3A2";
  --icon-brands-hornbill:"\F3A3";
  --icon-brands-hotjar:"\F3A4";
  --icon-brands-houzz:"\F3A5";
  --icon-brands-html5:"\F3A6";
  --icon-brands-hubspot:"\F3A7";
  --icon-brands-imdb:"\F3A8";
  --icon-brands-instagram:"\F3A9";
  --icon-brands-intercom:"\F3AA";
  --icon-brands-internet-explorer:"\F3AB";
  --icon-brands-invision:"\F3AC";
  --icon-brands-ioxhost:"\F3AD";
  --icon-brands-itunes-note:"\F3AE";
  --icon-brands-itunes:"\F3AF";
  --icon-brands-java:"\F3B0";
  --icon-brands-jedi-order:"\F3B1";
  --icon-brands-jenkins:"\F3B2";
  --icon-brands-jira:"\F3B3";
  --icon-brands-joget:"\F3B4";
  --icon-brands-joomla:"\F3B5";
  --icon-brands-js-square:"\F3B6";
  --icon-brands-js:"\F3B7";
  --icon-brands-jsfiddle:"\F3B8";
  --icon-brands-kaggle:"\F3B9";
  --icon-brands-keybase:"\F3BA";
  --icon-brands-keycdn:"\F3BB";
  --icon-brands-kickstarter-k:"\F3BC";
  --icon-brands-kickstarter:"\F3BD";
  --icon-brands-korvue:"\F3BE";
  --icon-brands-laravel:"\F3BF";
  --icon-brands-lastfm-square:"\F3C0";
  --icon-brands-lastfm:"\F3C1";
  --icon-brands-leanpub:"\F3C2";
  --icon-brands-less:"\F3C3";
  --icon-brands-line:"\F3C4";
  --icon-brands-linkedin-in:"\F3C5";
  --icon-brands-linkedin:"\F3C6";
  --icon-brands-linode:"\F3C7";
  --icon-brands-linux:"\F3C8";
  --icon-brands-lyft:"\F3C9";
  --icon-brands-magento:"\F3CA";
  --icon-brands-mailchimp:"\F3CB";
  --icon-brands-mandalorian:"\F3CC";
  --icon-brands-markdown:"\F3CD";
  --icon-brands-mastodon:"\F3CE";
  --icon-brands-maxcdn:"\F3CF";
  --icon-brands-medapps:"\F3D0";
  --icon-brands-medium-m:"\F3D1";
  --icon-brands-medium:"\F3D2";
  --icon-brands-medrt:"\F3D3";
  --icon-brands-meetup:"\F3D4";
  --icon-brands-megaport:"\F3D5";
  --icon-brands-mendeley:"\F3D6";
  --icon-brands-microsoft:"\F3D7";
  --icon-brands-mix:"\F3D8";
  --icon-brands-mixcloud:"\F3D9";
  --icon-brands-mizuni:"\F3DA";
  --icon-brands-modx:"\F3DB";
  --icon-brands-monero:"\F3DC";
  --icon-brands-napster:"\F3DD";
  --icon-brands-neos:"\F3DE";
  --icon-brands-nimblr:"\F3DF";
  --icon-brands-nintendo-switch:"\F3E0";
  --icon-brands-node-js:"\F3E1";
  --icon-brands-node:"\F3E2";
  --icon-brands-npm:"\F3E3";
  --icon-brands-ns8:"\F3E4";
  --icon-brands-nutritionix:"\F3E5";
  --icon-brands-odnoklassniki-square:"\F3E6";
  --icon-brands-odnoklassniki:"\F3E7";
  --icon-brands-old-republic:"\F3E8";
  --icon-brands-opencart:"\F3E9";
  --icon-brands-openid:"\F3EA";
  --icon-brands-opera:"\F3EB";
  --icon-brands-optin-monster:"\F3EC";
  --icon-brands-osi:"\F3ED";
  --icon-brands-page4:"\F3EE";
  --icon-brands-pagelines:"\F3EF";
  --icon-brands-palfed:"\F3F0";
  --icon-brands-patreon:"\F3F1";
  --icon-brands-paypal:"\F3F2";
  --icon-brands-penny-arcade:"\F3F3";
  --icon-brands-periscope:"\F3F4";
  --icon-brands-phabricator:"\F3F5";
  --icon-brands-phoenix-framework:"\F3F6";
  --icon-brands-phoenix-squadron:"\F3F7";
  --icon-brands-php:"\F3F8";
  --icon-brands-pied-piper-alt:"\F3F9";
  --icon-brands-pied-piper-hat:"\F3FA";
  --icon-brands-pied-piper-pp:"\F3FB";
  --icon-brands-pied-piper:"\F3FC";
  --icon-brands-pinterest-p:"\F3FD";
  --icon-brands-pinterest-square:"\F3FE";
  --icon-brands-pinterest:"\F3FF";
  --icon-brands-playstation:"\F400";
  --icon-brands-product-hunt:"\F401";
  --icon-brands-pushed:"\F402";
  --icon-brands-python:"\F403";
  --icon-brands-qq:"\F404";
  --icon-brands-quinscape:"\F405";
  --icon-brands-quora:"\F406";
  --icon-brands-r-project:"\F407";
  --icon-brands-raspberry-pi:"\F408";
  --icon-brands-ravelry:"\F409";
  --icon-brands-react:"\F40A";
  --icon-brands-reacteurope:"\F40B";
  --icon-brands-readme:"\F40C";
  --icon-brands-rebel:"\F40D";
  --icon-brands-red-river:"\F40E";
  --icon-brands-reddit-alien:"\F40F";
  --icon-brands-reddit-square:"\F410";
  --icon-brands-reddit:"\F411";
  --icon-brands-redhat:"\F412";
  --icon-brands-renren:"\F413";
  --icon-brands-replyd:"\F414";
  --icon-brands-researchgate:"\F415";
  --icon-brands-resolving:"\F416";
  --icon-brands-rev:"\F417";
  --icon-brands-rocketchat:"\F418";
  --icon-brands-rockrms:"\F419";
  --icon-brands-safari:"\F41A";
  --icon-brands-sass:"\F41B";
  --icon-brands-schlix:"\F41C";
  --icon-brands-scribd:"\F41D";
  --icon-brands-searchengin:"\F41E";
  --icon-brands-sellcast:"\F41F";
  --icon-brands-sellsy:"\F420";
  --icon-brands-servicestack:"\F421";
  --icon-brands-shirtsinbulk:"\F422";
  --icon-brands-shopware:"\F423";
  --icon-brands-simplybuilt:"\F424";
  --icon-brands-sistrix:"\F425";
  --icon-brands-sith:"\F426";
  --icon-brands-sketch:"\F427";
  --icon-brands-skyatlas:"\F428";
  --icon-brands-skype:"\F429";
  --icon-brands-slack-hash:"\F42A";
  --icon-brands-slack:"\F42B";
  --icon-brands-slideshare:"\F42C";
  --icon-brands-snapchat-ghost:"\F42D";
  --icon-brands-snapchat-square:"\F42E";
  --icon-brands-snapchat:"\F42F";
  --icon-brands-soundcloud:"\F430";
  --icon-brands-sourcetree:"\F431";
  --icon-brands-speakap:"\F432";
  --icon-brands-spotify:"\F433";
  --icon-brands-squarespace:"\F434";
  --icon-brands-stack-exchange:"\F435";
  --icon-brands-stack-overflow:"\F436";
  --icon-brands-staylinked:"\F437";
  --icon-brands-steam-square:"\F438";
  --icon-brands-steam-symbol:"\F439";
  --icon-brands-steam:"\F43A";
  --icon-brands-sticker-mule:"\F43B";
  --icon-brands-strava:"\F43C";
  --icon-brands-stripe-s:"\F43D";
  --icon-brands-stripe:"\F43E";
  --icon-brands-studiovinari:"\F43F";
  --icon-brands-stumbleupon-circle:"\F440";
  --icon-brands-stumbleupon:"\F441";
  --icon-brands-superpowers:"\F442";
  --icon-brands-supple:"\F443";
  --icon-brands-suse:"\F444";
  --icon-brands-teamspeak:"\F445";
  --icon-brands-telegram-plane:"\F446";
  --icon-brands-telegram:"\F447";
  --icon-brands-tencent-weibo:"\F448";
  --icon-brands-the-red-yeti:"\F449";
  --icon-brands-themeco:"\F44A";
  --icon-brands-themeisle:"\F44B";
  --icon-brands-think-peaks:"\F44C";
  --icon-brands-trade-federation:"\F44D";
  --icon-brands-trello:"\F44E";
  --icon-brands-tripadvisor:"\F44F";
  --icon-brands-tumblr-square:"\F450";
  --icon-brands-tumblr:"\F451";
  --icon-brands-twitch:"\F452";
  --icon-brands-twitter-square:"\F453";
  --icon-brands-twitter:"\F454";
  --icon-brands-typo3:"\F455";
  --icon-brands-uber:"\F456";
  --icon-brands-ubuntu:"\F457";
  --icon-brands-uikit:"\F458";
  --icon-brands-uniregistry:"\F459";
  --icon-brands-untappd:"\F45A";
  --icon-brands-ups:"\F45B";
  --icon-brands-usb:"\F45C";
  --icon-brands-usps:"\F45D";
  --icon-brands-ussunnah:"\F45E";
  --icon-brands-vaadin:"\F45F";
  --icon-brands-viacoin:"\F460";
  --icon-brands-viadeo-square:"\F461";
  --icon-brands-viadeo:"\F462";
  --icon-brands-viber:"\F463";
  --icon-brands-vimeo-square:"\F464";
  --icon-brands-vimeo-v:"\F465";
  --icon-brands-vimeo:"\F466";
  --icon-brands-vine:"\F467";
  --icon-brands-vk:"\F468";
  --icon-brands-vnv:"\F469";
  --icon-brands-vuejs:"\F46A";
  --icon-brands-weebly:"\F46B";
  --icon-brands-weibo:"\F46C";
  --icon-brands-weixin:"\F46D";
  --icon-brands-whatsapp-square:"\F46E";
  --icon-brands-whatsapp:"\F46F";
  --icon-brands-whmcs:"\F470";
  --icon-brands-wikipedia-w:"\F471";
  --icon-brands-windows:"\F472";
  --icon-brands-wix:"\F473";
  --icon-brands-wizards-of-the-coast:"\F474";
  --icon-brands-wolf-pack-battalion:"\F475";
  --icon-brands-wordpress-simple:"\F476";
  --icon-brands-wordpress:"\F477";
  --icon-brands-wpbeginner:"\F478";
  --icon-brands-wpexplorer:"\F479";
  --icon-brands-wpforms:"\F47A";
  --icon-brands-wpressr:"\F47B";
  --icon-brands-xbox:"\F47C";
  --icon-brands-xing-square:"\F47D";
  --icon-brands-xing:"\F47E";
  --icon-brands-y-combinator:"\F47F";
  --icon-brands-yahoo:"\F480";
  --icon-brands-yandex-international:"\F481";
  --icon-brands-yandex:"\F482";
  --icon-brands-yarn:"\F483";
  --icon-brands-yelp:"\F484";
  --icon-brands-yoast:"\F485";
  --icon-brands-youtube-square:"\F486";
  --icon-brands-youtube:"\F487";
  --icon-brands-zhihu:"\F488";
  --icon-address-book:"\F489";
  --icon-address-card:"\F48A";
  --icon-angry:"\F48B";
  --icon-arrow-alt-circle-down:"\F48C";
  --icon-arrow-alt-circle-left:"\F48D";
  --icon-arrow-alt-circle-right:"\F48E";
  --icon-arrow-alt-circle-up:"\F48F";
  --icon-bell-slash:"\F490";
  --icon-bookmark:"\F492";
  --icon-building:"\F493";
  --icon-calendar-alt:"\F494";
  --icon-calendar-check:"\F495";
  --icon-calendar-minus:"\F496";
  --icon-calendar-plus:"\F497";
  --icon-calendar-times:"\F498";
  --icon-caret-square-down:"\F49A";
  --icon-caret-square-left:"\F49B";
  --icon-caret-square-right:"\F49C";
  --icon-caret-square-up:"\F49D";
  --icon-chart-bar:"\F49E";
  --icon-check-circle:"\F49F";
  --icon-check-square:"\F4A0";
  --icon-circle:"\F4A1";
  --icon-clone:"\F4A4";
  --icon-closed-captioning:"\F4A5";
  --icon-comment-alt:"\F4A6";
  --icon-comment-dots:"\F4A7";
  --icon-comment:"\F4A8";
  --icon-comments:"\F4A9";
  --icon-copy:"\F4AB";
  --icon-copyright:"\F4AC";
  --icon-credit-card:"\F4AD";
  --icon-dizzy:"\F4AE";
  --icon-dot-circle:"\F4AF";
  --icon-edit:"\F4B0";
  --icon-envelope-open:"\F4B1";
  --icon-envelope:"\F4B2";
  --icon-eye-slash:"\F4B3";
  --icon-eye:"\F4B4";
  --icon-file-alt:"\F4B5";
  --icon-file-archive:"\F4B6";
  --icon-file-audio:"\F4B7";
  --icon-file-code:"\F4B8";
  --icon-file-excel:"\F4B9";
  --icon-file-image:"\F4BA";
  --icon-file-pdf:"\F4BB";
  --icon-file-powerpoint:"\F4BC";
  --icon-file-video:"\F4BD";
  --icon-file-word:"\F4BE";
  --icon-file:"\F4BF";
  --icon-flag:"\F4C0";
  --icon-flushed:"\F4C1";
  --icon-folder-open:"\F4C2";
  --icon-folder:"\F4C3";
  --icon-font-awesome-logo-full:"\F4C4";
  --icon-frown-open:"\F4C5";
  --icon-frown:"\F4C6";
  --icon-futbol:"\F4C7";
  --icon-gem:"\F4C8";
  --icon-grimace:"\F4C9";
  --icon-grin-alt:"\F4CA";
  --icon-grin-beam-sweat:"\F4CB";
  --icon-grin-beam:"\F4CC";
  --icon-grin-hearts:"\F4CD";
  --icon-grin-squint-tears:"\F4CE";
  --icon-grin-squint:"\F4CF";
  --icon-grin-stars:"\F4D0";
  --icon-grin-tears:"\F4D1";
  --icon-grin-tongue-squint:"\F4D2";
  --icon-grin-tongue-wink:"\F4D3";
  --icon-grin-tongue:"\F4D4";
  --icon-grin-wink:"\F4D5";
  --icon-grin:"\F4D6";
  --icon-hand-lizard:"\F4D7";
  --icon-hand-paper:"\F4D8";
  --icon-hand-peace:"\F4D9";
  --icon-hand-point-down:"\F4DA";
  --icon-hand-point-left:"\F4DB";
  --icon-hand-point-right:"\F4DC";
  --icon-hand-point-up:"\F4DD";
  --icon-hand-pointer:"\F4DE";
  --icon-hand-rock:"\F4DF";
  --icon-hand-scissors:"\F4E0";
  --icon-hand-spock:"\F4E1";
  --icon-handshake:"\F4E2";
  --icon-hdd:"\F4E3";
  --icon-heart:"\F4E4";
  --icon-hospital:"\F4E5";
  --icon-hourglass:"\F4E6";
  --icon-id-badge:"\F4E7";
  --icon-id-card:"\F4E8";
  --icon-image:"\F4E9";
  --icon-images:"\F4EA";
  --icon-kiss-beam:"\F4EC";
  --icon-kiss-wink-heart:"\F4ED";
  --icon-kiss:"\F4EE";
  --icon-laugh-beam:"\F4EF";
  --icon-laugh-squint:"\F4F0";
  --icon-laugh-wink:"\F4F1";
  --icon-laugh:"\F4F2";
  --icon-lemon:"\F4F3";
  --icon-life-ring:"\F4F4";
  --icon-lightbulb:"\F4F5";
  --icon-list-alt:"\F4F6";
  --icon-map:"\F4F7";
  --icon-meh-blank:"\F4F8";
  --icon-meh-rolling-eyes:"\F4F9";
  --icon-meh:"\F4FA";
  --icon-minus-square:"\F4FB";
  --icon-money-bill-alt:"\F4FC";
  --icon-moon:"\F4FD";
  --icon-object-group:"\F4FF";
  --icon-object-ungroup:"\F500";
  --icon-paper-plane:"\F501";
  --icon-pause-circle:"\F502";
  --icon-play-circle:"\F503";
  --icon-plus-square:"\F504";
  --icon-question-circle:"\F505";
  --icon-registered:"\F506";
  --icon-sad-cry:"\F507";
  --icon-sad-tear:"\F508";
  --icon-save:"\F509";
  --icon-share-square:"\F50A";
  --icon-smile-beam:"\F50B";
  --icon-smile-wink:"\F50C";
  --icon-smile:"\F50D";
  --icon-snowflake:"\F50E";
  --icon-square:"\F50F";
  --icon-star-half:"\F510";
  --icon-star:"\F511";
  --icon-sticky-note:"\F512";
  --icon-stop-circle:"\F513";
  --icon-sun:"\F514";
  --icon-surprise:"\F515";
  --icon-thumbs-down:"\F516";
  --icon-thumbs-up:"\F517";
  --icon-times-circle:"\F518";
  --icon-tired:"\F519";
  --icon-trash-alt:"\F51A";
  --icon-user-circle:"\F51B";
  --icon-user:"\F51C";
  --icon-window-close:"\F51D";
  --icon-window-maximize:"\F51E";
  --icon-window-minimize:"\F51F";
  --icon-window-restore:"\F520";
  --icon-solid-ad:"\F521";
  --icon-solid-address-book:"\F522";
  --icon-solid-address-card:"\F523";
  --icon-solid-adjust:"\F524";
  --icon-solid-air-freshener:"\F525";
  --icon-solid-align-center:"\F526";
  --icon-solid-align-justify:"\F527";
  --icon-solid-align-left:"\F528";
  --icon-solid-align-right:"\F529";
  --icon-solid-allergies:"\F52A";
  --icon-solid-ambulance:"\F52B";
  --icon-solid-american-sign-language-interpreting:"\F52C";
  --icon-solid-anchor:"\F52D";
  --icon-solid-angle-double-down:"\F52E";
  --icon-solid-angle-double-left:"\F52F";
  --icon-solid-angle-double-right:"\F530";
  --icon-solid-angle-double-up:"\F531";
  --icon-solid-angle-down:"\F532";
  --icon-solid-angle-left:"\F533";
  --icon-solid-angle-right:"\F534";
  --icon-solid-angle-up:"\F535";
  --icon-solid-angry:"\F536";
  --icon-solid-ankh:"\F537";
  --icon-solid-apple-alt:"\F538";
  --icon-solid-archive:"\F539";
  --icon-solid-archway:"\F53A";
  --icon-solid-arrow-alt-circle-down:"\F53B";
  --icon-solid-arrow-alt-circle-left:"\F53C";
  --icon-solid-arrow-alt-circle-right:"\F53D";
  --icon-solid-arrow-alt-circle-up:"\F53E";
  --icon-solid-arrow-circle-down:"\F53F";
  --icon-solid-arrow-circle-left:"\F540";
  --icon-solid-arrow-circle-right:"\F541";
  --icon-solid-arrow-circle-up:"\F542";
  --icon-solid-arrow-down:"\F543";
  --icon-solid-arrow-left:"\F544";
  --icon-solid-arrow-right:"\F545";
  --icon-solid-arrow-up:"\F546";
  --icon-solid-arrows-alt-h:"\F547";
  --icon-solid-arrows-alt-v:"\F548";
  --icon-solid-arrows-alt:"\F549";
  --icon-solid-assistive-listening-systems:"\F54A";
  --icon-solid-asterisk:"\F54B";
  --icon-solid-at:"\F54C";
  --icon-solid-atlas:"\F54D";
  --icon-solid-atom:"\F54E";
  --icon-solid-audio-description:"\F54F";
  --icon-solid-award:"\F550";
  --icon-solid-baby-carriage:"\F551";
  --icon-solid-baby:"\F552";
  --icon-solid-backspace:"\F553";
  --icon-solid-backward:"\F554";
  --icon-solid-bacon:"\F555";
  --icon-solid-balance-scale:"\F556";
  --icon-solid-ban:"\F557";
  --icon-solid-band-aid:"\F558";
  --icon-solid-barcode:"\F559";
  --icon-solid-bars:"\F55A";
  --icon-solid-baseball-ball:"\F55B";
  --icon-solid-basketball-ball:"\F55C";
  --icon-solid-bath:"\F55D";
  --icon-solid-battery-empty:"\F55E";
  --icon-solid-battery-full:"\F55F";
  --icon-solid-battery-half:"\F560";
  --icon-solid-battery-quarter:"\F561";
  --icon-solid-battery-three-quarters:"\F562";
  --icon-solid-bed:"\F563";
  --icon-solid-beer:"\F564";
  --icon-solid-bell-slash:"\F565";
  --icon-solid-bell:"\F566";
  --icon-solid-bezier-curve:"\F567";
  --icon-solid-bible:"\F568";
  --icon-solid-bicycle:"\F569";
  --icon-solid-binoculars:"\F56A";
  --icon-solid-biohazard:"\F56B";
  --icon-solid-birthday-cake:"\F56C";
  --icon-solid-blender-phone:"\F56D";
  --icon-solid-blender:"\F56E";
  --icon-solid-blind:"\F56F";
  --icon-solid-blog:"\F570";
  --icon-solid-bold:"\F571";
  --icon-solid-bolt:"\F572";
  --icon-solid-bomb:"\F573";
  --icon-solid-bone:"\F574";
  --icon-solid-bong:"\F575";
  --icon-solid-book-dead:"\F576";
  --icon-solid-book-medical:"\F577";
  --icon-solid-book-open:"\F578";
  --icon-solid-book-reader:"\F579";
  --icon-solid-book:"\F57A";
  --icon-solid-bookmark:"\F57B";
  --icon-solid-bowling-ball:"\F57C";
  --icon-solid-box-open:"\F57D";
  --icon-solid-box:"\F57E";
  --icon-solid-boxes:"\F57F";
  --icon-solid-braille:"\F580";
  --icon-solid-brain:"\F581";
  --icon-solid-bread-slice:"\F582";
  --icon-solid-briefcase-medical:"\F583";
  --icon-solid-briefcase:"\F584";
  --icon-solid-broadcast-tower:"\F585";
  --icon-solid-broom:"\F586";
  --icon-solid-brush:"\F587";
  --icon-solid-bug:"\F588";
  --icon-solid-building:"\F589";
  --icon-solid-bullhorn:"\F58A";
  --icon-solid-bullseye:"\F58B";
  --icon-solid-burn:"\F58C";
  --icon-solid-bus-alt:"\F58D";
  --icon-solid-bus:"\F58E";
  --icon-solid-business-time:"\F58F";
  --icon-solid-calculator:"\F590";
  --icon-solid-calendar-alt:"\F591";
  --icon-solid-calendar-check:"\F592";
  --icon-solid-calendar-day:"\F593";
  --icon-solid-calendar-minus:"\F594";
  --icon-solid-calendar-plus:"\F595";
  --icon-solid-calendar-times:"\F596";
  --icon-solid-calendar-week:"\F597";
  --icon-solid-calendar:"\F598";
  --icon-solid-camera-retro:"\F599";
  --icon-solid-camera:"\F59A";
  --icon-solid-campground:"\F59B";
  --icon-solid-candy-cane:"\F59C";
  --icon-solid-cannabis:"\F59D";
  --icon-solid-capsules:"\F59E";
  --icon-solid-car-alt:"\F59F";
  --icon-solid-car-battery:"\F5A0";
  --icon-solid-car-crash:"\F5A1";
  --icon-solid-car-side:"\F5A2";
  --icon-solid-car:"\F5A3";
  --icon-solid-caret-down:"\F5A4";
  --icon-solid-caret-left:"\F5A5";
  --icon-solid-caret-right:"\F5A6";
  --icon-solid-caret-square-down:"\F5A7";
  --icon-solid-caret-square-left:"\F5A8";
  --icon-solid-caret-square-right:"\F5A9";
  --icon-solid-caret-square-up:"\F5AA";
  --icon-solid-caret-up:"\F5AB";
  --icon-solid-carrot:"\F5AC";
  --icon-solid-cart-arrow-down:"\F5AD";
  --icon-solid-cart-plus:"\F5AE";
  --icon-solid-cash-register:"\F5AF";
  --icon-solid-cat:"\F5B0";
  --icon-solid-certificate:"\F5B1";
  --icon-solid-chair:"\F5B2";
  --icon-solid-chalkboard-teacher:"\F5B3";
  --icon-solid-chalkboard:"\F5B4";
  --icon-solid-charging-station:"\F5B5";
  --icon-solid-chart-area:"\F5B6";
  --icon-solid-chart-bar:"\F5B7";
  --icon-solid-chart-line:"\F5B8";
  --icon-solid-chart-pie:"\F5B9";
  --icon-solid-check-circle:"\F5BA";
  --icon-solid-check-double:"\F5BB";
  --icon-solid-check-square:"\F5BC";
  --icon-solid-check:"\F5BD";
  --icon-solid-cheese:"\F5BE";
  --icon-solid-chess-bishop:"\F5BF";
  --icon-solid-chess-board:"\F5C0";
  --icon-solid-chess-king:"\F5C1";
  --icon-solid-chess-knight:"\F5C2";
  --icon-solid-chess-pawn:"\F5C3";
  --icon-solid-chess-queen:"\F5C4";
  --icon-solid-chess-rook:"\F5C5";
  --icon-solid-chess:"\F5C6";
  --icon-solid-chevron-circle-down:"\F5C7";
  --icon-solid-chevron-circle-left:"\F5C8";
  --icon-solid-chevron-circle-right:"\F5C9";
  --icon-solid-chevron-circle-up:"\F5CA";
  --icon-solid-chevron-down:"\F5CB";
  --icon-solid-chevron-left:"\F5CC";
  --icon-solid-chevron-right:"\F5CD";
  --icon-solid-chevron-up:"\F5CE";
  --icon-solid-child:"\F5CF";
  --icon-solid-church:"\F5D0";
  --icon-solid-circle-notch:"\F5D1";
  --icon-solid-circle:"\F5D2";
  --icon-solid-city:"\F5D3";
  --icon-solid-clinic-medical:"\F5D4";
  --icon-solid-clipboard-check:"\F5D5";
  --icon-solid-clipboard-list:"\F5D6";
  --icon-solid-clipboard:"\F5D7";
  --icon-solid-clock:"\F5D8";
  --icon-solid-clone:"\F5D9";
  --icon-solid-closed-captioning:"\F5DA";
  --icon-solid-cloud-download-alt:"\F5DB";
  --icon-solid-cloud-meatball:"\F5DC";
  --icon-solid-cloud-moon-rain:"\F5DD";
  --icon-solid-cloud-moon:"\F5DE";
  --icon-solid-cloud-rain:"\F5DF";
  --icon-solid-cloud-showers-heavy:"\F5E0";
  --icon-solid-cloud-sun-rain:"\F5E1";
  --icon-solid-cloud-sun:"\F5E2";
  --icon-solid-cloud-upload-alt:"\F5E3";
  --icon-solid-cloud:"\F5E4";
  --icon-solid-cocktail:"\F5E5";
  --icon-solid-code-branch:"\F5E6";
  --icon-solid-code:"\F5E7";
  --icon-solid-coffee:"\F5E8";
  --icon-solid-cog:"\F5E9";
  --icon-solid-cogs:"\F5EA";
  --icon-solid-coins:"\F5EB";
  --icon-solid-columns:"\F5EC";
  --icon-solid-comment-alt:"\F5ED";
  --icon-solid-comment-dollar:"\F5EE";
  --icon-solid-comment-dots:"\F5EF";
  --icon-solid-comment-medical:"\F5F0";
  --icon-solid-comment-slash:"\F5F1";
  --icon-solid-comment:"\F5F2";
  --icon-solid-comments-dollar:"\F5F3";
  --icon-solid-comments:"\F5F4";
  --icon-solid-compact-disc:"\F5F5";
  --icon-solid-compass:"\F5F6";
  --icon-solid-compress-arrows-alt:"\F5F7";
  --icon-solid-compress:"\F5F8";
  --icon-solid-concierge-bell:"\F5F9";
  --icon-solid-cookie-bite:"\F5FA";
  --icon-solid-cookie:"\F5FB";
  --icon-solid-copy:"\F5FC";
  --icon-solid-copyright:"\F5FD";
  --icon-solid-couch:"\F5FE";
  --icon-solid-credit-card:"\F5FF";
  --icon-solid-crop-alt:"\F600";
  --icon-solid-crop:"\F601";
  --icon-solid-cross:"\F602";
  --icon-solid-crosshairs:"\F603";
  --icon-solid-crow:"\F604";
  --icon-solid-crown:"\F605";
  --icon-solid-crutch:"\F606";
  --icon-solid-cube:"\F607";
  --icon-solid-cubes:"\F608";
  --icon-solid-cut:"\F609";
  --icon-solid-database:"\F60A";
  --icon-solid-deaf:"\F60B";
  --icon-solid-democrat:"\F60C";
  --icon-solid-desktop:"\F60D";
  --icon-solid-dharmachakra:"\F60E";
  --icon-solid-diagnoses:"\F60F";
  --icon-solid-dice-d20:"\F610";
  --icon-solid-dice-d6:"\F611";
  --icon-solid-dice-five:"\F612";
  --icon-solid-dice-four:"\F613";
  --icon-solid-dice-one:"\F614";
  --icon-solid-dice-six:"\F615";
  --icon-solid-dice-three:"\F616";
  --icon-solid-dice-two:"\F617";
  --icon-solid-dice:"\F618";
  --icon-solid-digital-tachograph:"\F619";
  --icon-solid-directions:"\F61A";
  --icon-solid-divide:"\F61B";
  --icon-solid-dizzy:"\F61C";
  --icon-solid-dna:"\F61D";
  --icon-solid-dog:"\F61E";
  --icon-solid-dollar-sign:"\F61F";
  --icon-solid-dolly-flatbed:"\F620";
  --icon-solid-dolly:"\F621";
  --icon-solid-donate:"\F622";
  --icon-solid-door-closed:"\F623";
  --icon-solid-door-open:"\F624";
  --icon-solid-dot-circle:"\F625";
  --icon-solid-dove:"\F626";
  --icon-solid-download:"\F627";
  --icon-solid-drafting-compass:"\F628";
  --icon-solid-dragon:"\F629";
  --icon-solid-draw-polygon:"\F62A";
  --icon-solid-drum-steelpan:"\F62B";
  --icon-solid-drum:"\F62C";
  --icon-solid-drumstick-bite:"\F62D";
  --icon-solid-dumbbell:"\F62E";
  --icon-solid-dumpster-fire:"\F62F";
  --icon-solid-dumpster:"\F630";
  --icon-solid-dungeon:"\F631";
  --icon-solid-edit:"\F632";
  --icon-solid-egg:"\F633";
  --icon-solid-eject:"\F634";
  --icon-solid-ellipsis-h:"\F635";
  --icon-solid-ellipsis-v:"\F636";
  --icon-solid-envelope-open-text:"\F637";
  --icon-solid-envelope-open:"\F638";
  --icon-solid-envelope-square:"\F639";
  --icon-solid-envelope:"\F63A";
  --icon-solid-equals:"\F63B";
  --icon-solid-eraser:"\F63C";
  --icon-solid-ethernet:"\F63D";
  --icon-solid-euro-sign:"\F63E";
  --icon-solid-exchange-alt:"\F63F";
  --icon-solid-exclamation-circle:"\F640";
  --icon-solid-exclamation-triangle:"\F641";
  --icon-solid-exclamation:"\F642";
  --icon-solid-expand-arrows-alt:"\F643";
  --icon-solid-expand:"\F644";
  --icon-solid-external-link-alt:"\F645";
  --icon-solid-external-link-square-alt:"\F646";
  --icon-solid-eye-dropper:"\F647";
  --icon-solid-eye-slash:"\F648";
  --icon-solid-eye:"\F649";
  --icon-solid-fast-backward:"\F64A";
  --icon-solid-fast-forward:"\F64B";
  --icon-solid-fax:"\F64C";
  --icon-solid-feather-alt:"\F64D";
  --icon-solid-feather:"\F64E";
  --icon-solid-female:"\F64F";
  --icon-solid-fighter-jet:"\F650";
  --icon-solid-file-alt:"\F651";
  --icon-solid-file-archive:"\F652";
  --icon-solid-file-audio:"\F653";
  --icon-solid-file-code:"\F654";
  --icon-solid-file-contract:"\F655";
  --icon-solid-file-csv:"\F656";
  --icon-solid-file-download:"\F657";
  --icon-solid-file-excel:"\F658";
  --icon-solid-file-export:"\F659";
  --icon-solid-file-image:"\F65A";
  --icon-solid-file-import:"\F65B";
  --icon-solid-file-invoice-dollar:"\F65C";
  --icon-solid-file-invoice:"\F65D";
  --icon-solid-file-medical-alt:"\F65E";
  --icon-solid-file-medical:"\F65F";
  --icon-solid-file-pdf:"\F660";
  --icon-solid-file-powerpoint:"\F661";
  --icon-solid-file-prescription:"\F662";
  --icon-solid-file-signature:"\F663";
  --icon-solid-file-upload:"\F664";
  --icon-solid-file-video:"\F665";
  --icon-solid-file-word:"\F666";
  --icon-solid-file:"\F667";
  --icon-solid-fill-drip:"\F668";
  --icon-solid-fill:"\F669";
  --icon-solid-film:"\F66A";
  --icon-solid-filter:"\F66B";
  --icon-solid-fingerprint:"\F66C";
  --icon-solid-fire-alt:"\F66D";
  --icon-solid-fire-extinguisher:"\F66E";
  --icon-solid-fire:"\F66F";
  --icon-solid-first-aid:"\F670";
  --icon-solid-fish:"\F671";
  --icon-solid-fist-raised:"\F672";
  --icon-solid-flag-checkered:"\F673";
  --icon-solid-flag-usa:"\F674";
  --icon-solid-flag:"\F675";
  --icon-solid-flask:"\F676";
  --icon-solid-flushed:"\F677";
  --icon-solid-folder-minus:"\F678";
  --icon-solid-folder-open:"\F679";
  --icon-solid-folder-plus:"\F67A";
  --icon-solid-folder:"\F67B";
  --icon-solid-font-awesome-logo-full:"\F67C";
  --icon-solid-font:"\F67D";
  --icon-solid-football-ball:"\F67E";
  --icon-solid-forward:"\F67F";
  --icon-solid-frog:"\F680";
  --icon-solid-frown-open:"\F681";
  --icon-solid-frown:"\F682";
  --icon-solid-funnel-dollar:"\F683";
  --icon-solid-futbol:"\F684";
  --icon-solid-gamepad:"\F685";
  --icon-solid-gas-pump:"\F686";
  --icon-solid-gavel:"\F687";
  --icon-solid-gem:"\F688";
  --icon-solid-genderless:"\F689";
  --icon-solid-ghost:"\F68A";
  --icon-solid-gift:"\F68B";
  --icon-solid-gifts:"\F68C";
  --icon-solid-glass-cheers:"\F68D";
  --icon-solid-glass-martini-alt:"\F68E";
  --icon-solid-glass-martini:"\F68F";
  --icon-solid-glass-whiskey:"\F690";
  --icon-solid-glasses:"\F691";
  --icon-solid-globe-africa:"\F692";
  --icon-solid-globe-americas:"\F693";
  --icon-solid-globe-asia:"\F694";
  --icon-solid-globe-europe:"\F695";
  --icon-solid-globe:"\F696";
  --icon-solid-golf-ball:"\F697";
  --icon-solid-gopuram:"\F698";
  --icon-solid-graduation-cap:"\F699";
  --icon-solid-greater-than-equal:"\F69A";
  --icon-solid-greater-than:"\F69B";
  --icon-solid-grimace:"\F69C";
  --icon-solid-grin-alt:"\F69D";
  --icon-solid-grin-beam-sweat:"\F69E";
  --icon-solid-grin-beam:"\F69F";
  --icon-solid-grin-hearts:"\F6A0";
  --icon-solid-grin-squint-tears:"\F6A1";
  --icon-solid-grin-squint:"\F6A2";
  --icon-solid-grin-stars:"\F6A3";
  --icon-solid-grin-tears:"\F6A4";
  --icon-solid-grin-tongue-squint:"\F6A5";
  --icon-solid-grin-tongue-wink:"\F6A6";
  --icon-solid-grin-tongue:"\F6A7";
  --icon-solid-grin-wink:"\F6A8";
  --icon-solid-grin:"\F6A9";
  --icon-solid-grip-horizontal:"\F6AA";
  --icon-solid-grip-lines-vertical:"\F6AB";
  --icon-solid-grip-lines:"\F6AC";
  --icon-solid-grip-vertical:"\F6AD";
  --icon-solid-guitar:"\F6AE";
  --icon-solid-h-square:"\F6AF";
  --icon-solid-hamburger:"\F6B0";
  --icon-solid-hammer:"\F6B1";
  --icon-solid-hamsa:"\F6B2";
  --icon-solid-hand-holding-heart:"\F6B3";
  --icon-solid-hand-holding-usd:"\F6B4";
  --icon-solid-hand-holding:"\F6B5";
  --icon-solid-hand-lizard:"\F6B6";
  --icon-solid-hand-middle-finger:"\F6B7";
  --icon-solid-hand-paper:"\F6B8";
  --icon-solid-hand-peace:"\F6B9";
  --icon-solid-hand-point-down:"\F6BA";
  --icon-solid-hand-point-left:"\F6BB";
  --icon-solid-hand-point-right:"\F6BC";
  --icon-solid-hand-point-up:"\F6BD";
  --icon-solid-hand-pointer:"\F6BE";
  --icon-solid-hand-rock:"\F6BF";
  --icon-solid-hand-scissors:"\F6C0";
  --icon-solid-hand-spock:"\F6C1";
  --icon-solid-hands-helping:"\F6C2";
  --icon-solid-hands:"\F6C3";
  --icon-solid-handshake:"\F6C4";
  --icon-solid-hanukiah:"\F6C5";
  --icon-solid-hard-hat:"\F6C6";
  --icon-solid-hashtag:"\F6C7";
  --icon-solid-hat-wizard:"\F6C8";
  --icon-solid-haykal:"\F6C9";
  --icon-solid-hdd:"\F6CA";
  --icon-solid-heading:"\F6CB";
  --icon-solid-headphones-alt:"\F6CC";
  --icon-solid-headphones:"\F6CD";
  --icon-solid-headset:"\F6CE";
  --icon-solid-heart-broken:"\F6CF";
  --icon-solid-heart:"\F6D0";
  --icon-solid-heartbeat:"\F6D1";
  --icon-solid-helicopter:"\F6D2";
  --icon-solid-highlighter:"\F6D3";
  --icon-solid-hiking:"\F6D4";
  --icon-solid-hippo:"\F6D5";
  --icon-solid-history:"\F6D6";
  --icon-solid-hockey-puck:"\F6D7";
  --icon-solid-holly-berry:"\F6D8";
  --icon-solid-home:"\F6D9";
  --icon-solid-horse-head:"\F6DA";
  --icon-solid-horse:"\F6DB";
  --icon-solid-hospital-alt:"\F6DC";
  --icon-solid-hospital-symbol:"\F6DD";
  --icon-solid-hospital:"\F6DE";
  --icon-solid-hot-tub:"\F6DF";
  --icon-solid-hotdog:"\F6E0";
  --icon-solid-hotel:"\F6E1";
  --icon-solid-hourglass-end:"\F6E2";
  --icon-solid-hourglass-half:"\F6E3";
  --icon-solid-hourglass-start:"\F6E4";
  --icon-solid-hourglass:"\F6E5";
  --icon-solid-house-damage:"\F6E6";
  --icon-solid-hryvnia:"\F6E7";
  --icon-solid-i-cursor:"\F6E8";
  --icon-solid-ice-cream:"\F6E9";
  --icon-solid-icicles:"\F6EA";
  --icon-solid-id-badge:"\F6EB";
  --icon-solid-id-card-alt:"\F6EC";
  --icon-solid-id-card:"\F6ED";
  --icon-solid-igloo:"\F6EE";
  --icon-solid-image:"\F6EF";
  --icon-solid-images:"\F6F0";
  --icon-solid-inbox:"\F6F1";
  --icon-solid-indent:"\F6F2";
  --icon-solid-industry:"\F6F3";
  --icon-solid-infinity:"\F6F4";
  --icon-solid-info-circle:"\F6F5";
  --icon-solid-info:"\F6F6";
  --icon-solid-italic:"\F6F7";
  --icon-solid-jedi:"\F6F8";
  --icon-solid-joint:"\F6F9";
  --icon-solid-journal-whills:"\F6FA";
  --icon-solid-kaaba:"\F6FB";
  --icon-solid-key:"\F6FC";
  --icon-solid-keyboard:"\F6FD";
  --icon-solid-khanda:"\F6FE";
  --icon-solid-kiss-beam:"\F6FF";
  --icon-solid-kiss-wink-heart:"\F700";
  --icon-solid-kiss:"\F701";
  --icon-solid-kiwi-bird:"\F702";
  --icon-solid-landmark:"\F703";
  --icon-solid-language:"\F704";
  --icon-solid-laptop-code:"\F705";
  --icon-solid-laptop-medical:"\F706";
  --icon-solid-laptop:"\F707";
  --icon-solid-laugh-beam:"\F708";
  --icon-solid-laugh-squint:"\F709";
  --icon-solid-laugh-wink:"\F70A";
  --icon-solid-laugh:"\F70B";
  --icon-solid-layer-group:"\F70C";
  --icon-solid-leaf:"\F70D";
  --icon-solid-lemon:"\F70E";
  --icon-solid-less-than-equal:"\F70F";
  --icon-solid-less-than:"\F710";
  --icon-solid-level-down-alt:"\F711";
  --icon-solid-level-up-alt:"\F712";
  --icon-solid-life-ring:"\F713";
  --icon-solid-lightbulb:"\F714";
  --icon-solid-link:"\F715";
  --icon-solid-lira-sign:"\F716";
  --icon-solid-list-alt:"\F717";
  --icon-solid-list-ol:"\F718";
  --icon-solid-list-ul:"\F719";
  --icon-solid-list:"\F71A";
  --icon-solid-location-arrow:"\F71B";
  --icon-solid-lock-open:"\F71C";
  --icon-solid-lock:"\F71D";
  --icon-solid-long-arrow-alt-down:"\F71E";
  --icon-solid-long-arrow-alt-left:"\F71F";
  --icon-solid-long-arrow-alt-right:"\F720";
  --icon-solid-long-arrow-alt-up:"\F721";
  --icon-solid-low-vision:"\F722";
  --icon-solid-luggage-cart:"\F723";
  --icon-solid-magic:"\F724";
  --icon-solid-magnet:"\F725";
  --icon-solid-mail-bulk:"\F726";
  --icon-solid-male:"\F727";
  --icon-solid-map-marked-alt:"\F728";
  --icon-solid-map-marked:"\F729";
  --icon-solid-map-marker-alt:"\F72A";
  --icon-solid-map-marker:"\F72B";
  --icon-solid-map-pin:"\F72C";
  --icon-solid-map-signs:"\F72D";
  --icon-solid-map:"\F72E";
  --icon-solid-marker:"\F72F";
  --icon-solid-mars-double:"\F730";
  --icon-solid-mars-stroke-h:"\F731";
  --icon-solid-mars-stroke-v:"\F732";
  --icon-solid-mars-stroke:"\F733";
  --icon-solid-mars:"\F734";
  --icon-solid-mask:"\F735";
  --icon-solid-medal:"\F736";
  --icon-solid-medkit:"\F737";
  --icon-solid-meh-blank:"\F738";
  --icon-solid-meh-rolling-eyes:"\F739";
  --icon-solid-meh:"\F73A";
  --icon-solid-memory:"\F73B";
  --icon-solid-menorah:"\F73C";
  --icon-solid-mercury:"\F73D";
  --icon-solid-meteor:"\F73E";
  --icon-solid-microchip:"\F73F";
  --icon-solid-microphone-alt-slash:"\F740";
  --icon-solid-microphone-alt:"\F741";
  --icon-solid-microphone-slash:"\F742";
  --icon-solid-microphone:"\F743";
  --icon-solid-microscope:"\F744";
  --icon-solid-minus-circle:"\F745";
  --icon-solid-minus-square:"\F746";
  --icon-solid-minus:"\F747";
  --icon-solid-mitten:"\F748";
  --icon-solid-mobile-alt:"\F749";
  --icon-solid-mobile:"\F74A";
  --icon-solid-money-bill-alt:"\F74B";
  --icon-solid-money-bill-wave-alt:"\F74C";
  --icon-solid-money-bill-wave:"\F74D";
  --icon-solid-money-bill:"\F74E";
  --icon-solid-money-check-alt:"\F74F";
  --icon-solid-money-check:"\F750";
  --icon-solid-monument:"\F751";
  --icon-solid-moon:"\F752";
  --icon-solid-mortar-pestle:"\F753";
  --icon-solid-mosque:"\F754";
  --icon-solid-motorcycle:"\F755";
  --icon-solid-mountain:"\F756";
  --icon-solid-mouse-pointer:"\F757";
  --icon-solid-mug-hot:"\F758";
  --icon-solid-music:"\F759";
  --icon-solid-network-wired:"\F75A";
  --icon-solid-neuter:"\F75B";
  --icon-solid-newspaper:"\F75C";
  --icon-solid-not-equal:"\F75D";
  --icon-solid-notes-medical:"\F75E";
  --icon-solid-object-group:"\F75F";
  --icon-solid-object-ungroup:"\F760";
  --icon-solid-oil-can:"\F761";
  --icon-solid-om:"\F762";
  --icon-solid-otter:"\F763";
  --icon-solid-outdent:"\F764";
  --icon-solid-pager:"\F765";
  --icon-solid-paint-brush:"\F766";
  --icon-solid-paint-roller:"\F767";
  --icon-solid-palette:"\F768";
  --icon-solid-pallet:"\F769";
  --icon-solid-paper-plane:"\F76A";
  --icon-solid-paperclip:"\F76B";
  --icon-solid-parachute-box:"\F76C";
  --icon-solid-paragraph:"\F76D";
  --icon-solid-parking:"\F76E";
  --icon-solid-passport:"\F76F";
  --icon-solid-pastafarianism:"\F770";
  --icon-solid-paste:"\F771";
  --icon-solid-pause-circle:"\F772";
  --icon-solid-pause:"\F773";
  --icon-solid-paw:"\F774";
  --icon-solid-peace:"\F775";
  --icon-solid-pen-alt:"\F776";
  --icon-solid-pen-fancy:"\F777";
  --icon-solid-pen-nib:"\F778";
  --icon-solid-pen-square:"\F779";
  --icon-solid-pen:"\F77A";
  --icon-solid-pencil-alt:"\F77B";
  --icon-solid-pencil-ruler:"\F77C";
  --icon-solid-people-carry:"\F77D";
  --icon-solid-pepper-hot:"\F77E";
  --icon-solid-percent:"\F77F";
  --icon-solid-percentage:"\F780";
  --icon-solid-person-booth:"\F781";
  --icon-solid-phone-slash:"\F782";
  --icon-solid-phone-square:"\F783";
  --icon-solid-phone-volume:"\F784";
  --icon-solid-phone:"\F785";
  --icon-solid-piggy-bank:"\F786";
  --icon-solid-pills:"\F787";
  --icon-solid-pizza-slice:"\F788";
  --icon-solid-place-of-worship:"\F789";
  --icon-solid-plane-arrival:"\F78A";
  --icon-solid-plane-departure:"\F78B";
  --icon-solid-plane:"\F78C";
  --icon-solid-play-circle:"\F78D";
  --icon-solid-play:"\F78E";
  --icon-solid-plug:"\F78F";
  --icon-solid-plus-circle:"\F790";
  --icon-solid-plus-square:"\F791";
  --icon-solid-plus:"\F792";
  --icon-solid-podcast:"\F793";
  --icon-solid-poll-h:"\F794";
  --icon-solid-poll:"\F795";
  --icon-solid-poo-storm:"\F796";
  --icon-solid-poo:"\F797";
  --icon-solid-poop:"\F798";
  --icon-solid-portrait:"\F799";
  --icon-solid-pound-sign:"\F79A";
  --icon-solid-power-off:"\F79B";
  --icon-solid-pray:"\F79C";
  --icon-solid-praying-hands:"\F79D";
  --icon-solid-prescription-bottle-alt:"\F79E";
  --icon-solid-prescription-bottle:"\F79F";
  --icon-solid-prescription:"\F7A0";
  --icon-solid-print:"\F7A1";
  --icon-solid-procedures:"\F7A2";
  --icon-solid-project-diagram:"\F7A3";
  --icon-solid-puzzle-piece:"\F7A4";
  --icon-solid-qrcode:"\F7A5";
  --icon-solid-question-circle:"\F7A6";
  --icon-solid-question:"\F7A7";
  --icon-solid-quidditch:"\F7A8";
  --icon-solid-quote-left:"\F7A9";
  --icon-solid-quote-right:"\F7AA";
  --icon-solid-quran:"\F7AB";
  --icon-solid-radiation-alt:"\F7AC";
  --icon-solid-radiation:"\F7AD";
  --icon-solid-rainbow:"\F7AE";
  --icon-solid-random:"\F7AF";
  --icon-solid-receipt:"\F7B0";
  --icon-solid-recycle:"\F7B1";
  --icon-solid-redo-alt:"\F7B2";
  --icon-solid-redo:"\F7B3";
  --icon-solid-registered:"\F7B4";
  --icon-solid-reply-all:"\F7B5";
  --icon-solid-reply:"\F7B6";
  --icon-solid-republican:"\F7B7";
  --icon-solid-restroom:"\F7B8";
  --icon-solid-retweet:"\F7B9";
  --icon-solid-ribbon:"\F7BA";
  --icon-solid-ring:"\F7BB";
  --icon-solid-road:"\F7BC";
  --icon-solid-robot:"\F7BD";
  --icon-solid-rocket:"\F7BE";
  --icon-solid-route:"\F7BF";
  --icon-solid-rss-square:"\F7C0";
  --icon-solid-rss:"\F7C1";
  --icon-solid-ruble-sign:"\F7C2";
  --icon-solid-ruler-combined:"\F7C3";
  --icon-solid-ruler-horizontal:"\F7C4";
  --icon-solid-ruler-vertical:"\F7C5";
  --icon-solid-ruler:"\F7C6";
  --icon-solid-running:"\F7C7";
  --icon-solid-rupee-sign:"\F7C8";
  --icon-solid-sad-cry:"\F7C9";
  --icon-solid-sad-tear:"\F7CA";
  --icon-solid-satellite-dish:"\F7CB";
  --icon-solid-satellite:"\F7CC";
  --icon-solid-save:"\F7CD";
  --icon-solid-school:"\F7CE";
  --icon-solid-screwdriver:"\F7CF";
  --icon-solid-scroll:"\F7D0";
  --icon-solid-sd-card:"\F7D1";
  --icon-solid-search-dollar:"\F7D2";
  --icon-solid-search-location:"\F7D3";
  --icon-solid-search-minus:"\F7D4";
  --icon-solid-search-plus:"\F7D5";
  --icon-solid-search:"\F7D6";
  --icon-solid-seedling:"\F7D7";
  --icon-solid-server:"\F7D8";
  --icon-solid-shapes:"\F7D9";
  --icon-solid-share-alt-square:"\F7DA";
  --icon-solid-share-alt:"\F7DB";
  --icon-solid-share-square:"\F7DC";
  --icon-solid-share:"\F7DD";
  --icon-solid-shekel-sign:"\F7DE";
  --icon-solid-shield-alt:"\F7DF";
  --icon-solid-ship:"\F7E0";
  --icon-solid-shipping-fast:"\F7E1";
  --icon-solid-shoe-prints:"\F7E2";
  --icon-solid-shopping-bag:"\F7E3";
  --icon-solid-shopping-basket:"\F7E4";
  --icon-solid-shopping-cart:"\F7E5";
  --icon-solid-shower:"\F7E6";
  --icon-solid-shuttle-van:"\F7E7";
  --icon-solid-sign-in-alt:"\F7E8";
  --icon-solid-sign-language:"\F7E9";
  --icon-solid-sign-out-alt:"\F7EA";
  --icon-solid-sign:"\F7EB";
  --icon-solid-signal:"\F7EC";
  --icon-solid-signature:"\F7ED";
  --icon-solid-sim-card:"\F7EE";
  --icon-solid-sitemap:"\F7EF";
  --icon-solid-skating:"\F7F0";
  --icon-solid-skiing-nordic:"\F7F1";
  --icon-solid-skiing:"\F7F2";
  --icon-solid-skull-crossbones:"\F7F3";
  --icon-solid-skull:"\F7F4";
  --icon-solid-slash:"\F7F5";
  --icon-solid-sleigh:"\F7F6";
  --icon-solid-sliders-h:"\F7F7";
  --icon-solid-smile-beam:"\F7F8";
  --icon-solid-smile-wink:"\F7F9";
  --icon-solid-smile:"\F7FA";
  --icon-solid-smog:"\F7FB";
  --icon-solid-smoking-ban:"\F7FC";
  --icon-solid-smoking:"\F7FD";
  --icon-solid-sms:"\F7FE";
  --icon-solid-snowboarding:"\F7FF";
  --icon-solid-snowflake:"\F800";
  --icon-solid-snowman:"\F801";
  --icon-solid-snowplow:"\F802";
  --icon-solid-socks:"\F803";
  --icon-solid-solar-panel:"\F804";
  --icon-solid-sort-alpha-down:"\F805";
  --icon-solid-sort-alpha-up:"\F806";
  --icon-solid-sort-amount-down:"\F807";
  --icon-solid-sort-amount-up:"\F808";
  --icon-solid-sort-down:"\F809";
  --icon-solid-sort-numeric-down:"\F80A";
  --icon-solid-sort-numeric-up:"\F80B";
  --icon-solid-sort-up:"\F80C";
  --icon-solid-sort:"\F80D";
  --icon-solid-spa:"\F80E";
  --icon-solid-space-shuttle:"\F80F";
  --icon-solid-spider:"\F810";
  --icon-solid-spinner:"\F811";
  --icon-solid-splotch:"\F812";
  --icon-solid-spray-can:"\F813";
  --icon-solid-square-full:"\F814";
  --icon-solid-square-root-alt:"\F815";
  --icon-solid-square:"\F816";
  --icon-solid-stamp:"\F817";
  --icon-solid-star-and-crescent:"\F818";
  --icon-solid-star-half-alt:"\F819";
  --icon-solid-star-half:"\F81A";
  --icon-solid-star-of-david:"\F81B";
  --icon-solid-star-of-life:"\F81C";
  --icon-solid-star:"\F81D";
  --icon-solid-step-backward:"\F81E";
  --icon-solid-step-forward:"\F81F";
  --icon-solid-stethoscope:"\F820";
  --icon-solid-sticky-note:"\F821";
  --icon-solid-stop-circle:"\F822";
  --icon-solid-stop:"\F823";
  --icon-solid-stopwatch:"\F824";
  --icon-solid-store-alt:"\F825";
  --icon-solid-store:"\F826";
  --icon-solid-stream:"\F827";
  --icon-solid-street-view:"\F828";
  --icon-solid-strikethrough:"\F829";
  --icon-solid-stroopwafel:"\F82A";
  --icon-solid-subscript:"\F82B";
  --icon-solid-subway:"\F82C";
  --icon-solid-suitcase-rolling:"\F82D";
  --icon-solid-suitcase:"\F82E";
  --icon-solid-sun:"\F82F";
  --icon-solid-superscript:"\F830";
  --icon-solid-surprise:"\F831";
  --icon-solid-swatchbook:"\F832";
  --icon-solid-swimmer:"\F833";
  --icon-solid-swimming-pool:"\F834";
  --icon-solid-synagogue:"\F835";
  --icon-solid-sync-alt:"\F836";
  --icon-solid-sync:"\F837";
  --icon-solid-syringe:"\F838";
  --icon-solid-table-tennis:"\F839";
  --icon-solid-table:"\F83A";
  --icon-solid-tablet-alt:"\F83B";
  --icon-solid-tablet:"\F83C";
  --icon-solid-tablets:"\F83D";
  --icon-solid-tachometer-alt:"\F83E";
  --icon-solid-tag:"\F83F";
  --icon-solid-tags:"\F840";
  --icon-solid-tape:"\F841";
  --icon-solid-tasks:"\F842";
  --icon-solid-taxi:"\F843";
  --icon-solid-teeth-open:"\F844";
  --icon-solid-teeth:"\F845";
  --icon-solid-temperature-high:"\F846";
  --icon-solid-temperature-low:"\F847";
  --icon-solid-tenge:"\F848";
  --icon-solid-terminal:"\F849";
  --icon-solid-text-height:"\F84A";
  --icon-solid-text-width:"\F84B";
  --icon-solid-th-large:"\F84C";
  --icon-solid-th-list:"\F84D";
  --icon-solid-th:"\F84E";
  --icon-solid-theater-masks:"\F84F";
  --icon-solid-thermometer-empty:"\F850";
  --icon-solid-thermometer-full:"\F851";
  --icon-solid-thermometer-half:"\F852";
  --icon-solid-thermometer-quarter:"\F853";
  --icon-solid-thermometer-three-quarters:"\F854";
  --icon-solid-thermometer:"\F855";
  --icon-solid-thumbs-down:"\F856";
  --icon-solid-thumbs-up:"\F857";
  --icon-solid-thumbtack:"\F858";
  --icon-solid-ticket-alt:"\F859";
  --icon-solid-times-circle:"\F85A";
  --icon-solid-times:"\F85B";
  --icon-solid-tint-slash:"\F85C";
  --icon-solid-tint:"\F85D";
  --icon-solid-tired:"\F85E";
  --icon-solid-toggle-off:"\F85F";
  --icon-solid-toggle-on:"\F860";
  --icon-solid-toilet-paper:"\F861";
  --icon-solid-toilet:"\F862";
  --icon-solid-toolbox:"\F863";
  --icon-solid-tools:"\F864";
  --icon-solid-tooth:"\F865";
  --icon-solid-torah:"\F866";
  --icon-solid-torii-gate:"\F867";
  --icon-solid-tractor:"\F868";
  --icon-solid-trademark:"\F869";
  --icon-solid-traffic-light:"\F86A";
  --icon-solid-train:"\F86B";
  --icon-solid-tram:"\F86C";
  --icon-solid-transgender-alt:"\F86D";
  --icon-solid-transgender:"\F86E";
  --icon-solid-trash-alt:"\F86F";
  --icon-solid-trash-restore-alt:"\F870";
  --icon-solid-trash-restore:"\F871";
  --icon-solid-trash:"\F872";
  --icon-solid-tree:"\F873";
  --icon-solid-trophy:"\F874";
  --icon-solid-truck-loading:"\F875";
  --icon-solid-truck-monster:"\F876";
  --icon-solid-truck-moving:"\F877";
  --icon-solid-truck-pickup:"\F878";
  --icon-solid-truck:"\F879";
  --icon-solid-tshirt:"\F87A";
  --icon-solid-tty:"\F87B";
  --icon-solid-tv:"\F87C";
  --icon-solid-umbrella-beach:"\F87D";
  --icon-solid-umbrella:"\F87E";
  --icon-solid-underline:"\F87F";
  --icon-solid-undo-alt:"\F880";
  --icon-solid-undo:"\F881";
  --icon-solid-universal-access:"\F882";
  --icon-solid-university:"\F883";
  --icon-solid-unlink:"\F884";
  --icon-solid-unlock-alt:"\F885";
  --icon-solid-unlock:"\F886";
  --icon-solid-upload:"\F887";
  --icon-solid-user-alt-slash:"\F888";
  --icon-solid-user-alt:"\F889";
  --icon-solid-user-astronaut:"\F88A";
  --icon-solid-user-check:"\F88B";
  --icon-solid-user-circle:"\F88C";
  --icon-solid-user-clock:"\F88D";
  --icon-solid-user-cog:"\F88E";
  --icon-solid-user-edit:"\F88F";
  --icon-solid-user-friends:"\F890";
  --icon-solid-user-graduate:"\F891";
  --icon-solid-user-injured:"\F892";
  --icon-solid-user-lock:"\F893";
  --icon-solid-user-md:"\F894";
  --icon-solid-user-minus:"\F895";
  --icon-solid-user-ninja:"\F896";
  --icon-solid-user-nurse:"\F897";
  --icon-solid-user-plus:"\F898";
  --icon-solid-user-secret:"\F899";
  --icon-solid-user-shield:"\F89A";
  --icon-solid-user-slash:"\F89B";
  --icon-solid-user-tag:"\F89C";
  --icon-solid-user-tie:"\F89D";
  --icon-solid-user-times:"\F89E";
  --icon-solid-user:"\F89F";
  --icon-solid-users-cog:"\F8A0";
  --icon-solid-users:"\F8A1";
  --icon-solid-utensil-spoon:"\F8A2";
  --icon-solid-utensils:"\F8A3";
  --icon-solid-vector-square:"\F8A4";
  --icon-solid-venus-double:"\F8A5";
  --icon-solid-venus-mars:"\F8A6";
  --icon-solid-venus:"\F8A7";
  --icon-solid-vial:"\F8A8";
  --icon-solid-vials:"\F8A9";
  --icon-solid-video-slash:"\F8AA";
  --icon-solid-video:"\F8AB";
  --icon-solid-vihara:"\F8AC";
  --icon-solid-volleyball-ball:"\F8AD";
  --icon-solid-volume-down:"\F8AE";
  --icon-solid-volume-mute:"\F8AF";
  --icon-solid-volume-off:"\F8B0";
  --icon-solid-volume-up:"\F8B1";
  --icon-solid-vote-yea:"\F8B2";
  --icon-solid-vr-cardboard:"\F8B3";
  --icon-solid-walking:"\F8B4";
  --icon-solid-wallet:"\F8B5";
  --icon-solid-warehouse:"\F8B6";
  --icon-solid-water:"\F8B7";
  --icon-solid-weight-hanging:"\F8B8";
  --icon-solid-weight:"\F8B9";
  --icon-solid-wheelchair:"\F8BA";
  --icon-solid-wifi:"\F8BB";
  --icon-solid-wind:"\F8BC";
  --icon-solid-window-close:"\F8BD";
  --icon-solid-window-maximize:"\F8BE";
  --icon-solid-window-minimize:"\F8BF";
  --icon-solid-window-restore:"\F8C0";
  --icon-solid-wine-bottle:"\F8C1";
  --icon-solid-wine-glass-alt:"\F8C2";
  --icon-solid-wine-glass:"\F8C3";
  --icon-solid-won-sign:"\F8C4";
  --icon-solid-wrench:"\F8C5";
  --icon-solid-x-ray:"\F8C6";
  --icon-solid-yen-sign:"\F8C7";
  --icon-solid-yin-yang:"\F8C8";
}

.icon-24v:before {
  content: var(--icon-24v);
}

.icon-BR:before {
  content: var(--icon-BR);
}

.icon-Mitsubishi-Electric-large:before {
  content: var(--icon-Mitsubishi-Electric-large);
}

.icon-Mitsubishi_Electric_icon:before {
  content: var(--icon-Mitsubishi_Electric_icon);
}

.icon-ai-1:before {
  content: var(--icon-ai-1);
}

.icon-aiguillage:before {
  content: var(--icon-aiguillage);
}

.icon-alarm:before {
  content: var(--icon-alarm);
}

.icon-allen-bradley-icon:before {
  content: var(--icon-allen-bradley-icon);
}

.icon-allen-bradley-large:before {
  content: var(--icon-allen-bradley-large);
}

.icon-analyse-grain:before {
  content: var(--icon-analyse-grain);
}

.icon-analyse:before {
  content: var(--icon-analyse);
}

.icon-api:before {
  content: var(--icon-api);
}

.icon-appareil-1:before {
  content: var(--icon-appareil-1);
}

.icon-appareil-2:before {
  content: var(--icon-appareil-2);
}

.icon-appareil-3:before {
  content: var(--icon-appareil-3);
}

.icon-asi:before {
  content: var(--icon-asi);
}

.icon-aside-dock:before {
  content: var(--icon-aside-dock);
}

.icon-aside:before {
  content: var(--icon-aside);
}

.icon-asp-1:before {
  content: var(--icon-asp-1);
}

.icon-attention:before {
  content: var(--icon-attention);
}

.icon-au-1:before {
  content: var(--icon-au-1);
}

.icon-au-2:before {
  content: var(--icon-au-2);
}

.icon-au-3:before {
  content: var(--icon-au-3);
}

.icon-au-4:before {
  content: var(--icon-au-4);
}

.icon-au-5:before {
  content: var(--icon-au-5);
}

.icon-au-6:before {
  content: var(--icon-au-6);
}

.icon-au-7:before {
  content: var(--icon-au-7);
}

.icon-au-8:before {
  content: var(--icon-au-8);
}

.icon-autoprog-2021-icon-bold:before {
  content: var(--icon-autoprog-2021-icon-bold);
}

.icon-autoprog-2021-icon:before {
  content: var(--icon-autoprog-2021-icon);
}

.icon-autoprog-2021:before {
  content: var(--icon-autoprog-2021);
}

.icon-autoprog-icon:before {
  content: var(--icon-autoprog-icon);
}

.icon-autoprog-new-full-2018:before {
  content: var(--icon-autoprog-new-full-2018);
}

.icon-autoprog-new-full-reverse-2018:before {
  content: var(--icon-autoprog-new-full-reverse-2018);
}

.icon-autoprog-old:before {
  content: var(--icon-autoprog-old);
}

.icon-badge:before {
  content: var(--icon-badge);
}

.icon-balance:before {
  content: var(--icon-balance);
}

.icon-bars:before {
  content: var(--icon-bars);
}

.icon-bascule:before {
  content: var(--icon-bascule);
}

.icon-battery-0:before {
  content: var(--icon-battery-0);
}

.icon-battery-10:before {
  content: var(--icon-battery-10);
}

.icon-battery-100:before {
  content: var(--icon-battery-100);
}

.icon-battery-20:before {
  content: var(--icon-battery-20);
}

.icon-battery-30:before {
  content: var(--icon-battery-30);
}

.icon-battery-40:before {
  content: var(--icon-battery-40);
}

.icon-battery-50:before {
  content: var(--icon-battery-50);
}

.icon-battery-60:before {
  content: var(--icon-battery-60);
}

.icon-battery-70:before {
  content: var(--icon-battery-70);
}

.icon-battery-80:before {
  content: var(--icon-battery-80);
}

.icon-battery-90:before {
  content: var(--icon-battery-90);
}

.icon-beckhoff-full:before {
  content: var(--icon-beckhoff-full);
}

.icon-beckhoff-icon:before {
  content: var(--icon-beckhoff-icon);
}

.icon-bell:before {
  content: var(--icon-bell);
}

.icon-bihl-wiedemann-icon:before {
  content: var(--icon-bihl-wiedemann-icon);
}

.icon-bin:before {
  content: var(--icon-bin);
}

.icon-binoculars:before {
  content: var(--icon-binoculars);
}

.icon-biohazard-2:before {
  content: var(--icon-biohazard-2);
}

.icon-biohazard:before {
  content: var(--icon-biohazard);
}

.icon-blocked:before {
  content: var(--icon-blocked);
}

.icon-boite-1:before {
  content: var(--icon-boite-1);
}

.icon-boite-3D:before {
  content: var(--icon-boite-3D);
}

.icon-boite-change-1:before {
  content: var(--icon-boite-change-1);
}

.icon-boite-change-2:before {
  content: var(--icon-boite-change-2);
}

.icon-boite-droite-1:before {
  content: var(--icon-boite-droite-1);
}

.icon-boite-droite-2:before {
  content: var(--icon-boite-droite-2);
}

.icon-boite-gauche-1:before {
  content: var(--icon-boite-gauche-1);
}

.icon-boite-gauche-2:before {
  content: var(--icon-boite-gauche-2);
}

.icon-bolt:before {
  content: var(--icon-bolt);
}

.icon-box-add:before {
  content: var(--icon-box-add);
}

.icon-box-remove:before {
  content: var(--icon-box-remove);
}

.icon-bright:before {
  content: var(--icon-bright);
}

.icon-brightness-contrast:before {
  content: var(--icon-brightness-contrast);
}

.icon-bubble:before {
  content: var(--icon-bubble);
}

.icon-bubble2:before {
  content: var(--icon-bubble2);
}

.icon-bullhorn:before {
  content: var(--icon-bullhorn);
}

.icon-business:before {
  content: var(--icon-business);
}

.icon-calculator:before {
  content: var(--icon-calculator);
}

.icon-calendar:before {
  content: var(--icon-calendar);
}

.icon-call:before {
  content: var(--icon-call);
}

.icon-cancel-circle:before {
  content: var(--icon-cancel-circle);
}

.icon-cancel:before {
  content: var(--icon-cancel);
}

.icon-canopen-icon:before {
  content: var(--icon-canopen-icon);
}

.icon-canopen-vector-logo:before {
  content: var(--icon-canopen-vector-logo);
}

.icon-cellule-interdit:before {
  content: var(--icon-cellule-interdit);
}

.icon-cellule-new:before {
  content: var(--icon-cellule-new);
}

.icon-cellule:before {
  content: var(--icon-cellule);
}

.icon-chariot:before {
  content: var(--icon-chariot);
}

.icon-chart:before {
  content: var(--icon-chart);
}

.icon-checkall:before {
  content: var(--icon-checkall);
}

.icon-checkbox-checked:before {
  content: var(--icon-checkbox-checked);
}

.icon-checkbox-partial:before {
  content: var(--icon-checkbox-partial);
}

.icon-checkbox-unchecked:before {
  content: var(--icon-checkbox-unchecked);
}

.icon-checkmark:before {
  content: var(--icon-checkmark);
}

.icon-checkmark2:before {
  content: var(--icon-checkmark2);
}

.icon-circuit:before {
  content: var(--icon-circuit);
}

.icon-clipboard:before {
  content: var(--icon-clipboard);
}

.icon-clock:before {
  content: var(--icon-clock);
}

.icon-clock2:before {
  content: var(--icon-clock2);
}

.icon-coffee:before {
  content: var(--icon-coffee);
}

.icon-cog:before {
  content: var(--icon-cog);
}

.icon-cogs:before {
  content: var(--icon-cogs);
}

.icon-coin-dollar:before {
  content: var(--icon-coin-dollar);
}

.icon-colors:before {
  content: var(--icon-colors);
}

.icon-combobox:before {
  content: var(--icon-combobox);
}

.icon-compass:before {
  content: var(--icon-compass);
}

.icon-compass2:before {
  content: var(--icon-compass2);
}

.icon-compresseur-2:before {
  content: var(--icon-compresseur-2);
}

.icon-compresseur:before {
  content: var(--icon-compresseur);
}

.icon-construction:before {
  content: var(--icon-construction);
}

.icon-control-joystick:before {
  content: var(--icon-control-joystick);
}

.icon-cord:before {
  content: var(--icon-cord);
}

.icon-corn:before {
  content: var(--icon-corn);
}

.icon-cutwire:before {
  content: var(--icon-cutwire);
}

.icon-destination-interdit:before {
  content: var(--icon-destination-interdit);
}

.icon-destination:before {
  content: var(--icon-destination);
}

.icon-devicenet-icon:before {
  content: var(--icon-devicenet-icon);
}

.icon-devicenet-new:before {
  content: var(--icon-devicenet-new);
}

.icon-diagram:before {
  content: var(--icon-diagram);
}

.icon-document:before {
  content: var(--icon-document);
}

.icon-droplet:before {
  content: var(--icon-droplet);
}

.icon-echantillon-grain:before {
  content: var(--icon-echantillon-grain);
}

.icon-ecl-1:before {
  content: var(--icon-ecl-1);
}

.icon-egg-2:before {
  content: var(--icon-egg-2);
}

.icon-egg:before {
  content: var(--icon-egg);
}

.icon-el-1:before {
  content: var(--icon-el-1);
}

.icon-ethercat-full:before {
  content: var(--icon-ethercat-full);
}

.icon-ethercat-icon:before {
  content: var(--icon-ethercat-icon);
}

.icon-ethernet-ip-icon:before {
  content: var(--icon-ethernet-ip-icon);
}

.icon-ethernet-ip-new:before {
  content: var(--icon-ethernet-ip-new);
}

.icon-ethernet-version-1:before {
  content: var(--icon-ethernet-version-1);
}

.icon-ethernet-version-2:before {
  content: var(--icon-ethernet-version-2);
}

.icon-extracteur:before {
  content: var(--icon-extracteur);
}

.icon-fan-1:before {
  content: var(--icon-fan-1);
}

.icon-fan-2:before {
  content: var(--icon-fan-2);
}

.icon-filt-1:before {
  content: var(--icon-filt-1);
}

.icon-filter-fill:before {
  content: var(--icon-filter-fill);
}

.icon-filter-line:before {
  content: var(--icon-filter-line);
}

.icon-filter-off-fill:before {
  content: var(--icon-filter-off-fill);
}

.icon-filter-off-line:before {
  content: var(--icon-filter-off-line);
}

.icon-filter:before {
  content: var(--icon-filter);
}

.icon-fire:before {
  content: var(--icon-fire);
}

.icon-floor-brush:before {
  content: var(--icon-floor-brush);
}

.icon-fond-plat-1:before {
  content: var(--icon-fond-plat-1);
}

.icon-forced-fan:before {
  content: var(--icon-forced-fan);
}

.icon-grain-alone-2:before {
  content: var(--icon-grain-alone-2);
}

.icon-grain-alone:before {
  content: var(--icon-grain-alone);
}

.icon-grain-dual-2:before {
  content: var(--icon-grain-dual-2);
}

.icon-grain-dual:before {
  content: var(--icon-grain-dual);
}

.icon-grain-familly:before {
  content: var(--icon-grain-familly);
}

.icon-grain-feld:before {
  content: var(--icon-grain-feld);
}

.icon-graph:before {
  content: var(--icon-graph);
}

.icon-gsm-0:before {
  content: var(--icon-gsm-0);
}

.icon-gsm-1:before {
  content: var(--icon-gsm-1);
}

.icon-gsm-2:before {
  content: var(--icon-gsm-2);
}

.icon-gsm-3:before {
  content: var(--icon-gsm-3);
}

.icon-gsm-4:before {
  content: var(--icon-gsm-4);
}

.icon-hand:before {
  content: var(--icon-hand);
}

.icon-history:before {
  content: var(--icon-history);
}

.icon-humidite:before {
  content: var(--icon-humidite);
}

.icon-ifm:before {
  content: var(--icon-ifm);
}

.icon-infraliseur:before {
  content: var(--icon-infraliseur);
}

.icon-input:before {
  content: var(--icon-input);
}

.icon-keyboard:before {
  content: var(--icon-keyboard);
}

.icon-leaf:before {
  content: var(--icon-leaf);
}

.icon-liens:before {
  content: var(--icon-liens);
}

.icon-loader:before {
  content: var(--icon-loader);
}

.icon-location:before {
  content: var(--icon-location);
}

.icon-location2:before {
  content: var(--icon-location2);
}

.icon-lock:before {
  content: var(--icon-lock);
}

.icon-magic-wand:before {
  content: var(--icon-magic-wand);
}

.icon-magnifying-glass:before {
  content: var(--icon-magnifying-glass);
}

.icon-manual:before {
  content: var(--icon-manual);
}

.icon-menu:before {
  content: var(--icon-menu);
}

.icon-menu_new:before {
  content: var(--icon-menu_new);
}

.icon-meter-0:before {
  content: var(--icon-meter-0);
}

.icon-meter-1:before {
  content: var(--icon-meter-1);
}

.icon-methanisation-1:before {
  content: var(--icon-methanisation-1);
}

.icon-methanisation-2:before {
  content: var(--icon-methanisation-2);
}

.icon-mobile:before {
  content: var(--icon-mobile);
}

.icon-mobile2:before {
  content: var(--icon-mobile2);
}

.icon-modbus-icon:before {
  content: var(--icon-modbus-icon);
}

.icon-mode_real:before {
  content: var(--icon-mode_real);
}

.icon-mode_simulate:before {
  content: var(--icon-mode_simulate);
}

.icon-module-thermo:before {
  content: var(--icon-module-thermo);
}

.icon-move-down:before {
  content: var(--icon-move-down);
}

.icon-move-up:before {
  content: var(--icon-move-up);
}

.icon-neb-1:before {
  content: var(--icon-neb-1);
}

.icon-nebule:before {
  content: var(--icon-nebule);
}

.icon-network-add:before {
  content: var(--icon-network-add);
}

.icon-network-lost:before {
  content: var(--icon-network-lost);
}

.icon-network-rem:before {
  content: var(--icon-network-rem);
}

.icon-network-warnig:before {
  content: var(--icon-network-warnig);
}

.icon-network:before {
  content: var(--icon-network);
}

.icon-newspaper:before {
  content: var(--icon-newspaper);
}

.icon-not-saved:before {
  content: var(--icon-not-saved);
}

.icon-objectif:before {
  content: var(--icon-objectif);
}

.icon-omron-icon:before {
  content: var(--icon-omron-icon);
}

.icon-omron-large:before {
  content: var(--icon-omron-large);
}

.icon-open-door:before {
  content: var(--icon-open-door);
}

.icon-outline-file-invoice-dollar:before {
  content: var(--icon-outline-file-invoice-dollar);
}

.icon-page-break:before {
  content: var(--icon-page-break);
}

.icon-pagebreak:before {
  content: var(--icon-pagebreak);
}

.icon-paint-format:before {
  content: var(--icon-paint-format);
}

.icon-paint-trace:before {
  content: var(--icon-paint-trace);
}

.icon-passage:before {
  content: var(--icon-passage);
}

.icon-passage_diagonale:before {
  content: var(--icon-passage_diagonale);
}

.icon-pause2:before {
  content: var(--icon-pause2);
}

.icon-phoenix-contact-full:before {
  content: var(--icon-phoenix-contact-full);
}

.icon-phoenix-contact-icon:before {
  content: var(--icon-phoenix-contact-icon);
}

.icon-phone:before {
  content: var(--icon-phone);
}

.icon-pie-chart:before {
  content: var(--icon-pie-chart);
}

.icon-play3:before {
  content: var(--icon-play3);
}

.icon-plus:before {
  content: var(--icon-plus);
}

.icon-plus_slim:before {
  content: var(--icon-plus_slim);
}

.icon-post-it:before {
  content: var(--icon-post-it);
}

.icon-poule:before {
  content: var(--icon-poule);
}

.icon-power-cord:before {
  content: var(--icon-power-cord);
}

.icon-power:before {
  content: var(--icon-power);
}

.icon-pression:before {
  content: var(--icon-pression);
}

.icon-printer-alt-outilne:before {
  content: var(--icon-printer-alt-outilne);
}

.icon-printer-outiline:before {
  content: var(--icon-printer-outiline);
}

.icon-printer:before {
  content: var(--icon-printer);
}

.icon-printer_alt:before {
  content: var(--icon-printer_alt);
}

.icon-printer_none:before {
  content: var(--icon-printer_none);
}

.icon-profibus-large:before {
  content: var(--icon-profibus-large);
}

.icon-profibus:before {
  content: var(--icon-profibus);
}

.icon-profinet-large:before {
  content: var(--icon-profinet-large);
}

.icon-profinet:before {
  content: var(--icon-profinet);
}

.icon-proximity-command-left:before {
  content: var(--icon-proximity-command-left);
}

.icon-proximity-command-right:before {
  content: var(--icon-proximity-command-right);
}

.icon-proximity-command-stop:before {
  content: var(--icon-proximity-command-stop);
}

.icon-proximity-command-up:before {
  content: var(--icon-proximity-command-up);
}

.icon-qrcode:before {
  content: var(--icon-qrcode);
}

.icon-registre:before {
  content: var(--icon-registre);
}

.icon-remove-filter:before {
  content: var(--icon-remove-filter);
}

.icon-repartiteur:before {
  content: var(--icon-repartiteur);
}

.icon-reprise-interdit:before {
  content: var(--icon-reprise-interdit);
}

.icon-reprise:before {
  content: var(--icon-reprise);
}

.icon-ri-account-circle-fill:before {
  content: var(--icon-ri-account-circle-fill);
}

.icon-ri-account-circle-line:before {
  content: var(--icon-ri-account-circle-line);
}

.icon-ri-add-fill:before {
  content: var(--icon-ri-add-fill);
}

.icon-ri-add-line:before {
  content: var(--icon-ri-add-line);
}

.icon-ri-alert-fill:before {
  content: var(--icon-ri-alert-fill);
}

.icon-ri-alert-line:before {
  content: var(--icon-ri-alert-line);
}

.icon-ri-arrow-drop-down-fill:before {
  content: var(--icon-ri-arrow-drop-down-fill);
}

.icon-ri-arrow-drop-down-line:before {
  content: var(--icon-ri-arrow-drop-down-line);
}

.icon-ri-arrow-go-back-fill:before {
  content: var(--icon-ri-arrow-go-back-fill);
}

.icon-ri-arrow-go-back-line:before {
  content: var(--icon-ri-arrow-go-back-line);
}

.icon-ri-arrow-left-right-fill:before {
  content: var(--icon-ri-arrow-left-right-fill);
}

.icon-ri-arrow-left-right-line:before {
  content: var(--icon-ri-arrow-left-right-line);
}

.icon-ri-arrow-right-fill:before {
  content: var(--icon-ri-arrow-right-fill);
}

.icon-ri-arrow-right-line:before {
  content: var(--icon-ri-arrow-right-line);
}

.icon-ri-arrow-right-s-fill:before {
  content: var(--icon-ri-arrow-right-s-fill);
}

.icon-ri-arrow-right-s-line:before {
  content: var(--icon-ri-arrow-right-s-line);
}

.icon-ri-barcode-fill:before {
  content: var(--icon-ri-barcode-fill);
}

.icon-ri-barcode-line:before {
  content: var(--icon-ri-barcode-line);
}

.icon-ri-briefcase-4-fill:before {
  content: var(--icon-ri-briefcase-4-fill);
}

.icon-ri-briefcase-4-line:before {
  content: var(--icon-ri-briefcase-4-line);
}

.icon-ri-bug-2-fill:before {
  content: var(--icon-ri-bug-2-fill);
}

.icon-ri-bug-2-line:before {
  content: var(--icon-ri-bug-2-line);
}

.icon-ri-calendar-event-fill:before {
  content: var(--icon-ri-calendar-event-fill);
}

.icon-ri-calendar-event-line:before {
  content: var(--icon-ri-calendar-event-line);
}

.icon-ri-calendar-todo-fill:before {
  content: var(--icon-ri-calendar-todo-fill);
}

.icon-ri-calendar-todo-line:before {
  content: var(--icon-ri-calendar-todo-line);
}

.icon-ri-close-fill:before {
  content: var(--icon-ri-close-fill);
}

.icon-ri-close-line:before {
  content: var(--icon-ri-close-line);
}

.icon-ri-contrast-drop-fill:before {
  content: var(--icon-ri-contrast-drop-fill);
}

.icon-ri-contrast-drop-line:before {
  content: var(--icon-ri-contrast-drop-line);
}

.icon-ri-creative-commons-nd-fill:before {
  content: var(--icon-ri-creative-commons-nd-fill);
}

.icon-ri-creative-commons-nd-line:before {
  content: var(--icon-ri-creative-commons-nd-line);
}

.icon-ri-dashboard-3-fill:before {
  content: var(--icon-ri-dashboard-3-fill);
}

.icon-ri-dashboard-3-line:before {
  content: var(--icon-ri-dashboard-3-line);
}

.icon-ri-database-2-fill:before {
  content: var(--icon-ri-database-2-fill);
}

.icon-ri-database-2-line:before {
  content: var(--icon-ri-database-2-line);
}

.icon-ri-delete-back-2-fill:before {
  content: var(--icon-ri-delete-back-2-fill);
}

.icon-ri-delete-back-2-line:before {
  content: var(--icon-ri-delete-back-2-line);
}

.icon-ri-delete-bin-5-fill:before {
  content: var(--icon-ri-delete-bin-5-fill);
}

.icon-ri-delete-bin-5-line:before {
  content: var(--icon-ri-delete-bin-5-line);
}

.icon-ri-door-open-fill:before {
  content: var(--icon-ri-door-open-fill);
}

.icon-ri-door-open-line:before {
  content: var(--icon-ri-door-open-line);
}

.icon-ri-download-2-fill:before {
  content: var(--icon-ri-download-2-fill);
}

.icon-ri-download-2-line:before {
  content: var(--icon-ri-download-2-line);
}

.icon-ri-earth-fill:before {
  content: var(--icon-ri-earth-fill);
}

.icon-ri-earth-line:before {
  content: var(--icon-ri-earth-line);
}

.icon-ri-eraser-fill:before {
  content: var(--icon-ri-eraser-fill);
}

.icon-ri-eraser-line:before {
  content: var(--icon-ri-eraser-line);
}

.icon-ri-exchange-dollar-fill:before {
  content: var(--icon-ri-exchange-dollar-fill);
}

.icon-ri-exchange-dollar-line:before {
  content: var(--icon-ri-exchange-dollar-line);
}

.icon-ri-exchange-euro-fill:before {
  content: var(--icon-ri-exchange-euro-fill);
}

.icon-ri-exchange-euro-line:before {
  content: var(--icon-ri-exchange-euro-line);
}

.icon-ri-external-link-fill:before {
  content: var(--icon-ri-external-link-fill);
}

.icon-ri-external-link-line:before {
  content: var(--icon-ri-external-link-line);
}

.icon-ri-eye-fill:before {
  content: var(--icon-ri-eye-fill);
}

.icon-ri-eye-line:before {
  content: var(--icon-ri-eye-line);
}

.icon-ri-eye-off-fill:before {
  content: var(--icon-ri-eye-off-fill);
}

.icon-ri-eye-off-line:before {
  content: var(--icon-ri-eye-off-line);
}

.icon-ri-file-3-fill:before {
  content: var(--icon-ri-file-3-fill);
}

.icon-ri-file-3-line:before {
  content: var(--icon-ri-file-3-line);
}

.icon-ri-file-bill-dollar-fill:before {
  content: var(--icon-ri-file-bill-dollar-fill);
}

.icon-ri-file-bill-dollar-line:before {
  content: var(--icon-ri-file-bill-dollar-line);
}

.icon-ri-file-bill-euro-fill:before {
  content: var(--icon-ri-file-bill-euro-fill);
}

.icon-ri-file-bill-euro-line:before {
  content: var(--icon-ri-file-bill-euro-line);
}

.icon-ri-file-code-fill:before {
  content: var(--icon-ri-file-code-fill);
}

.icon-ri-file-code-line:before {
  content: var(--icon-ri-file-code-line);
}

.icon-ri-file-edit-fill:before {
  content: var(--icon-ri-file-edit-fill);
}

.icon-ri-file-edit-line:before {
  content: var(--icon-ri-file-edit-line);
}

.icon-ri-file-settings-fill:before {
  content: var(--icon-ri-file-settings-fill);
}

.icon-ri-file-settings-line:before {
  content: var(--icon-ri-file-settings-line);
}

.icon-ri-file-text-fill:before {
  content: var(--icon-ri-file-text-fill);
}

.icon-ri-file-text-line:before {
  content: var(--icon-ri-file-text-line);
}

.icon-ri-game-fill:before {
  content: var(--icon-ri-game-fill);
}

.icon-ri-game-line:before {
  content: var(--icon-ri-game-line);
}

.icon-ri-global-fill:before {
  content: var(--icon-ri-global-fill);
}

.icon-ri-global-line:before {
  content: var(--icon-ri-global-line);
}

.icon-ri-hand-coin-fill:before {
  content: var(--icon-ri-hand-coin-fill);
}

.icon-ri-hand-coin-line:before {
  content: var(--icon-ri-hand-coin-line);
}

.icon-ri-hard-drive-2-fill:before {
  content: var(--icon-ri-hard-drive-2-fill);
}

.icon-ri-hard-drive-2-line:before {
  content: var(--icon-ri-hard-drive-2-line);
}

.icon-ri-heart-fill:before {
  content: var(--icon-ri-heart-fill);
}

.icon-ri-heart-line:before {
  content: var(--icon-ri-heart-line);
}

.icon-ri-history-fill:before {
  content: var(--icon-ri-history-fill);
}

.icon-ri-history-line:before {
  content: var(--icon-ri-history-line);
}

.icon-ri-home-2-fill:before {
  content: var(--icon-ri-home-2-fill);
}

.icon-ri-home-2-line:before {
  content: var(--icon-ri-home-2-line);
}

.icon-ri-image-fill:before {
  content: var(--icon-ri-image-fill);
}

.icon-ri-image-line:before {
  content: var(--icon-ri-image-line);
}

.icon-ri-information-fill:before {
  content: var(--icon-ri-information-fill);
}

.icon-ri-information-line:before {
  content: var(--icon-ri-information-line);
}

.icon-ri-lightbulb-flash-fill:before {
  content: var(--icon-ri-lightbulb-flash-fill);
}

.icon-ri-lightbulb-flash-line:before {
  content: var(--icon-ri-lightbulb-flash-line);
}

.icon-ri-line-chart-fill:before {
  content: var(--icon-ri-line-chart-fill);
}

.icon-ri-line-chart-line:before {
  content: var(--icon-ri-line-chart-line);
}

.icon-ri-list-check-2:before {
  content: var(--icon-ri-list-check-2);
}

.icon-ri-list-check:before {
  content: var(--icon-ri-list-check);
}

.icon-ri-list-settings-fill:before {
  content: var(--icon-ri-list-settings-fill);
}

.icon-ri-list-settings-line:before {
  content: var(--icon-ri-list-settings-line);
}

.icon-ri-loader-2-fill:before {
  content: var(--icon-ri-loader-2-fill);
}

.icon-ri-loader-2-line:before {
  content: var(--icon-ri-loader-2-line);
}

.icon-ri-lock-fill:before {
  content: var(--icon-ri-lock-fill);
}

.icon-ri-lock-line:before {
  content: var(--icon-ri-lock-line);
}

.icon-ri-lock-unlock-fill:before {
  content: var(--icon-ri-lock-unlock-fill);
}

.icon-ri-lock-unlock-line:before {
  content: var(--icon-ri-lock-unlock-line);
}

.icon-ri-map-pin-fill:before {
  content: var(--icon-ri-map-pin-fill);
}

.icon-ri-map-pin-line:before {
  content: var(--icon-ri-map-pin-line);
}

.icon-ri-money-dollar:before {
  content: var(--icon-ri-money-dollar);
}

.icon-ri-money-euro:before {
  content: var(--icon-ri-money-euro);
}

.icon-ri-more-2-fill:before {
  content: var(--icon-ri-more-2-fill);
}

.icon-ri-more-2-line:before {
  content: var(--icon-ri-more-2-line);
}

.icon-ri-more-fill:before {
  content: var(--icon-ri-more-fill);
}

.icon-ri-more-line:before {
  content: var(--icon-ri-more-line);
}

.icon-ri-notification-3-fill:before {
  content: var(--icon-ri-notification-3-fill);
}

.icon-ri-notification-3-line:before {
  content: var(--icon-ri-notification-3-line);
}

.icon-ri-palette-fill:before {
  content: var(--icon-ri-palette-fill);
}

.icon-ri-palette-line:before {
  content: var(--icon-ri-palette-line);
}

.icon-ri-pencil-fill:before {
  content: var(--icon-ri-pencil-fill);
}

.icon-ri-pencil-line:before {
  content: var(--icon-ri-pencil-line);
}

.icon-ri-pie-chart-fill:before {
  content: var(--icon-ri-pie-chart-fill);
}

.icon-ri-pie-chart-line:before {
  content: var(--icon-ri-pie-chart-line);
}

.icon-ri-pin-distance-fill:before {
  content: var(--icon-ri-pin-distance-fill);
}

.icon-ri-pin-distance-line:before {
  content: var(--icon-ri-pin-distance-line);
}

.icon-ri-price-tag-3-fill:before {
  content: var(--icon-ri-price-tag-3-fill);
}

.icon-ri-price-tag-3-line:before {
  content: var(--icon-ri-price-tag-3-line);
}

.icon-ri-printer-fill:before {
  content: var(--icon-ri-printer-fill);
}

.icon-ri-printer-line:before {
  content: var(--icon-ri-printer-line);
}

.icon-ri-qr-code-fill:before {
  content: var(--icon-ri-qr-code-fill);
}

.icon-ri-qr-code-line:before {
  content: var(--icon-ri-qr-code-line);
}

.icon-ri-rocket-2-fill:before {
  content: var(--icon-ri-rocket-2-fill);
}

.icon-ri-rocket-2-line:before {
  content: var(--icon-ri-rocket-2-line);
}

.icon-ri-save-fill:before {
  content: var(--icon-ri-save-fill);
}

.icon-ri-save-line:before {
  content: var(--icon-ri-save-line);
}

.icon-ri-scissors-cut-fill:before {
  content: var(--icon-ri-scissors-cut-fill);
}

.icon-ri-scissors-cut-line:before {
  content: var(--icon-ri-scissors-cut-line);
}

.icon-ri-sd-card-mini-fill:before {
  content: var(--icon-ri-sd-card-mini-fill);
}

.icon-ri-sd-card-mini-line:before {
  content: var(--icon-ri-sd-card-mini-line);
}

.icon-ri-server-fill:before {
  content: var(--icon-ri-server-fill);
}

.icon-ri-server-line:before {
  content: var(--icon-ri-server-line);
}

.icon-ri-settings-3-fill:before {
  content: var(--icon-ri-settings-3-fill);
}

.icon-ri-settings-3-line:before {
  content: var(--icon-ri-settings-3-line);
}

.icon-ri-shield-check-fill:before {
  content: var(--icon-ri-shield-check-fill);
}

.icon-ri-shield-check-line:before {
  content: var(--icon-ri-shield-check-line);
}

.icon-ri-shopping-bag-3-fill:before {
  content: var(--icon-ri-shopping-bag-3-fill);
}

.icon-ri-shopping-bag-3-line:before {
  content: var(--icon-ri-shopping-bag-3-line);
}

.icon-ri-shopping-cart-2-fill:before {
  content: var(--icon-ri-shopping-cart-2-fill);
}

.icon-ri-shopping-cart-2-line:before {
  content: var(--icon-ri-shopping-cart-2-line);
}

.icon-ri-stack-fill:before {
  content: var(--icon-ri-stack-fill);
}

.icon-ri-stack-line:before {
  content: var(--icon-ri-stack-line);
}

.icon-ri-star-fill:before {
  content: var(--icon-ri-star-fill);
}

.icon-ri-star-line:before {
  content: var(--icon-ri-star-line);
}

.icon-ri-stock-fill:before {
  content: var(--icon-ri-stock-fill);
}

.icon-ri-stock-line:before {
  content: var(--icon-ri-stock-line);
}

.icon-ri-sword-fill:before {
  content: var(--icon-ri-sword-fill);
}

.icon-ri-sword-line:before {
  content: var(--icon-ri-sword-line);
}

.icon-ri-t-shirt-air-fill:before {
  content: var(--icon-ri-t-shirt-air-fill);
}

.icon-ri-t-shirt-air-line:before {
  content: var(--icon-ri-t-shirt-air-line);
}

.icon-ri-terminal-box-fill:before {
  content: var(--icon-ri-terminal-box-fill);
}

.icon-ri-terminal-box-line:before {
  content: var(--icon-ri-terminal-box-line);
}

.icon-ri-terminal-window-fill:before {
  content: var(--icon-ri-terminal-window-fill);
}

.icon-ri-terminal-window-line:before {
  content: var(--icon-ri-terminal-window-line);
}

.icon-ri-thumb-up-fill:before {
  content: var(--icon-ri-thumb-up-fill);
}

.icon-ri-thumb-up-line:before {
  content: var(--icon-ri-thumb-up-line);
}

.icon-ri-time-fill:before {
  content: var(--icon-ri-time-fill);
}

.icon-ri-time-line:before {
  content: var(--icon-ri-time-line);
}

.icon-ri-timer-fill:before {
  content: var(--icon-ri-timer-fill);
}

.icon-ri-timer-line:before {
  content: var(--icon-ri-timer-line);
}

.icon-ri-tools-fill:before {
  content: var(--icon-ri-tools-fill);
}

.icon-ri-tools-line:before {
  content: var(--icon-ri-tools-line);
}

.icon-ri-truck-fill:before {
  content: var(--icon-ri-truck-fill);
}

.icon-ri-truck-line:before {
  content: var(--icon-ri-truck-line);
}

.icon-ri-upload-2-fill:before {
  content: var(--icon-ri-upload-2-fill);
}

.icon-ri-upload-2-line:before {
  content: var(--icon-ri-upload-2-line);
}

.icon-ri-user-settings-fill:before {
  content: var(--icon-ri-user-settings-fill);
}

.icon-ri-user-settings-line:before {
  content: var(--icon-ri-user-settings-line);
}

.icon-ri-vip-crown-2-fill:before {
  content: var(--icon-ri-vip-crown-2-fill);
}

.icon-ri-vip-crown-2-line:before {
  content: var(--icon-ri-vip-crown-2-line);
}

.icon-ri-volume-mute-fill:before {
  content: var(--icon-ri-volume-mute-fill);
}

.icon-ri-volume-mute-line:before {
  content: var(--icon-ri-volume-mute-line);
}

.icon-ri-volume-up-fill:before {
  content: var(--icon-ri-volume-up-fill);
}

.icon-ri-volume-up-line:before {
  content: var(--icon-ri-volume-up-line);
}

.icon-rockwell-icon:before {
  content: var(--icon-rockwell-icon);
}

.icon-rockwell-large:before {
  content: var(--icon-rockwell-large);
}

.icon-sablier:before {
  content: var(--icon-sablier);
}

.icon-saved:before {
  content: var(--icon-saved);
}

.icon-schneider:before {
  content: var(--icon-schneider);
}

.icon-search:before {
  content: var(--icon-search);
}

.icon-serie:before {
  content: var(--icon-serie);
}

.icon-siemens-icon:before {
  content: var(--icon-siemens-icon);
}

.icon-siemens:before {
  content: var(--icon-siemens);
}

.icon-silo-1:before {
  content: var(--icon-silo-1);
}

.icon-silo-2:before {
  content: var(--icon-silo-2);
}

.icon-spinner4:before {
  content: var(--icon-spinner4);
}

.icon-spoon-and-fork-crossed:before {
  content: var(--icon-spoon-and-fork-crossed);
}

.icon-stats-bars:before {
  content: var(--icon-stats-bars);
}

.icon-stats-bars2:before {
  content: var(--icon-stats-bars2);
}

.icon-stats-dots:before {
  content: var(--icon-stats-dots);
}

.icon-stop:before {
  content: var(--icon-stop);
}

.icon-stopwatch:before {
  content: var(--icon-stopwatch);
}

.icon-svg:before {
  content: var(--icon-svg);
}

.icon-switch-actived-perced-full:before {
  content: var(--icon-switch-actived-perced-full);
}

.icon-switch-actived-perced:before {
  content: var(--icon-switch-actived-perced);
}

.icon-switch-actived:before {
  content: var(--icon-switch-actived);
}

.icon-switch-unactived-perced-full:before {
  content: var(--icon-switch-unactived-perced-full);
}

.icon-switch-unactived-perced:before {
  content: var(--icon-switch-unactived-perced);
}

.icon-switch-unactived:before {
  content: var(--icon-switch-unactived);
}

.icon-switch:before {
  content: var(--icon-switch);
}

.icon-table:before {
  content: var(--icon-table);
}

.icon-table2:before {
  content: var(--icon-table2);
}

.icon-tablet:before {
  content: var(--icon-tablet);
}

.icon-tapis-2:before {
  content: var(--icon-tapis-2);
}

.icon-tapis-parametrage-2:before {
  content: var(--icon-tapis-parametrage-2);
}

.icon-tapis-parametrage:before {
  content: var(--icon-tapis-parametrage);
}

.icon-tapis:before {
  content: var(--icon-tapis);
}

.icon-target:before {
  content: var(--icon-target);
}

.icon-teamviewer:before {
  content: var(--icon-teamviewer);
}

.icon-temp-ext:before {
  content: var(--icon-temp-ext);
}

.icon-temp-int:before {
  content: var(--icon-temp-int);
}

.icon-terminal:before {
  content: var(--icon-terminal);
}

.icon-text-color:before {
  content: var(--icon-text-color);
}

.icon-thermometer:before {
  content: var(--icon-thermometer);
}

.icon-tools:before {
  content: var(--icon-tools);
}

.icon-transaction:before {
  content: var(--icon-transaction);
}

.icon-transporteur-1:before {
  content: var(--icon-transporteur-1);
}

.icon-transporteur-1s-1:before {
  content: var(--icon-transporteur-1s-1);
}

.icon-transporteur-2s-1:before {
  content: var(--icon-transporteur-2s-1);
}

.icon-trappe-1:before {
  content: var(--icon-trappe-1);
}

.icon-trappe-2:before {
  content: var(--icon-trappe-2);
}

.icon-trappe-3:before {
  content: var(--icon-trappe-3);
}

.icon-trappe-4:before {
  content: var(--icon-trappe-4);
}

.icon-trappe-5:before {
  content: var(--icon-trappe-5);
}

.icon-trappe-6:before {
  content: var(--icon-trappe-6);
}

.icon-trappe-7:before {
  content: var(--icon-trappe-7);
}

.icon-tree:before {
  content: var(--icon-tree);
}

.icon-tremie:before {
  content: var(--icon-tremie);
}

.icon-uncheckall:before {
  content: var(--icon-uncheckall);
}

.icon-unlocked:before {
  content: var(--icon-unlocked);
}

.icon-usb-cable:before {
  content: var(--icon-usb-cable);
}

.icon-usb-icon:before {
  content: var(--icon-usb-icon);
}

.icon-valider:before {
  content: var(--icon-valider);
}

.icon-vipa-full:before {
  content: var(--icon-vipa-full);
}

.icon-vipa-icon:before {
  content: var(--icon-vipa-icon);
}

.icon-vis:before {
  content: var(--icon-vis);
}

.icon-wago-full:before {
  content: var(--icon-wago-full);
}

.icon-wago-icon-circle:before {
  content: var(--icon-wago-icon-circle);
}

.icon-wago-icon:before {
  content: var(--icon-wago-icon);
}

.icon-warning:before {
  content: var(--icon-warning);
}

.icon-water61:before {
  content: var(--icon-water61);
}

.icon-weidmuller-full:before {
  content: var(--icon-weidmuller-full);
}

.icon-weidmuller-icon:before {
  content: var(--icon-weidmuller-icon);
}

.icon-wikipedia-logo:before {
  content: var(--icon-wikipedia-logo);
}

.icon-y-command-down:before {
  content: var(--icon-y-command-down);
}

.icon-brands-500px:before {
  content: var(--icon-brands-500px);
}

.icon-brands-accessible-icon:before {
  content: var(--icon-brands-accessible-icon);
}

.icon-brands-accusoft:before {
  content: var(--icon-brands-accusoft);
}

.icon-brands-acquisitions-incorporated:before {
  content: var(--icon-brands-acquisitions-incorporated);
}

.icon-brands-adn:before {
  content: var(--icon-brands-adn);
}

.icon-brands-adobe:before {
  content: var(--icon-brands-adobe);
}

.icon-brands-adversal:before {
  content: var(--icon-brands-adversal);
}

.icon-brands-affiliatetheme:before {
  content: var(--icon-brands-affiliatetheme);
}

.icon-brands-algolia:before {
  content: var(--icon-brands-algolia);
}

.icon-brands-alipay:before {
  content: var(--icon-brands-alipay);
}

.icon-brands-amazon-pay:before {
  content: var(--icon-brands-amazon-pay);
}

.icon-brands-amazon:before {
  content: var(--icon-brands-amazon);
}

.icon-brands-amilia:before {
  content: var(--icon-brands-amilia);
}

.icon-brands-android:before {
  content: var(--icon-brands-android);
}

.icon-brands-angellist:before {
  content: var(--icon-brands-angellist);
}

.icon-brands-angrycreative:before {
  content: var(--icon-brands-angrycreative);
}

.icon-brands-angular:before {
  content: var(--icon-brands-angular);
}

.icon-brands-app-store-ios:before {
  content: var(--icon-brands-app-store-ios);
}

.icon-brands-app-store:before {
  content: var(--icon-brands-app-store);
}

.icon-brands-apper:before {
  content: var(--icon-brands-apper);
}

.icon-brands-apple-pay:before {
  content: var(--icon-brands-apple-pay);
}

.icon-brands-apple:before {
  content: var(--icon-brands-apple);
}

.icon-brands-artstation:before {
  content: var(--icon-brands-artstation);
}

.icon-brands-asymmetrik:before {
  content: var(--icon-brands-asymmetrik);
}

.icon-brands-atlassian:before {
  content: var(--icon-brands-atlassian);
}

.icon-brands-audible:before {
  content: var(--icon-brands-audible);
}

.icon-brands-autoprefixer:before {
  content: var(--icon-brands-autoprefixer);
}

.icon-brands-avianex:before {
  content: var(--icon-brands-avianex);
}

.icon-brands-aviato:before {
  content: var(--icon-brands-aviato);
}

.icon-brands-aws:before {
  content: var(--icon-brands-aws);
}

.icon-brands-bandcamp:before {
  content: var(--icon-brands-bandcamp);
}

.icon-brands-behance-square:before {
  content: var(--icon-brands-behance-square);
}

.icon-brands-behance:before {
  content: var(--icon-brands-behance);
}

.icon-brands-bimobject:before {
  content: var(--icon-brands-bimobject);
}

.icon-brands-bitbucket:before {
  content: var(--icon-brands-bitbucket);
}

.icon-brands-bitcoin:before {
  content: var(--icon-brands-bitcoin);
}

.icon-brands-bity:before {
  content: var(--icon-brands-bity);
}

.icon-brands-black-tie:before {
  content: var(--icon-brands-black-tie);
}

.icon-brands-blackberry:before {
  content: var(--icon-brands-blackberry);
}

.icon-brands-blogger-b:before {
  content: var(--icon-brands-blogger-b);
}

.icon-brands-blogger:before {
  content: var(--icon-brands-blogger);
}

.icon-brands-bluetooth-b:before {
  content: var(--icon-brands-bluetooth-b);
}

.icon-brands-bluetooth:before {
  content: var(--icon-brands-bluetooth);
}

.icon-brands-btc:before {
  content: var(--icon-brands-btc);
}

.icon-brands-buromobelexperte:before {
  content: var(--icon-brands-buromobelexperte);
}

.icon-brands-buysellads:before {
  content: var(--icon-brands-buysellads);
}

.icon-brands-canadian-maple-leaf:before {
  content: var(--icon-brands-canadian-maple-leaf);
}

.icon-brands-cc-amazon-pay:before {
  content: var(--icon-brands-cc-amazon-pay);
}

.icon-brands-cc-amex:before {
  content: var(--icon-brands-cc-amex);
}

.icon-brands-cc-apple-pay:before {
  content: var(--icon-brands-cc-apple-pay);
}

.icon-brands-cc-diners-club:before {
  content: var(--icon-brands-cc-diners-club);
}

.icon-brands-cc-discover:before {
  content: var(--icon-brands-cc-discover);
}

.icon-brands-cc-jcb:before {
  content: var(--icon-brands-cc-jcb);
}

.icon-brands-cc-mastercard:before {
  content: var(--icon-brands-cc-mastercard);
}

.icon-brands-cc-paypal:before {
  content: var(--icon-brands-cc-paypal);
}

.icon-brands-cc-stripe:before {
  content: var(--icon-brands-cc-stripe);
}

.icon-brands-cc-visa:before {
  content: var(--icon-brands-cc-visa);
}

.icon-brands-centercode:before {
  content: var(--icon-brands-centercode);
}

.icon-brands-centos:before {
  content: var(--icon-brands-centos);
}

.icon-brands-chrome:before {
  content: var(--icon-brands-chrome);
}

.icon-brands-cloudscale:before {
  content: var(--icon-brands-cloudscale);
}

.icon-brands-cloudsmith:before {
  content: var(--icon-brands-cloudsmith);
}

.icon-brands-cloudversify:before {
  content: var(--icon-brands-cloudversify);
}

.icon-brands-codepen:before {
  content: var(--icon-brands-codepen);
}

.icon-brands-codiepie:before {
  content: var(--icon-brands-codiepie);
}

.icon-brands-confluence:before {
  content: var(--icon-brands-confluence);
}

.icon-brands-connectdevelop:before {
  content: var(--icon-brands-connectdevelop);
}

.icon-brands-contao:before {
  content: var(--icon-brands-contao);
}

.icon-brands-cpanel:before {
  content: var(--icon-brands-cpanel);
}

.icon-brands-creative-commons-by:before {
  content: var(--icon-brands-creative-commons-by);
}

.icon-brands-creative-commons-nc-eu:before {
  content: var(--icon-brands-creative-commons-nc-eu);
}

.icon-brands-creative-commons-nc-jp:before {
  content: var(--icon-brands-creative-commons-nc-jp);
}

.icon-brands-creative-commons-nc:before {
  content: var(--icon-brands-creative-commons-nc);
}

.icon-brands-creative-commons-nd:before {
  content: var(--icon-brands-creative-commons-nd);
}

.icon-brands-creative-commons-pd-alt:before {
  content: var(--icon-brands-creative-commons-pd-alt);
}

.icon-brands-creative-commons-pd:before {
  content: var(--icon-brands-creative-commons-pd);
}

.icon-brands-creative-commons-remix:before {
  content: var(--icon-brands-creative-commons-remix);
}

.icon-brands-creative-commons-sa:before {
  content: var(--icon-brands-creative-commons-sa);
}

.icon-brands-creative-commons-sampling-plus:before {
  content: var(--icon-brands-creative-commons-sampling-plus);
}

.icon-brands-creative-commons-sampling:before {
  content: var(--icon-brands-creative-commons-sampling);
}

.icon-brands-creative-commons-share:before {
  content: var(--icon-brands-creative-commons-share);
}

.icon-brands-creative-commons-zero:before {
  content: var(--icon-brands-creative-commons-zero);
}

.icon-brands-creative-commons:before {
  content: var(--icon-brands-creative-commons);
}

.icon-brands-critical-role:before {
  content: var(--icon-brands-critical-role);
}

.icon-brands-css3-alt:before {
  content: var(--icon-brands-css3-alt);
}

.icon-brands-css3:before {
  content: var(--icon-brands-css3);
}

.icon-brands-cuttlefish:before {
  content: var(--icon-brands-cuttlefish);
}

.icon-brands-d-and-d-beyond:before {
  content: var(--icon-brands-d-and-d-beyond);
}

.icon-brands-d-and-d:before {
  content: var(--icon-brands-d-and-d);
}

.icon-brands-dashcube:before {
  content: var(--icon-brands-dashcube);
}

.icon-brands-delicious:before {
  content: var(--icon-brands-delicious);
}

.icon-brands-deploydog:before {
  content: var(--icon-brands-deploydog);
}

.icon-brands-deskpro:before {
  content: var(--icon-brands-deskpro);
}

.icon-brands-dev:before {
  content: var(--icon-brands-dev);
}

.icon-brands-deviantart:before {
  content: var(--icon-brands-deviantart);
}

.icon-brands-dhl:before {
  content: var(--icon-brands-dhl);
}

.icon-brands-diaspora:before {
  content: var(--icon-brands-diaspora);
}

.icon-brands-digg:before {
  content: var(--icon-brands-digg);
}

.icon-brands-digital-ocean:before {
  content: var(--icon-brands-digital-ocean);
}

.icon-brands-discord:before {
  content: var(--icon-brands-discord);
}

.icon-brands-discourse:before {
  content: var(--icon-brands-discourse);
}

.icon-brands-dochub:before {
  content: var(--icon-brands-dochub);
}

.icon-brands-docker:before {
  content: var(--icon-brands-docker);
}

.icon-brands-draft2digital:before {
  content: var(--icon-brands-draft2digital);
}

.icon-brands-dribbble-square:before {
  content: var(--icon-brands-dribbble-square);
}

.icon-brands-dribbble:before {
  content: var(--icon-brands-dribbble);
}

.icon-brands-dropbox:before {
  content: var(--icon-brands-dropbox);
}

.icon-brands-drupal:before {
  content: var(--icon-brands-drupal);
}

.icon-brands-dyalog:before {
  content: var(--icon-brands-dyalog);
}

.icon-brands-earlybirds:before {
  content: var(--icon-brands-earlybirds);
}

.icon-brands-ebay:before {
  content: var(--icon-brands-ebay);
}

.icon-brands-edge:before {
  content: var(--icon-brands-edge);
}

.icon-brands-elementor:before {
  content: var(--icon-brands-elementor);
}

.icon-brands-ello:before {
  content: var(--icon-brands-ello);
}

.icon-brands-ember:before {
  content: var(--icon-brands-ember);
}

.icon-brands-empire:before {
  content: var(--icon-brands-empire);
}

.icon-brands-envira:before {
  content: var(--icon-brands-envira);
}

.icon-brands-erlang:before {
  content: var(--icon-brands-erlang);
}

.icon-brands-ethereum:before {
  content: var(--icon-brands-ethereum);
}

.icon-brands-etsy:before {
  content: var(--icon-brands-etsy);
}

.icon-brands-expeditedssl:before {
  content: var(--icon-brands-expeditedssl);
}

.icon-brands-facebook-f:before {
  content: var(--icon-brands-facebook-f);
}

.icon-brands-facebook-messenger:before {
  content: var(--icon-brands-facebook-messenger);
}

.icon-brands-facebook-square:before {
  content: var(--icon-brands-facebook-square);
}

.icon-brands-facebook:before {
  content: var(--icon-brands-facebook);
}

.icon-brands-fantasy-flight-games:before {
  content: var(--icon-brands-fantasy-flight-games);
}

.icon-brands-fedex:before {
  content: var(--icon-brands-fedex);
}

.icon-brands-fedora:before {
  content: var(--icon-brands-fedora);
}

.icon-brands-figma:before {
  content: var(--icon-brands-figma);
}

.icon-brands-firefox:before {
  content: var(--icon-brands-firefox);
}

.icon-brands-first-order-alt:before {
  content: var(--icon-brands-first-order-alt);
}

.icon-brands-first-order:before {
  content: var(--icon-brands-first-order);
}

.icon-brands-firstdraft:before {
  content: var(--icon-brands-firstdraft);
}

.icon-brands-flickr:before {
  content: var(--icon-brands-flickr);
}

.icon-brands-flipboard:before {
  content: var(--icon-brands-flipboard);
}

.icon-brands-fly:before {
  content: var(--icon-brands-fly);
}

.icon-brands-font-awesome-alt:before {
  content: var(--icon-brands-font-awesome-alt);
}

.icon-brands-font-awesome-flag:before {
  content: var(--icon-brands-font-awesome-flag);
}

.icon-brands-font-awesome-logo-full:before {
  content: var(--icon-brands-font-awesome-logo-full);
}

.icon-brands-font-awesome:before {
  content: var(--icon-brands-font-awesome);
}

.icon-brands-fonticons-fi:before {
  content: var(--icon-brands-fonticons-fi);
}

.icon-brands-fonticons:before {
  content: var(--icon-brands-fonticons);
}

.icon-brands-fort-awesome-alt:before {
  content: var(--icon-brands-fort-awesome-alt);
}

.icon-brands-fort-awesome:before {
  content: var(--icon-brands-fort-awesome);
}

.icon-brands-forumbee:before {
  content: var(--icon-brands-forumbee);
}

.icon-brands-foursquare:before {
  content: var(--icon-brands-foursquare);
}

.icon-brands-free-code-camp:before {
  content: var(--icon-brands-free-code-camp);
}

.icon-brands-freebsd:before {
  content: var(--icon-brands-freebsd);
}

.icon-brands-fulcrum:before {
  content: var(--icon-brands-fulcrum);
}

.icon-brands-galactic-republic:before {
  content: var(--icon-brands-galactic-republic);
}

.icon-brands-galactic-senate:before {
  content: var(--icon-brands-galactic-senate);
}

.icon-brands-get-pocket:before {
  content: var(--icon-brands-get-pocket);
}

.icon-brands-gg-circle:before {
  content: var(--icon-brands-gg-circle);
}

.icon-brands-gg:before {
  content: var(--icon-brands-gg);
}

.icon-brands-git-square:before {
  content: var(--icon-brands-git-square);
}

.icon-brands-git:before {
  content: var(--icon-brands-git);
}

.icon-brands-github-alt:before {
  content: var(--icon-brands-github-alt);
}

.icon-brands-github-square:before {
  content: var(--icon-brands-github-square);
}

.icon-brands-github:before {
  content: var(--icon-brands-github);
}

.icon-brands-gitkraken:before {
  content: var(--icon-brands-gitkraken);
}

.icon-brands-gitlab:before {
  content: var(--icon-brands-gitlab);
}

.icon-brands-gitter:before {
  content: var(--icon-brands-gitter);
}

.icon-brands-glide-g:before {
  content: var(--icon-brands-glide-g);
}

.icon-brands-glide:before {
  content: var(--icon-brands-glide);
}

.icon-brands-gofore:before {
  content: var(--icon-brands-gofore);
}

.icon-brands-goodreads-g:before {
  content: var(--icon-brands-goodreads-g);
}

.icon-brands-goodreads:before {
  content: var(--icon-brands-goodreads);
}

.icon-brands-google-drive:before {
  content: var(--icon-brands-google-drive);
}

.icon-brands-google-play:before {
  content: var(--icon-brands-google-play);
}

.icon-brands-google-plus-g:before {
  content: var(--icon-brands-google-plus-g);
}

.icon-brands-google-plus-square:before {
  content: var(--icon-brands-google-plus-square);
}

.icon-brands-google-plus:before {
  content: var(--icon-brands-google-plus);
}

.icon-brands-google-wallet:before {
  content: var(--icon-brands-google-wallet);
}

.icon-brands-google:before {
  content: var(--icon-brands-google);
}

.icon-brands-gratipay:before {
  content: var(--icon-brands-gratipay);
}

.icon-brands-grav:before {
  content: var(--icon-brands-grav);
}

.icon-brands-gripfire:before {
  content: var(--icon-brands-gripfire);
}

.icon-brands-grunt:before {
  content: var(--icon-brands-grunt);
}

.icon-brands-gulp:before {
  content: var(--icon-brands-gulp);
}

.icon-brands-hacker-news-square:before {
  content: var(--icon-brands-hacker-news-square);
}

.icon-brands-hacker-news:before {
  content: var(--icon-brands-hacker-news);
}

.icon-brands-hackerrank:before {
  content: var(--icon-brands-hackerrank);
}

.icon-brands-hips:before {
  content: var(--icon-brands-hips);
}

.icon-brands-hire-a-helper:before {
  content: var(--icon-brands-hire-a-helper);
}

.icon-brands-hooli:before {
  content: var(--icon-brands-hooli);
}

.icon-brands-hornbill:before {
  content: var(--icon-brands-hornbill);
}

.icon-brands-hotjar:before {
  content: var(--icon-brands-hotjar);
}

.icon-brands-houzz:before {
  content: var(--icon-brands-houzz);
}

.icon-brands-html5:before {
  content: var(--icon-brands-html5);
}

.icon-brands-hubspot:before {
  content: var(--icon-brands-hubspot);
}

.icon-brands-imdb:before {
  content: var(--icon-brands-imdb);
}

.icon-brands-instagram:before {
  content: var(--icon-brands-instagram);
}

.icon-brands-intercom:before {
  content: var(--icon-brands-intercom);
}

.icon-brands-internet-explorer:before {
  content: var(--icon-brands-internet-explorer);
}

.icon-brands-invision:before {
  content: var(--icon-brands-invision);
}

.icon-brands-ioxhost:before {
  content: var(--icon-brands-ioxhost);
}

.icon-brands-itunes-note:before {
  content: var(--icon-brands-itunes-note);
}

.icon-brands-itunes:before {
  content: var(--icon-brands-itunes);
}

.icon-brands-java:before {
  content: var(--icon-brands-java);
}

.icon-brands-jedi-order:before {
  content: var(--icon-brands-jedi-order);
}

.icon-brands-jenkins:before {
  content: var(--icon-brands-jenkins);
}

.icon-brands-jira:before {
  content: var(--icon-brands-jira);
}

.icon-brands-joget:before {
  content: var(--icon-brands-joget);
}

.icon-brands-joomla:before {
  content: var(--icon-brands-joomla);
}

.icon-brands-js-square:before {
  content: var(--icon-brands-js-square);
}

.icon-brands-js:before {
  content: var(--icon-brands-js);
}

.icon-brands-jsfiddle:before {
  content: var(--icon-brands-jsfiddle);
}

.icon-brands-kaggle:before {
  content: var(--icon-brands-kaggle);
}

.icon-brands-keybase:before {
  content: var(--icon-brands-keybase);
}

.icon-brands-keycdn:before {
  content: var(--icon-brands-keycdn);
}

.icon-brands-kickstarter-k:before {
  content: var(--icon-brands-kickstarter-k);
}

.icon-brands-kickstarter:before {
  content: var(--icon-brands-kickstarter);
}

.icon-brands-korvue:before {
  content: var(--icon-brands-korvue);
}

.icon-brands-laravel:before {
  content: var(--icon-brands-laravel);
}

.icon-brands-lastfm-square:before {
  content: var(--icon-brands-lastfm-square);
}

.icon-brands-lastfm:before {
  content: var(--icon-brands-lastfm);
}

.icon-brands-leanpub:before {
  content: var(--icon-brands-leanpub);
}

.icon-brands-less:before {
  content: var(--icon-brands-less);
}

.icon-brands-line:before {
  content: var(--icon-brands-line);
}

.icon-brands-linkedin-in:before {
  content: var(--icon-brands-linkedin-in);
}

.icon-brands-linkedin:before {
  content: var(--icon-brands-linkedin);
}

.icon-brands-linode:before {
  content: var(--icon-brands-linode);
}

.icon-brands-linux:before {
  content: var(--icon-brands-linux);
}

.icon-brands-lyft:before {
  content: var(--icon-brands-lyft);
}

.icon-brands-magento:before {
  content: var(--icon-brands-magento);
}

.icon-brands-mailchimp:before {
  content: var(--icon-brands-mailchimp);
}

.icon-brands-mandalorian:before {
  content: var(--icon-brands-mandalorian);
}

.icon-brands-markdown:before {
  content: var(--icon-brands-markdown);
}

.icon-brands-mastodon:before {
  content: var(--icon-brands-mastodon);
}

.icon-brands-maxcdn:before {
  content: var(--icon-brands-maxcdn);
}

.icon-brands-medapps:before {
  content: var(--icon-brands-medapps);
}

.icon-brands-medium-m:before {
  content: var(--icon-brands-medium-m);
}

.icon-brands-medium:before {
  content: var(--icon-brands-medium);
}

.icon-brands-medrt:before {
  content: var(--icon-brands-medrt);
}

.icon-brands-meetup:before {
  content: var(--icon-brands-meetup);
}

.icon-brands-megaport:before {
  content: var(--icon-brands-megaport);
}

.icon-brands-mendeley:before {
  content: var(--icon-brands-mendeley);
}

.icon-brands-microsoft:before {
  content: var(--icon-brands-microsoft);
}

.icon-brands-mix:before {
  content: var(--icon-brands-mix);
}

.icon-brands-mixcloud:before {
  content: var(--icon-brands-mixcloud);
}

.icon-brands-mizuni:before {
  content: var(--icon-brands-mizuni);
}

.icon-brands-modx:before {
  content: var(--icon-brands-modx);
}

.icon-brands-monero:before {
  content: var(--icon-brands-monero);
}

.icon-brands-napster:before {
  content: var(--icon-brands-napster);
}

.icon-brands-neos:before {
  content: var(--icon-brands-neos);
}

.icon-brands-nimblr:before {
  content: var(--icon-brands-nimblr);
}

.icon-brands-nintendo-switch:before {
  content: var(--icon-brands-nintendo-switch);
}

.icon-brands-node-js:before {
  content: var(--icon-brands-node-js);
}

.icon-brands-node:before {
  content: var(--icon-brands-node);
}

.icon-brands-npm:before {
  content: var(--icon-brands-npm);
}

.icon-brands-ns8:before {
  content: var(--icon-brands-ns8);
}

.icon-brands-nutritionix:before {
  content: var(--icon-brands-nutritionix);
}

.icon-brands-odnoklassniki-square:before {
  content: var(--icon-brands-odnoklassniki-square);
}

.icon-brands-odnoklassniki:before {
  content: var(--icon-brands-odnoklassniki);
}

.icon-brands-old-republic:before {
  content: var(--icon-brands-old-republic);
}

.icon-brands-opencart:before {
  content: var(--icon-brands-opencart);
}

.icon-brands-openid:before {
  content: var(--icon-brands-openid);
}

.icon-brands-opera:before {
  content: var(--icon-brands-opera);
}

.icon-brands-optin-monster:before {
  content: var(--icon-brands-optin-monster);
}

.icon-brands-osi:before {
  content: var(--icon-brands-osi);
}

.icon-brands-page4:before {
  content: var(--icon-brands-page4);
}

.icon-brands-pagelines:before {
  content: var(--icon-brands-pagelines);
}

.icon-brands-palfed:before {
  content: var(--icon-brands-palfed);
}

.icon-brands-patreon:before {
  content: var(--icon-brands-patreon);
}

.icon-brands-paypal:before {
  content: var(--icon-brands-paypal);
}

.icon-brands-penny-arcade:before {
  content: var(--icon-brands-penny-arcade);
}

.icon-brands-periscope:before {
  content: var(--icon-brands-periscope);
}

.icon-brands-phabricator:before {
  content: var(--icon-brands-phabricator);
}

.icon-brands-phoenix-framework:before {
  content: var(--icon-brands-phoenix-framework);
}

.icon-brands-phoenix-squadron:before {
  content: var(--icon-brands-phoenix-squadron);
}

.icon-brands-php:before {
  content: var(--icon-brands-php);
}

.icon-brands-pied-piper-alt:before {
  content: var(--icon-brands-pied-piper-alt);
}

.icon-brands-pied-piper-hat:before {
  content: var(--icon-brands-pied-piper-hat);
}

.icon-brands-pied-piper-pp:before {
  content: var(--icon-brands-pied-piper-pp);
}

.icon-brands-pied-piper:before {
  content: var(--icon-brands-pied-piper);
}

.icon-brands-pinterest-p:before {
  content: var(--icon-brands-pinterest-p);
}

.icon-brands-pinterest-square:before {
  content: var(--icon-brands-pinterest-square);
}

.icon-brands-pinterest:before {
  content: var(--icon-brands-pinterest);
}

.icon-brands-playstation:before {
  content: var(--icon-brands-playstation);
}

.icon-brands-product-hunt:before {
  content: var(--icon-brands-product-hunt);
}

.icon-brands-pushed:before {
  content: var(--icon-brands-pushed);
}

.icon-brands-python:before {
  content: var(--icon-brands-python);
}

.icon-brands-qq:before {
  content: var(--icon-brands-qq);
}

.icon-brands-quinscape:before {
  content: var(--icon-brands-quinscape);
}

.icon-brands-quora:before {
  content: var(--icon-brands-quora);
}

.icon-brands-r-project:before {
  content: var(--icon-brands-r-project);
}

.icon-brands-raspberry-pi:before {
  content: var(--icon-brands-raspberry-pi);
}

.icon-brands-ravelry:before {
  content: var(--icon-brands-ravelry);
}

.icon-brands-react:before {
  content: var(--icon-brands-react);
}

.icon-brands-reacteurope:before {
  content: var(--icon-brands-reacteurope);
}

.icon-brands-readme:before {
  content: var(--icon-brands-readme);
}

.icon-brands-rebel:before {
  content: var(--icon-brands-rebel);
}

.icon-brands-red-river:before {
  content: var(--icon-brands-red-river);
}

.icon-brands-reddit-alien:before {
  content: var(--icon-brands-reddit-alien);
}

.icon-brands-reddit-square:before {
  content: var(--icon-brands-reddit-square);
}

.icon-brands-reddit:before {
  content: var(--icon-brands-reddit);
}

.icon-brands-redhat:before {
  content: var(--icon-brands-redhat);
}

.icon-brands-renren:before {
  content: var(--icon-brands-renren);
}

.icon-brands-replyd:before {
  content: var(--icon-brands-replyd);
}

.icon-brands-researchgate:before {
  content: var(--icon-brands-researchgate);
}

.icon-brands-resolving:before {
  content: var(--icon-brands-resolving);
}

.icon-brands-rev:before {
  content: var(--icon-brands-rev);
}

.icon-brands-rocketchat:before {
  content: var(--icon-brands-rocketchat);
}

.icon-brands-rockrms:before {
  content: var(--icon-brands-rockrms);
}

.icon-brands-safari:before {
  content: var(--icon-brands-safari);
}

.icon-brands-sass:before {
  content: var(--icon-brands-sass);
}

.icon-brands-schlix:before {
  content: var(--icon-brands-schlix);
}

.icon-brands-scribd:before {
  content: var(--icon-brands-scribd);
}

.icon-brands-searchengin:before {
  content: var(--icon-brands-searchengin);
}

.icon-brands-sellcast:before {
  content: var(--icon-brands-sellcast);
}

.icon-brands-sellsy:before {
  content: var(--icon-brands-sellsy);
}

.icon-brands-servicestack:before {
  content: var(--icon-brands-servicestack);
}

.icon-brands-shirtsinbulk:before {
  content: var(--icon-brands-shirtsinbulk);
}

.icon-brands-shopware:before {
  content: var(--icon-brands-shopware);
}

.icon-brands-simplybuilt:before {
  content: var(--icon-brands-simplybuilt);
}

.icon-brands-sistrix:before {
  content: var(--icon-brands-sistrix);
}

.icon-brands-sith:before {
  content: var(--icon-brands-sith);
}

.icon-brands-sketch:before {
  content: var(--icon-brands-sketch);
}

.icon-brands-skyatlas:before {
  content: var(--icon-brands-skyatlas);
}

.icon-brands-skype:before {
  content: var(--icon-brands-skype);
}

.icon-brands-slack-hash:before {
  content: var(--icon-brands-slack-hash);
}

.icon-brands-slack:before {
  content: var(--icon-brands-slack);
}

.icon-brands-slideshare:before {
  content: var(--icon-brands-slideshare);
}

.icon-brands-snapchat-ghost:before {
  content: var(--icon-brands-snapchat-ghost);
}

.icon-brands-snapchat-square:before {
  content: var(--icon-brands-snapchat-square);
}

.icon-brands-snapchat:before {
  content: var(--icon-brands-snapchat);
}

.icon-brands-soundcloud:before {
  content: var(--icon-brands-soundcloud);
}

.icon-brands-sourcetree:before {
  content: var(--icon-brands-sourcetree);
}

.icon-brands-speakap:before {
  content: var(--icon-brands-speakap);
}

.icon-brands-spotify:before {
  content: var(--icon-brands-spotify);
}

.icon-brands-squarespace:before {
  content: var(--icon-brands-squarespace);
}

.icon-brands-stack-exchange:before {
  content: var(--icon-brands-stack-exchange);
}

.icon-brands-stack-overflow:before {
  content: var(--icon-brands-stack-overflow);
}

.icon-brands-staylinked:before {
  content: var(--icon-brands-staylinked);
}

.icon-brands-steam-square:before {
  content: var(--icon-brands-steam-square);
}

.icon-brands-steam-symbol:before {
  content: var(--icon-brands-steam-symbol);
}

.icon-brands-steam:before {
  content: var(--icon-brands-steam);
}

.icon-brands-sticker-mule:before {
  content: var(--icon-brands-sticker-mule);
}

.icon-brands-strava:before {
  content: var(--icon-brands-strava);
}

.icon-brands-stripe-s:before {
  content: var(--icon-brands-stripe-s);
}

.icon-brands-stripe:before {
  content: var(--icon-brands-stripe);
}

.icon-brands-studiovinari:before {
  content: var(--icon-brands-studiovinari);
}

.icon-brands-stumbleupon-circle:before {
  content: var(--icon-brands-stumbleupon-circle);
}

.icon-brands-stumbleupon:before {
  content: var(--icon-brands-stumbleupon);
}

.icon-brands-superpowers:before {
  content: var(--icon-brands-superpowers);
}

.icon-brands-supple:before {
  content: var(--icon-brands-supple);
}

.icon-brands-suse:before {
  content: var(--icon-brands-suse);
}

.icon-brands-teamspeak:before {
  content: var(--icon-brands-teamspeak);
}

.icon-brands-telegram-plane:before {
  content: var(--icon-brands-telegram-plane);
}

.icon-brands-telegram:before {
  content: var(--icon-brands-telegram);
}

.icon-brands-tencent-weibo:before {
  content: var(--icon-brands-tencent-weibo);
}

.icon-brands-the-red-yeti:before {
  content: var(--icon-brands-the-red-yeti);
}

.icon-brands-themeco:before {
  content: var(--icon-brands-themeco);
}

.icon-brands-themeisle:before {
  content: var(--icon-brands-themeisle);
}

.icon-brands-think-peaks:before {
  content: var(--icon-brands-think-peaks);
}

.icon-brands-trade-federation:before {
  content: var(--icon-brands-trade-federation);
}

.icon-brands-trello:before {
  content: var(--icon-brands-trello);
}

.icon-brands-tripadvisor:before {
  content: var(--icon-brands-tripadvisor);
}

.icon-brands-tumblr-square:before {
  content: var(--icon-brands-tumblr-square);
}

.icon-brands-tumblr:before {
  content: var(--icon-brands-tumblr);
}

.icon-brands-twitch:before {
  content: var(--icon-brands-twitch);
}

.icon-brands-twitter-square:before {
  content: var(--icon-brands-twitter-square);
}

.icon-brands-twitter:before {
  content: var(--icon-brands-twitter);
}

.icon-brands-typo3:before {
  content: var(--icon-brands-typo3);
}

.icon-brands-uber:before {
  content: var(--icon-brands-uber);
}

.icon-brands-ubuntu:before {
  content: var(--icon-brands-ubuntu);
}

.icon-brands-uikit:before {
  content: var(--icon-brands-uikit);
}

.icon-brands-uniregistry:before {
  content: var(--icon-brands-uniregistry);
}

.icon-brands-untappd:before {
  content: var(--icon-brands-untappd);
}

.icon-brands-ups:before {
  content: var(--icon-brands-ups);
}

.icon-brands-usb:before {
  content: var(--icon-brands-usb);
}

.icon-brands-usps:before {
  content: var(--icon-brands-usps);
}

.icon-brands-ussunnah:before {
  content: var(--icon-brands-ussunnah);
}

.icon-brands-vaadin:before {
  content: var(--icon-brands-vaadin);
}

.icon-brands-viacoin:before {
  content: var(--icon-brands-viacoin);
}

.icon-brands-viadeo-square:before {
  content: var(--icon-brands-viadeo-square);
}

.icon-brands-viadeo:before {
  content: var(--icon-brands-viadeo);
}

.icon-brands-viber:before {
  content: var(--icon-brands-viber);
}

.icon-brands-vimeo-square:before {
  content: var(--icon-brands-vimeo-square);
}

.icon-brands-vimeo-v:before {
  content: var(--icon-brands-vimeo-v);
}

.icon-brands-vimeo:before {
  content: var(--icon-brands-vimeo);
}

.icon-brands-vine:before {
  content: var(--icon-brands-vine);
}

.icon-brands-vk:before {
  content: var(--icon-brands-vk);
}

.icon-brands-vnv:before {
  content: var(--icon-brands-vnv);
}

.icon-brands-vuejs:before {
  content: var(--icon-brands-vuejs);
}

.icon-brands-weebly:before {
  content: var(--icon-brands-weebly);
}

.icon-brands-weibo:before {
  content: var(--icon-brands-weibo);
}

.icon-brands-weixin:before {
  content: var(--icon-brands-weixin);
}

.icon-brands-whatsapp-square:before {
  content: var(--icon-brands-whatsapp-square);
}

.icon-brands-whatsapp:before {
  content: var(--icon-brands-whatsapp);
}

.icon-brands-whmcs:before {
  content: var(--icon-brands-whmcs);
}

.icon-brands-wikipedia-w:before {
  content: var(--icon-brands-wikipedia-w);
}

.icon-brands-windows:before {
  content: var(--icon-brands-windows);
}

.icon-brands-wix:before {
  content: var(--icon-brands-wix);
}

.icon-brands-wizards-of-the-coast:before {
  content: var(--icon-brands-wizards-of-the-coast);
}

.icon-brands-wolf-pack-battalion:before {
  content: var(--icon-brands-wolf-pack-battalion);
}

.icon-brands-wordpress-simple:before {
  content: var(--icon-brands-wordpress-simple);
}

.icon-brands-wordpress:before {
  content: var(--icon-brands-wordpress);
}

.icon-brands-wpbeginner:before {
  content: var(--icon-brands-wpbeginner);
}

.icon-brands-wpexplorer:before {
  content: var(--icon-brands-wpexplorer);
}

.icon-brands-wpforms:before {
  content: var(--icon-brands-wpforms);
}

.icon-brands-wpressr:before {
  content: var(--icon-brands-wpressr);
}

.icon-brands-xbox:before {
  content: var(--icon-brands-xbox);
}

.icon-brands-xing-square:before {
  content: var(--icon-brands-xing-square);
}

.icon-brands-xing:before {
  content: var(--icon-brands-xing);
}

.icon-brands-y-combinator:before {
  content: var(--icon-brands-y-combinator);
}

.icon-brands-yahoo:before {
  content: var(--icon-brands-yahoo);
}

.icon-brands-yandex-international:before {
  content: var(--icon-brands-yandex-international);
}

.icon-brands-yandex:before {
  content: var(--icon-brands-yandex);
}

.icon-brands-yarn:before {
  content: var(--icon-brands-yarn);
}

.icon-brands-yelp:before {
  content: var(--icon-brands-yelp);
}

.icon-brands-yoast:before {
  content: var(--icon-brands-yoast);
}

.icon-brands-youtube-square:before {
  content: var(--icon-brands-youtube-square);
}

.icon-brands-youtube:before {
  content: var(--icon-brands-youtube);
}

.icon-brands-zhihu:before {
  content: var(--icon-brands-zhihu);
}

.icon-address-book:before {
  content: var(--icon-address-book);
}

.icon-address-card:before {
  content: var(--icon-address-card);
}

.icon-angry:before {
  content: var(--icon-angry);
}

.icon-arrow-alt-circle-down:before {
  content: var(--icon-arrow-alt-circle-down);
}

.icon-arrow-alt-circle-left:before {
  content: var(--icon-arrow-alt-circle-left);
}

.icon-arrow-alt-circle-right:before {
  content: var(--icon-arrow-alt-circle-right);
}

.icon-arrow-alt-circle-up:before {
  content: var(--icon-arrow-alt-circle-up);
}

.icon-bell-slash:before {
  content: var(--icon-bell-slash);
}

.icon-bookmark:before {
  content: var(--icon-bookmark);
}

.icon-building:before {
  content: var(--icon-building);
}

.icon-calendar-alt:before {
  content: var(--icon-calendar-alt);
}

.icon-calendar-check:before {
  content: var(--icon-calendar-check);
}

.icon-calendar-minus:before {
  content: var(--icon-calendar-minus);
}

.icon-calendar-plus:before {
  content: var(--icon-calendar-plus);
}

.icon-calendar-times:before {
  content: var(--icon-calendar-times);
}

.icon-caret-square-down:before {
  content: var(--icon-caret-square-down);
}

.icon-caret-square-left:before {
  content: var(--icon-caret-square-left);
}

.icon-caret-square-right:before {
  content: var(--icon-caret-square-right);
}

.icon-caret-square-up:before {
  content: var(--icon-caret-square-up);
}

.icon-chart-bar:before {
  content: var(--icon-chart-bar);
}

.icon-check-circle:before {
  content: var(--icon-check-circle);
}

.icon-check-square:before {
  content: var(--icon-check-square);
}

.icon-circle:before {
  content: var(--icon-circle);
}

.icon-clone:before {
  content: var(--icon-clone);
}

.icon-closed-captioning:before {
  content: var(--icon-closed-captioning);
}

.icon-comment-alt:before {
  content: var(--icon-comment-alt);
}

.icon-comment-dots:before {
  content: var(--icon-comment-dots);
}

.icon-comment:before {
  content: var(--icon-comment);
}

.icon-comments:before {
  content: var(--icon-comments);
}

.icon-copy:before {
  content: var(--icon-copy);
}

.icon-copyright:before {
  content: var(--icon-copyright);
}

.icon-credit-card:before {
  content: var(--icon-credit-card);
}

.icon-dizzy:before {
  content: var(--icon-dizzy);
}

.icon-dot-circle:before {
  content: var(--icon-dot-circle);
}

.icon-edit:before {
  content: var(--icon-edit);
}

.icon-envelope-open:before {
  content: var(--icon-envelope-open);
}

.icon-envelope:before {
  content: var(--icon-envelope);
}

.icon-eye-slash:before {
  content: var(--icon-eye-slash);
}

.icon-eye:before {
  content: var(--icon-eye);
}

.icon-file-alt:before {
  content: var(--icon-file-alt);
}

.icon-file-archive:before {
  content: var(--icon-file-archive);
}

.icon-file-audio:before {
  content: var(--icon-file-audio);
}

.icon-file-code:before {
  content: var(--icon-file-code);
}

.icon-file-excel:before {
  content: var(--icon-file-excel);
}

.icon-file-image:before {
  content: var(--icon-file-image);
}

.icon-file-pdf:before {
  content: var(--icon-file-pdf);
}

.icon-file-powerpoint:before {
  content: var(--icon-file-powerpoint);
}

.icon-file-video:before {
  content: var(--icon-file-video);
}

.icon-file-word:before {
  content: var(--icon-file-word);
}

.icon-file:before {
  content: var(--icon-file);
}

.icon-flag:before {
  content: var(--icon-flag);
}

.icon-flushed:before {
  content: var(--icon-flushed);
}

.icon-folder-open:before {
  content: var(--icon-folder-open);
}

.icon-folder:before {
  content: var(--icon-folder);
}

.icon-font-awesome-logo-full:before {
  content: var(--icon-font-awesome-logo-full);
}

.icon-frown-open:before {
  content: var(--icon-frown-open);
}

.icon-frown:before {
  content: var(--icon-frown);
}

.icon-futbol:before {
  content: var(--icon-futbol);
}

.icon-gem:before {
  content: var(--icon-gem);
}

.icon-grimace:before {
  content: var(--icon-grimace);
}

.icon-grin-alt:before {
  content: var(--icon-grin-alt);
}

.icon-grin-beam-sweat:before {
  content: var(--icon-grin-beam-sweat);
}

.icon-grin-beam:before {
  content: var(--icon-grin-beam);
}

.icon-grin-hearts:before {
  content: var(--icon-grin-hearts);
}

.icon-grin-squint-tears:before {
  content: var(--icon-grin-squint-tears);
}

.icon-grin-squint:before {
  content: var(--icon-grin-squint);
}

.icon-grin-stars:before {
  content: var(--icon-grin-stars);
}

.icon-grin-tears:before {
  content: var(--icon-grin-tears);
}

.icon-grin-tongue-squint:before {
  content: var(--icon-grin-tongue-squint);
}

.icon-grin-tongue-wink:before {
  content: var(--icon-grin-tongue-wink);
}

.icon-grin-tongue:before {
  content: var(--icon-grin-tongue);
}

.icon-grin-wink:before {
  content: var(--icon-grin-wink);
}

.icon-grin:before {
  content: var(--icon-grin);
}

.icon-hand-lizard:before {
  content: var(--icon-hand-lizard);
}

.icon-hand-paper:before {
  content: var(--icon-hand-paper);
}

.icon-hand-peace:before {
  content: var(--icon-hand-peace);
}

.icon-hand-point-down:before {
  content: var(--icon-hand-point-down);
}

.icon-hand-point-left:before {
  content: var(--icon-hand-point-left);
}

.icon-hand-point-right:before {
  content: var(--icon-hand-point-right);
}

.icon-hand-point-up:before {
  content: var(--icon-hand-point-up);
}

.icon-hand-pointer:before {
  content: var(--icon-hand-pointer);
}

.icon-hand-rock:before {
  content: var(--icon-hand-rock);
}

.icon-hand-scissors:before {
  content: var(--icon-hand-scissors);
}

.icon-hand-spock:before {
  content: var(--icon-hand-spock);
}

.icon-handshake:before {
  content: var(--icon-handshake);
}

.icon-hdd:before {
  content: var(--icon-hdd);
}

.icon-heart:before {
  content: var(--icon-heart);
}

.icon-hospital:before {
  content: var(--icon-hospital);
}

.icon-hourglass:before {
  content: var(--icon-hourglass);
}

.icon-id-badge:before {
  content: var(--icon-id-badge);
}

.icon-id-card:before {
  content: var(--icon-id-card);
}

.icon-image:before {
  content: var(--icon-image);
}

.icon-images:before {
  content: var(--icon-images);
}

.icon-kiss-beam:before {
  content: var(--icon-kiss-beam);
}

.icon-kiss-wink-heart:before {
  content: var(--icon-kiss-wink-heart);
}

.icon-kiss:before {
  content: var(--icon-kiss);
}

.icon-laugh-beam:before {
  content: var(--icon-laugh-beam);
}

.icon-laugh-squint:before {
  content: var(--icon-laugh-squint);
}

.icon-laugh-wink:before {
  content: var(--icon-laugh-wink);
}

.icon-laugh:before {
  content: var(--icon-laugh);
}

.icon-lemon:before {
  content: var(--icon-lemon);
}

.icon-life-ring:before {
  content: var(--icon-life-ring);
}

.icon-lightbulb:before {
  content: var(--icon-lightbulb);
}

.icon-list-alt:before {
  content: var(--icon-list-alt);
}

.icon-map:before {
  content: var(--icon-map);
}

.icon-meh-blank:before {
  content: var(--icon-meh-blank);
}

.icon-meh-rolling-eyes:before {
  content: var(--icon-meh-rolling-eyes);
}

.icon-meh:before {
  content: var(--icon-meh);
}

.icon-minus-square:before {
  content: var(--icon-minus-square);
}

.icon-money-bill-alt:before {
  content: var(--icon-money-bill-alt);
}

.icon-moon:before {
  content: var(--icon-moon);
}

.icon-object-group:before {
  content: var(--icon-object-group);
}

.icon-object-ungroup:before {
  content: var(--icon-object-ungroup);
}

.icon-paper-plane:before {
  content: var(--icon-paper-plane);
}

.icon-pause-circle:before {
  content: var(--icon-pause-circle);
}

.icon-play-circle:before {
  content: var(--icon-play-circle);
}

.icon-plus-square:before {
  content: var(--icon-plus-square);
}

.icon-question-circle:before {
  content: var(--icon-question-circle);
}

.icon-registered:before {
  content: var(--icon-registered);
}

.icon-sad-cry:before {
  content: var(--icon-sad-cry);
}

.icon-sad-tear:before {
  content: var(--icon-sad-tear);
}

.icon-save:before {
  content: var(--icon-save);
}

.icon-share-square:before {
  content: var(--icon-share-square);
}

.icon-smile-beam:before {
  content: var(--icon-smile-beam);
}

.icon-smile-wink:before {
  content: var(--icon-smile-wink);
}

.icon-smile:before {
  content: var(--icon-smile);
}

.icon-snowflake:before {
  content: var(--icon-snowflake);
}

.icon-square:before {
  content: var(--icon-square);
}

.icon-star-half:before {
  content: var(--icon-star-half);
}

.icon-star:before {
  content: var(--icon-star);
}

.icon-sticky-note:before {
  content: var(--icon-sticky-note);
}

.icon-stop-circle:before {
  content: var(--icon-stop-circle);
}

.icon-sun:before {
  content: var(--icon-sun);
}

.icon-surprise:before {
  content: var(--icon-surprise);
}

.icon-thumbs-down:before {
  content: var(--icon-thumbs-down);
}

.icon-thumbs-up:before {
  content: var(--icon-thumbs-up);
}

.icon-times-circle:before {
  content: var(--icon-times-circle);
}

.icon-tired:before {
  content: var(--icon-tired);
}

.icon-trash-alt:before {
  content: var(--icon-trash-alt);
}

.icon-user-circle:before {
  content: var(--icon-user-circle);
}

.icon-user:before {
  content: var(--icon-user);
}

.icon-window-close:before {
  content: var(--icon-window-close);
}

.icon-window-maximize:before {
  content: var(--icon-window-maximize);
}

.icon-window-minimize:before {
  content: var(--icon-window-minimize);
}

.icon-window-restore:before {
  content: var(--icon-window-restore);
}

.icon-solid-ad:before {
  content: var(--icon-solid-ad);
}

.icon-solid-address-book:before {
  content: var(--icon-solid-address-book);
}

.icon-solid-address-card:before {
  content: var(--icon-solid-address-card);
}

.icon-solid-adjust:before {
  content: var(--icon-solid-adjust);
}

.icon-solid-air-freshener:before {
  content: var(--icon-solid-air-freshener);
}

.icon-solid-align-center:before {
  content: var(--icon-solid-align-center);
}

.icon-solid-align-justify:before {
  content: var(--icon-solid-align-justify);
}

.icon-solid-align-left:before {
  content: var(--icon-solid-align-left);
}

.icon-solid-align-right:before {
  content: var(--icon-solid-align-right);
}

.icon-solid-allergies:before {
  content: var(--icon-solid-allergies);
}

.icon-solid-ambulance:before {
  content: var(--icon-solid-ambulance);
}

.icon-solid-american-sign-language-interpreting:before {
  content: var(--icon-solid-american-sign-language-interpreting);
}

.icon-solid-anchor:before {
  content: var(--icon-solid-anchor);
}

.icon-solid-angle-double-down:before {
  content: var(--icon-solid-angle-double-down);
}

.icon-solid-angle-double-left:before {
  content: var(--icon-solid-angle-double-left);
}

.icon-solid-angle-double-right:before {
  content: var(--icon-solid-angle-double-right);
}

.icon-solid-angle-double-up:before {
  content: var(--icon-solid-angle-double-up);
}

.icon-solid-angle-down:before {
  content: var(--icon-solid-angle-down);
}

.icon-solid-angle-left:before {
  content: var(--icon-solid-angle-left);
}

.icon-solid-angle-right:before {
  content: var(--icon-solid-angle-right);
}

.icon-solid-angle-up:before {
  content: var(--icon-solid-angle-up);
}

.icon-solid-angry:before {
  content: var(--icon-solid-angry);
}

.icon-solid-ankh:before {
  content: var(--icon-solid-ankh);
}

.icon-solid-apple-alt:before {
  content: var(--icon-solid-apple-alt);
}

.icon-solid-archive:before {
  content: var(--icon-solid-archive);
}

.icon-solid-archway:before {
  content: var(--icon-solid-archway);
}

.icon-solid-arrow-alt-circle-down:before {
  content: var(--icon-solid-arrow-alt-circle-down);
}

.icon-solid-arrow-alt-circle-left:before {
  content: var(--icon-solid-arrow-alt-circle-left);
}

.icon-solid-arrow-alt-circle-right:before {
  content: var(--icon-solid-arrow-alt-circle-right);
}

.icon-solid-arrow-alt-circle-up:before {
  content: var(--icon-solid-arrow-alt-circle-up);
}

.icon-solid-arrow-circle-down:before {
  content: var(--icon-solid-arrow-circle-down);
}

.icon-solid-arrow-circle-left:before {
  content: var(--icon-solid-arrow-circle-left);
}

.icon-solid-arrow-circle-right:before {
  content: var(--icon-solid-arrow-circle-right);
}

.icon-solid-arrow-circle-up:before {
  content: var(--icon-solid-arrow-circle-up);
}

.icon-solid-arrow-down:before {
  content: var(--icon-solid-arrow-down);
}

.icon-solid-arrow-left:before {
  content: var(--icon-solid-arrow-left);
}

.icon-solid-arrow-right:before {
  content: var(--icon-solid-arrow-right);
}

.icon-solid-arrow-up:before {
  content: var(--icon-solid-arrow-up);
}

.icon-solid-arrows-alt-h:before {
  content: var(--icon-solid-arrows-alt-h);
}

.icon-solid-arrows-alt-v:before {
  content: var(--icon-solid-arrows-alt-v);
}

.icon-solid-arrows-alt:before {
  content: var(--icon-solid-arrows-alt);
}

.icon-solid-assistive-listening-systems:before {
  content: var(--icon-solid-assistive-listening-systems);
}

.icon-solid-asterisk:before {
  content: var(--icon-solid-asterisk);
}

.icon-solid-at:before {
  content: var(--icon-solid-at);
}

.icon-solid-atlas:before {
  content: var(--icon-solid-atlas);
}

.icon-solid-atom:before {
  content: var(--icon-solid-atom);
}

.icon-solid-audio-description:before {
  content: var(--icon-solid-audio-description);
}

.icon-solid-award:before {
  content: var(--icon-solid-award);
}

.icon-solid-baby-carriage:before {
  content: var(--icon-solid-baby-carriage);
}

.icon-solid-baby:before {
  content: var(--icon-solid-baby);
}

.icon-solid-backspace:before {
  content: var(--icon-solid-backspace);
}

.icon-solid-backward:before {
  content: var(--icon-solid-backward);
}

.icon-solid-bacon:before {
  content: var(--icon-solid-bacon);
}

.icon-solid-balance-scale:before {
  content: var(--icon-solid-balance-scale);
}

.icon-solid-ban:before {
  content: var(--icon-solid-ban);
}

.icon-solid-band-aid:before {
  content: var(--icon-solid-band-aid);
}

.icon-solid-barcode:before {
  content: var(--icon-solid-barcode);
}

.icon-solid-bars:before {
  content: var(--icon-solid-bars);
}

.icon-solid-baseball-ball:before {
  content: var(--icon-solid-baseball-ball);
}

.icon-solid-basketball-ball:before {
  content: var(--icon-solid-basketball-ball);
}

.icon-solid-bath:before {
  content: var(--icon-solid-bath);
}

.icon-solid-battery-empty:before {
  content: var(--icon-solid-battery-empty);
}

.icon-solid-battery-full:before {
  content: var(--icon-solid-battery-full);
}

.icon-solid-battery-half:before {
  content: var(--icon-solid-battery-half);
}

.icon-solid-battery-quarter:before {
  content: var(--icon-solid-battery-quarter);
}

.icon-solid-battery-three-quarters:before {
  content: var(--icon-solid-battery-three-quarters);
}

.icon-solid-bed:before {
  content: var(--icon-solid-bed);
}

.icon-solid-beer:before {
  content: var(--icon-solid-beer);
}

.icon-solid-bell-slash:before {
  content: var(--icon-solid-bell-slash);
}

.icon-solid-bell:before {
  content: var(--icon-solid-bell);
}

.icon-solid-bezier-curve:before {
  content: var(--icon-solid-bezier-curve);
}

.icon-solid-bible:before {
  content: var(--icon-solid-bible);
}

.icon-solid-bicycle:before {
  content: var(--icon-solid-bicycle);
}

.icon-solid-binoculars:before {
  content: var(--icon-solid-binoculars);
}

.icon-solid-biohazard:before {
  content: var(--icon-solid-biohazard);
}

.icon-solid-birthday-cake:before {
  content: var(--icon-solid-birthday-cake);
}

.icon-solid-blender-phone:before {
  content: var(--icon-solid-blender-phone);
}

.icon-solid-blender:before {
  content: var(--icon-solid-blender);
}

.icon-solid-blind:before {
  content: var(--icon-solid-blind);
}

.icon-solid-blog:before {
  content: var(--icon-solid-blog);
}

.icon-solid-bold:before {
  content: var(--icon-solid-bold);
}

.icon-solid-bolt:before {
  content: var(--icon-solid-bolt);
}

.icon-solid-bomb:before {
  content: var(--icon-solid-bomb);
}

.icon-solid-bone:before {
  content: var(--icon-solid-bone);
}

.icon-solid-bong:before {
  content: var(--icon-solid-bong);
}

.icon-solid-book-dead:before {
  content: var(--icon-solid-book-dead);
}

.icon-solid-book-medical:before {
  content: var(--icon-solid-book-medical);
}

.icon-solid-book-open:before {
  content: var(--icon-solid-book-open);
}

.icon-solid-book-reader:before {
  content: var(--icon-solid-book-reader);
}

.icon-solid-book:before {
  content: var(--icon-solid-book);
}

.icon-solid-bookmark:before {
  content: var(--icon-solid-bookmark);
}

.icon-solid-bowling-ball:before {
  content: var(--icon-solid-bowling-ball);
}

.icon-solid-box-open:before {
  content: var(--icon-solid-box-open);
}

.icon-solid-box:before {
  content: var(--icon-solid-box);
}

.icon-solid-boxes:before {
  content: var(--icon-solid-boxes);
}

.icon-solid-braille:before {
  content: var(--icon-solid-braille);
}

.icon-solid-brain:before {
  content: var(--icon-solid-brain);
}

.icon-solid-bread-slice:before {
  content: var(--icon-solid-bread-slice);
}

.icon-solid-briefcase-medical:before {
  content: var(--icon-solid-briefcase-medical);
}

.icon-solid-briefcase:before {
  content: var(--icon-solid-briefcase);
}

.icon-solid-broadcast-tower:before {
  content: var(--icon-solid-broadcast-tower);
}

.icon-solid-broom:before {
  content: var(--icon-solid-broom);
}

.icon-solid-brush:before {
  content: var(--icon-solid-brush);
}

.icon-solid-bug:before {
  content: var(--icon-solid-bug);
}

.icon-solid-building:before {
  content: var(--icon-solid-building);
}

.icon-solid-bullhorn:before {
  content: var(--icon-solid-bullhorn);
}

.icon-solid-bullseye:before {
  content: var(--icon-solid-bullseye);
}

.icon-solid-burn:before {
  content: var(--icon-solid-burn);
}

.icon-solid-bus-alt:before {
  content: var(--icon-solid-bus-alt);
}

.icon-solid-bus:before {
  content: var(--icon-solid-bus);
}

.icon-solid-business-time:before {
  content: var(--icon-solid-business-time);
}

.icon-solid-calculator:before {
  content: var(--icon-solid-calculator);
}

.icon-solid-calendar-alt:before {
  content: var(--icon-solid-calendar-alt);
}

.icon-solid-calendar-check:before {
  content: var(--icon-solid-calendar-check);
}

.icon-solid-calendar-day:before {
  content: var(--icon-solid-calendar-day);
}

.icon-solid-calendar-minus:before {
  content: var(--icon-solid-calendar-minus);
}

.icon-solid-calendar-plus:before {
  content: var(--icon-solid-calendar-plus);
}

.icon-solid-calendar-times:before {
  content: var(--icon-solid-calendar-times);
}

.icon-solid-calendar-week:before {
  content: var(--icon-solid-calendar-week);
}

.icon-solid-calendar:before {
  content: var(--icon-solid-calendar);
}

.icon-solid-camera-retro:before {
  content: var(--icon-solid-camera-retro);
}

.icon-solid-camera:before {
  content: var(--icon-solid-camera);
}

.icon-solid-campground:before {
  content: var(--icon-solid-campground);
}

.icon-solid-candy-cane:before {
  content: var(--icon-solid-candy-cane);
}

.icon-solid-cannabis:before {
  content: var(--icon-solid-cannabis);
}

.icon-solid-capsules:before {
  content: var(--icon-solid-capsules);
}

.icon-solid-car-alt:before {
  content: var(--icon-solid-car-alt);
}

.icon-solid-car-battery:before {
  content: var(--icon-solid-car-battery);
}

.icon-solid-car-crash:before {
  content: var(--icon-solid-car-crash);
}

.icon-solid-car-side:before {
  content: var(--icon-solid-car-side);
}

.icon-solid-car:before {
  content: var(--icon-solid-car);
}

.icon-solid-caret-down:before {
  content: var(--icon-solid-caret-down);
}

.icon-solid-caret-left:before {
  content: var(--icon-solid-caret-left);
}

.icon-solid-caret-right:before {
  content: var(--icon-solid-caret-right);
}

.icon-solid-caret-square-down:before {
  content: var(--icon-solid-caret-square-down);
}

.icon-solid-caret-square-left:before {
  content: var(--icon-solid-caret-square-left);
}

.icon-solid-caret-square-right:before {
  content: var(--icon-solid-caret-square-right);
}

.icon-solid-caret-square-up:before {
  content: var(--icon-solid-caret-square-up);
}

.icon-solid-caret-up:before {
  content: var(--icon-solid-caret-up);
}

.icon-solid-carrot:before {
  content: var(--icon-solid-carrot);
}

.icon-solid-cart-arrow-down:before {
  content: var(--icon-solid-cart-arrow-down);
}

.icon-solid-cart-plus:before {
  content: var(--icon-solid-cart-plus);
}

.icon-solid-cash-register:before {
  content: var(--icon-solid-cash-register);
}

.icon-solid-cat:before {
  content: var(--icon-solid-cat);
}

.icon-solid-certificate:before {
  content: var(--icon-solid-certificate);
}

.icon-solid-chair:before {
  content: var(--icon-solid-chair);
}

.icon-solid-chalkboard-teacher:before {
  content: var(--icon-solid-chalkboard-teacher);
}

.icon-solid-chalkboard:before {
  content: var(--icon-solid-chalkboard);
}

.icon-solid-charging-station:before {
  content: var(--icon-solid-charging-station);
}

.icon-solid-chart-area:before {
  content: var(--icon-solid-chart-area);
}

.icon-solid-chart-bar:before {
  content: var(--icon-solid-chart-bar);
}

.icon-solid-chart-line:before {
  content: var(--icon-solid-chart-line);
}

.icon-solid-chart-pie:before {
  content: var(--icon-solid-chart-pie);
}

.icon-solid-check-circle:before {
  content: var(--icon-solid-check-circle);
}

.icon-solid-check-double:before {
  content: var(--icon-solid-check-double);
}

.icon-solid-check-square:before {
  content: var(--icon-solid-check-square);
}

.icon-solid-check:before {
  content: var(--icon-solid-check);
}

.icon-solid-cheese:before {
  content: var(--icon-solid-cheese);
}

.icon-solid-chess-bishop:before {
  content: var(--icon-solid-chess-bishop);
}

.icon-solid-chess-board:before {
  content: var(--icon-solid-chess-board);
}

.icon-solid-chess-king:before {
  content: var(--icon-solid-chess-king);
}

.icon-solid-chess-knight:before {
  content: var(--icon-solid-chess-knight);
}

.icon-solid-chess-pawn:before {
  content: var(--icon-solid-chess-pawn);
}

.icon-solid-chess-queen:before {
  content: var(--icon-solid-chess-queen);
}

.icon-solid-chess-rook:before {
  content: var(--icon-solid-chess-rook);
}

.icon-solid-chess:before {
  content: var(--icon-solid-chess);
}

.icon-solid-chevron-circle-down:before {
  content: var(--icon-solid-chevron-circle-down);
}

.icon-solid-chevron-circle-left:before {
  content: var(--icon-solid-chevron-circle-left);
}

.icon-solid-chevron-circle-right:before {
  content: var(--icon-solid-chevron-circle-right);
}

.icon-solid-chevron-circle-up:before {
  content: var(--icon-solid-chevron-circle-up);
}

.icon-solid-chevron-down:before {
  content: var(--icon-solid-chevron-down);
}

.icon-solid-chevron-left:before {
  content: var(--icon-solid-chevron-left);
}

.icon-solid-chevron-right:before {
  content: var(--icon-solid-chevron-right);
}

.icon-solid-chevron-up:before {
  content: var(--icon-solid-chevron-up);
}

.icon-solid-child:before {
  content: var(--icon-solid-child);
}

.icon-solid-church:before {
  content: var(--icon-solid-church);
}

.icon-solid-circle-notch:before {
  content: var(--icon-solid-circle-notch);
}

.icon-solid-circle:before {
  content: var(--icon-solid-circle);
}

.icon-solid-city:before {
  content: var(--icon-solid-city);
}

.icon-solid-clinic-medical:before {
  content: var(--icon-solid-clinic-medical);
}

.icon-solid-clipboard-check:before {
  content: var(--icon-solid-clipboard-check);
}

.icon-solid-clipboard-list:before {
  content: var(--icon-solid-clipboard-list);
}

.icon-solid-clipboard:before {
  content: var(--icon-solid-clipboard);
}

.icon-solid-clock:before {
  content: var(--icon-solid-clock);
}

.icon-solid-clone:before {
  content: var(--icon-solid-clone);
}

.icon-solid-closed-captioning:before {
  content: var(--icon-solid-closed-captioning);
}

.icon-solid-cloud-download-alt:before {
  content: var(--icon-solid-cloud-download-alt);
}

.icon-solid-cloud-meatball:before {
  content: var(--icon-solid-cloud-meatball);
}

.icon-solid-cloud-moon-rain:before {
  content: var(--icon-solid-cloud-moon-rain);
}

.icon-solid-cloud-moon:before {
  content: var(--icon-solid-cloud-moon);
}

.icon-solid-cloud-rain:before {
  content: var(--icon-solid-cloud-rain);
}

.icon-solid-cloud-showers-heavy:before {
  content: var(--icon-solid-cloud-showers-heavy);
}

.icon-solid-cloud-sun-rain:before {
  content: var(--icon-solid-cloud-sun-rain);
}

.icon-solid-cloud-sun:before {
  content: var(--icon-solid-cloud-sun);
}

.icon-solid-cloud-upload-alt:before {
  content: var(--icon-solid-cloud-upload-alt);
}

.icon-solid-cloud:before {
  content: var(--icon-solid-cloud);
}

.icon-solid-cocktail:before {
  content: var(--icon-solid-cocktail);
}

.icon-solid-code-branch:before {
  content: var(--icon-solid-code-branch);
}

.icon-solid-code:before {
  content: var(--icon-solid-code);
}

.icon-solid-coffee:before {
  content: var(--icon-solid-coffee);
}

.icon-solid-cog:before {
  content: var(--icon-solid-cog);
}

.icon-solid-cogs:before {
  content: var(--icon-solid-cogs);
}

.icon-solid-coins:before {
  content: var(--icon-solid-coins);
}

.icon-solid-columns:before {
  content: var(--icon-solid-columns);
}

.icon-solid-comment-alt:before {
  content: var(--icon-solid-comment-alt);
}

.icon-solid-comment-dollar:before {
  content: var(--icon-solid-comment-dollar);
}

.icon-solid-comment-dots:before {
  content: var(--icon-solid-comment-dots);
}

.icon-solid-comment-medical:before {
  content: var(--icon-solid-comment-medical);
}

.icon-solid-comment-slash:before {
  content: var(--icon-solid-comment-slash);
}

.icon-solid-comment:before {
  content: var(--icon-solid-comment);
}

.icon-solid-comments-dollar:before {
  content: var(--icon-solid-comments-dollar);
}

.icon-solid-comments:before {
  content: var(--icon-solid-comments);
}

.icon-solid-compact-disc:before {
  content: var(--icon-solid-compact-disc);
}

.icon-solid-compass:before {
  content: var(--icon-solid-compass);
}

.icon-solid-compress-arrows-alt:before {
  content: var(--icon-solid-compress-arrows-alt);
}

.icon-solid-compress:before {
  content: var(--icon-solid-compress);
}

.icon-solid-concierge-bell:before {
  content: var(--icon-solid-concierge-bell);
}

.icon-solid-cookie-bite:before {
  content: var(--icon-solid-cookie-bite);
}

.icon-solid-cookie:before {
  content: var(--icon-solid-cookie);
}

.icon-solid-copy:before {
  content: var(--icon-solid-copy);
}

.icon-solid-copyright:before {
  content: var(--icon-solid-copyright);
}

.icon-solid-couch:before {
  content: var(--icon-solid-couch);
}

.icon-solid-credit-card:before {
  content: var(--icon-solid-credit-card);
}

.icon-solid-crop-alt:before {
  content: var(--icon-solid-crop-alt);
}

.icon-solid-crop:before {
  content: var(--icon-solid-crop);
}

.icon-solid-cross:before {
  content: var(--icon-solid-cross);
}

.icon-solid-crosshairs:before {
  content: var(--icon-solid-crosshairs);
}

.icon-solid-crow:before {
  content: var(--icon-solid-crow);
}

.icon-solid-crown:before {
  content: var(--icon-solid-crown);
}

.icon-solid-crutch:before {
  content: var(--icon-solid-crutch);
}

.icon-solid-cube:before {
  content: var(--icon-solid-cube);
}

.icon-solid-cubes:before {
  content: var(--icon-solid-cubes);
}

.icon-solid-cut:before {
  content: var(--icon-solid-cut);
}

.icon-solid-database:before {
  content: var(--icon-solid-database);
}

.icon-solid-deaf:before {
  content: var(--icon-solid-deaf);
}

.icon-solid-democrat:before {
  content: var(--icon-solid-democrat);
}

.icon-solid-desktop:before {
  content: var(--icon-solid-desktop);
}

.icon-solid-dharmachakra:before {
  content: var(--icon-solid-dharmachakra);
}

.icon-solid-diagnoses:before {
  content: var(--icon-solid-diagnoses);
}

.icon-solid-dice-d20:before {
  content: var(--icon-solid-dice-d20);
}

.icon-solid-dice-d6:before {
  content: var(--icon-solid-dice-d6);
}

.icon-solid-dice-five:before {
  content: var(--icon-solid-dice-five);
}

.icon-solid-dice-four:before {
  content: var(--icon-solid-dice-four);
}

.icon-solid-dice-one:before {
  content: var(--icon-solid-dice-one);
}

.icon-solid-dice-six:before {
  content: var(--icon-solid-dice-six);
}

.icon-solid-dice-three:before {
  content: var(--icon-solid-dice-three);
}

.icon-solid-dice-two:before {
  content: var(--icon-solid-dice-two);
}

.icon-solid-dice:before {
  content: var(--icon-solid-dice);
}

.icon-solid-digital-tachograph:before {
  content: var(--icon-solid-digital-tachograph);
}

.icon-solid-directions:before {
  content: var(--icon-solid-directions);
}

.icon-solid-divide:before {
  content: var(--icon-solid-divide);
}

.icon-solid-dizzy:before {
  content: var(--icon-solid-dizzy);
}

.icon-solid-dna:before {
  content: var(--icon-solid-dna);
}

.icon-solid-dog:before {
  content: var(--icon-solid-dog);
}

.icon-solid-dollar-sign:before {
  content: var(--icon-solid-dollar-sign);
}

.icon-solid-dolly-flatbed:before {
  content: var(--icon-solid-dolly-flatbed);
}

.icon-solid-dolly:before {
  content: var(--icon-solid-dolly);
}

.icon-solid-donate:before {
  content: var(--icon-solid-donate);
}

.icon-solid-door-closed:before {
  content: var(--icon-solid-door-closed);
}

.icon-solid-door-open:before {
  content: var(--icon-solid-door-open);
}

.icon-solid-dot-circle:before {
  content: var(--icon-solid-dot-circle);
}

.icon-solid-dove:before {
  content: var(--icon-solid-dove);
}

.icon-solid-download:before {
  content: var(--icon-solid-download);
}

.icon-solid-drafting-compass:before {
  content: var(--icon-solid-drafting-compass);
}

.icon-solid-dragon:before {
  content: var(--icon-solid-dragon);
}

.icon-solid-draw-polygon:before {
  content: var(--icon-solid-draw-polygon);
}

.icon-solid-drum-steelpan:before {
  content: var(--icon-solid-drum-steelpan);
}

.icon-solid-drum:before {
  content: var(--icon-solid-drum);
}

.icon-solid-drumstick-bite:before {
  content: var(--icon-solid-drumstick-bite);
}

.icon-solid-dumbbell:before {
  content: var(--icon-solid-dumbbell);
}

.icon-solid-dumpster-fire:before {
  content: var(--icon-solid-dumpster-fire);
}

.icon-solid-dumpster:before {
  content: var(--icon-solid-dumpster);
}

.icon-solid-dungeon:before {
  content: var(--icon-solid-dungeon);
}

.icon-solid-edit:before {
  content: var(--icon-solid-edit);
}

.icon-solid-egg:before {
  content: var(--icon-solid-egg);
}

.icon-solid-eject:before {
  content: var(--icon-solid-eject);
}

.icon-solid-ellipsis-h:before {
  content: var(--icon-solid-ellipsis-h);
}

.icon-solid-ellipsis-v:before {
  content: var(--icon-solid-ellipsis-v);
}

.icon-solid-envelope-open-text:before {
  content: var(--icon-solid-envelope-open-text);
}

.icon-solid-envelope-open:before {
  content: var(--icon-solid-envelope-open);
}

.icon-solid-envelope-square:before {
  content: var(--icon-solid-envelope-square);
}

.icon-solid-envelope:before {
  content: var(--icon-solid-envelope);
}

.icon-solid-equals:before {
  content: var(--icon-solid-equals);
}

.icon-solid-eraser:before {
  content: var(--icon-solid-eraser);
}

.icon-solid-ethernet:before {
  content: var(--icon-solid-ethernet);
}

.icon-solid-euro-sign:before {
  content: var(--icon-solid-euro-sign);
}

.icon-solid-exchange-alt:before {
  content: var(--icon-solid-exchange-alt);
}

.icon-solid-exclamation-circle:before {
  content: var(--icon-solid-exclamation-circle);
}

.icon-solid-exclamation-triangle:before {
  content: var(--icon-solid-exclamation-triangle);
}

.icon-solid-exclamation:before {
  content: var(--icon-solid-exclamation);
}

.icon-solid-expand-arrows-alt:before {
  content: var(--icon-solid-expand-arrows-alt);
}

.icon-solid-expand:before {
  content: var(--icon-solid-expand);
}

.icon-solid-external-link-alt:before {
  content: var(--icon-solid-external-link-alt);
}

.icon-solid-external-link-square-alt:before {
  content: var(--icon-solid-external-link-square-alt);
}

.icon-solid-eye-dropper:before {
  content: var(--icon-solid-eye-dropper);
}

.icon-solid-eye-slash:before {
  content: var(--icon-solid-eye-slash);
}

.icon-solid-eye:before {
  content: var(--icon-solid-eye);
}

.icon-solid-fast-backward:before {
  content: var(--icon-solid-fast-backward);
}

.icon-solid-fast-forward:before {
  content: var(--icon-solid-fast-forward);
}

.icon-solid-fax:before {
  content: var(--icon-solid-fax);
}

.icon-solid-feather-alt:before {
  content: var(--icon-solid-feather-alt);
}

.icon-solid-feather:before {
  content: var(--icon-solid-feather);
}

.icon-solid-female:before {
  content: var(--icon-solid-female);
}

.icon-solid-fighter-jet:before {
  content: var(--icon-solid-fighter-jet);
}

.icon-solid-file-alt:before {
  content: var(--icon-solid-file-alt);
}

.icon-solid-file-archive:before {
  content: var(--icon-solid-file-archive);
}

.icon-solid-file-audio:before {
  content: var(--icon-solid-file-audio);
}

.icon-solid-file-code:before {
  content: var(--icon-solid-file-code);
}

.icon-solid-file-contract:before {
  content: var(--icon-solid-file-contract);
}

.icon-solid-file-csv:before {
  content: var(--icon-solid-file-csv);
}

.icon-solid-file-download:before {
  content: var(--icon-solid-file-download);
}

.icon-solid-file-excel:before {
  content: var(--icon-solid-file-excel);
}

.icon-solid-file-export:before {
  content: var(--icon-solid-file-export);
}

.icon-solid-file-image:before {
  content: var(--icon-solid-file-image);
}

.icon-solid-file-import:before {
  content: var(--icon-solid-file-import);
}

.icon-solid-file-invoice-dollar:before {
  content: var(--icon-solid-file-invoice-dollar);
}

.icon-solid-file-invoice:before {
  content: var(--icon-solid-file-invoice);
}

.icon-solid-file-medical-alt:before {
  content: var(--icon-solid-file-medical-alt);
}

.icon-solid-file-medical:before {
  content: var(--icon-solid-file-medical);
}

.icon-solid-file-pdf:before {
  content: var(--icon-solid-file-pdf);
}

.icon-solid-file-powerpoint:before {
  content: var(--icon-solid-file-powerpoint);
}

.icon-solid-file-prescription:before {
  content: var(--icon-solid-file-prescription);
}

.icon-solid-file-signature:before {
  content: var(--icon-solid-file-signature);
}

.icon-solid-file-upload:before {
  content: var(--icon-solid-file-upload);
}

.icon-solid-file-video:before {
  content: var(--icon-solid-file-video);
}

.icon-solid-file-word:before {
  content: var(--icon-solid-file-word);
}

.icon-solid-file:before {
  content: var(--icon-solid-file);
}

.icon-solid-fill-drip:before {
  content: var(--icon-solid-fill-drip);
}

.icon-solid-fill:before {
  content: var(--icon-solid-fill);
}

.icon-solid-film:before {
  content: var(--icon-solid-film);
}

.icon-solid-filter:before {
  content: var(--icon-solid-filter);
}

.icon-solid-fingerprint:before {
  content: var(--icon-solid-fingerprint);
}

.icon-solid-fire-alt:before {
  content: var(--icon-solid-fire-alt);
}

.icon-solid-fire-extinguisher:before {
  content: var(--icon-solid-fire-extinguisher);
}

.icon-solid-fire:before {
  content: var(--icon-solid-fire);
}

.icon-solid-first-aid:before {
  content: var(--icon-solid-first-aid);
}

.icon-solid-fish:before {
  content: var(--icon-solid-fish);
}

.icon-solid-fist-raised:before {
  content: var(--icon-solid-fist-raised);
}

.icon-solid-flag-checkered:before {
  content: var(--icon-solid-flag-checkered);
}

.icon-solid-flag-usa:before {
  content: var(--icon-solid-flag-usa);
}

.icon-solid-flag:before {
  content: var(--icon-solid-flag);
}

.icon-solid-flask:before {
  content: var(--icon-solid-flask);
}

.icon-solid-flushed:before {
  content: var(--icon-solid-flushed);
}

.icon-solid-folder-minus:before {
  content: var(--icon-solid-folder-minus);
}

.icon-solid-folder-open:before {
  content: var(--icon-solid-folder-open);
}

.icon-solid-folder-plus:before {
  content: var(--icon-solid-folder-plus);
}

.icon-solid-folder:before {
  content: var(--icon-solid-folder);
}

.icon-solid-font-awesome-logo-full:before {
  content: var(--icon-solid-font-awesome-logo-full);
}

.icon-solid-font:before {
  content: var(--icon-solid-font);
}

.icon-solid-football-ball:before {
  content: var(--icon-solid-football-ball);
}

.icon-solid-forward:before {
  content: var(--icon-solid-forward);
}

.icon-solid-frog:before {
  content: var(--icon-solid-frog);
}

.icon-solid-frown-open:before {
  content: var(--icon-solid-frown-open);
}

.icon-solid-frown:before {
  content: var(--icon-solid-frown);
}

.icon-solid-funnel-dollar:before {
  content: var(--icon-solid-funnel-dollar);
}

.icon-solid-futbol:before {
  content: var(--icon-solid-futbol);
}

.icon-solid-gamepad:before {
  content: var(--icon-solid-gamepad);
}

.icon-solid-gas-pump:before {
  content: var(--icon-solid-gas-pump);
}

.icon-solid-gavel:before {
  content: var(--icon-solid-gavel);
}

.icon-solid-gem:before {
  content: var(--icon-solid-gem);
}

.icon-solid-genderless:before {
  content: var(--icon-solid-genderless);
}

.icon-solid-ghost:before {
  content: var(--icon-solid-ghost);
}

.icon-solid-gift:before {
  content: var(--icon-solid-gift);
}

.icon-solid-gifts:before {
  content: var(--icon-solid-gifts);
}

.icon-solid-glass-cheers:before {
  content: var(--icon-solid-glass-cheers);
}

.icon-solid-glass-martini-alt:before {
  content: var(--icon-solid-glass-martini-alt);
}

.icon-solid-glass-martini:before {
  content: var(--icon-solid-glass-martini);
}

.icon-solid-glass-whiskey:before {
  content: var(--icon-solid-glass-whiskey);
}

.icon-solid-glasses:before {
  content: var(--icon-solid-glasses);
}

.icon-solid-globe-africa:before {
  content: var(--icon-solid-globe-africa);
}

.icon-solid-globe-americas:before {
  content: var(--icon-solid-globe-americas);
}

.icon-solid-globe-asia:before {
  content: var(--icon-solid-globe-asia);
}

.icon-solid-globe-europe:before {
  content: var(--icon-solid-globe-europe);
}

.icon-solid-globe:before {
  content: var(--icon-solid-globe);
}

.icon-solid-golf-ball:before {
  content: var(--icon-solid-golf-ball);
}

.icon-solid-gopuram:before {
  content: var(--icon-solid-gopuram);
}

.icon-solid-graduation-cap:before {
  content: var(--icon-solid-graduation-cap);
}

.icon-solid-greater-than-equal:before {
  content: var(--icon-solid-greater-than-equal);
}

.icon-solid-greater-than:before {
  content: var(--icon-solid-greater-than);
}

.icon-solid-grimace:before {
  content: var(--icon-solid-grimace);
}

.icon-solid-grin-alt:before {
  content: var(--icon-solid-grin-alt);
}

.icon-solid-grin-beam-sweat:before {
  content: var(--icon-solid-grin-beam-sweat);
}

.icon-solid-grin-beam:before {
  content: var(--icon-solid-grin-beam);
}

.icon-solid-grin-hearts:before {
  content: var(--icon-solid-grin-hearts);
}

.icon-solid-grin-squint-tears:before {
  content: var(--icon-solid-grin-squint-tears);
}

.icon-solid-grin-squint:before {
  content: var(--icon-solid-grin-squint);
}

.icon-solid-grin-stars:before {
  content: var(--icon-solid-grin-stars);
}

.icon-solid-grin-tears:before {
  content: var(--icon-solid-grin-tears);
}

.icon-solid-grin-tongue-squint:before {
  content: var(--icon-solid-grin-tongue-squint);
}

.icon-solid-grin-tongue-wink:before {
  content: var(--icon-solid-grin-tongue-wink);
}

.icon-solid-grin-tongue:before {
  content: var(--icon-solid-grin-tongue);
}

.icon-solid-grin-wink:before {
  content: var(--icon-solid-grin-wink);
}

.icon-solid-grin:before {
  content: var(--icon-solid-grin);
}

.icon-solid-grip-horizontal:before {
  content: var(--icon-solid-grip-horizontal);
}

.icon-solid-grip-lines-vertical:before {
  content: var(--icon-solid-grip-lines-vertical);
}

.icon-solid-grip-lines:before {
  content: var(--icon-solid-grip-lines);
}

.icon-solid-grip-vertical:before {
  content: var(--icon-solid-grip-vertical);
}

.icon-solid-guitar:before {
  content: var(--icon-solid-guitar);
}

.icon-solid-h-square:before {
  content: var(--icon-solid-h-square);
}

.icon-solid-hamburger:before {
  content: var(--icon-solid-hamburger);
}

.icon-solid-hammer:before {
  content: var(--icon-solid-hammer);
}

.icon-solid-hamsa:before {
  content: var(--icon-solid-hamsa);
}

.icon-solid-hand-holding-heart:before {
  content: var(--icon-solid-hand-holding-heart);
}

.icon-solid-hand-holding-usd:before {
  content: var(--icon-solid-hand-holding-usd);
}

.icon-solid-hand-holding:before {
  content: var(--icon-solid-hand-holding);
}

.icon-solid-hand-lizard:before {
  content: var(--icon-solid-hand-lizard);
}

.icon-solid-hand-middle-finger:before {
  content: var(--icon-solid-hand-middle-finger);
}

.icon-solid-hand-paper:before {
  content: var(--icon-solid-hand-paper);
}

.icon-solid-hand-peace:before {
  content: var(--icon-solid-hand-peace);
}

.icon-solid-hand-point-down:before {
  content: var(--icon-solid-hand-point-down);
}

.icon-solid-hand-point-left:before {
  content: var(--icon-solid-hand-point-left);
}

.icon-solid-hand-point-right:before {
  content: var(--icon-solid-hand-point-right);
}

.icon-solid-hand-point-up:before {
  content: var(--icon-solid-hand-point-up);
}

.icon-solid-hand-pointer:before {
  content: var(--icon-solid-hand-pointer);
}

.icon-solid-hand-rock:before {
  content: var(--icon-solid-hand-rock);
}

.icon-solid-hand-scissors:before {
  content: var(--icon-solid-hand-scissors);
}

.icon-solid-hand-spock:before {
  content: var(--icon-solid-hand-spock);
}

.icon-solid-hands-helping:before {
  content: var(--icon-solid-hands-helping);
}

.icon-solid-hands:before {
  content: var(--icon-solid-hands);
}

.icon-solid-handshake:before {
  content: var(--icon-solid-handshake);
}

.icon-solid-hanukiah:before {
  content: var(--icon-solid-hanukiah);
}

.icon-solid-hard-hat:before {
  content: var(--icon-solid-hard-hat);
}

.icon-solid-hashtag:before {
  content: var(--icon-solid-hashtag);
}

.icon-solid-hat-wizard:before {
  content: var(--icon-solid-hat-wizard);
}

.icon-solid-haykal:before {
  content: var(--icon-solid-haykal);
}

.icon-solid-hdd:before {
  content: var(--icon-solid-hdd);
}

.icon-solid-heading:before {
  content: var(--icon-solid-heading);
}

.icon-solid-headphones-alt:before {
  content: var(--icon-solid-headphones-alt);
}

.icon-solid-headphones:before {
  content: var(--icon-solid-headphones);
}

.icon-solid-headset:before {
  content: var(--icon-solid-headset);
}

.icon-solid-heart-broken:before {
  content: var(--icon-solid-heart-broken);
}

.icon-solid-heart:before {
  content: var(--icon-solid-heart);
}

.icon-solid-heartbeat:before {
  content: var(--icon-solid-heartbeat);
}

.icon-solid-helicopter:before {
  content: var(--icon-solid-helicopter);
}

.icon-solid-highlighter:before {
  content: var(--icon-solid-highlighter);
}

.icon-solid-hiking:before {
  content: var(--icon-solid-hiking);
}

.icon-solid-hippo:before {
  content: var(--icon-solid-hippo);
}

.icon-solid-history:before {
  content: var(--icon-solid-history);
}

.icon-solid-hockey-puck:before {
  content: var(--icon-solid-hockey-puck);
}

.icon-solid-holly-berry:before {
  content: var(--icon-solid-holly-berry);
}

.icon-solid-home:before {
  content: var(--icon-solid-home);
}

.icon-solid-horse-head:before {
  content: var(--icon-solid-horse-head);
}

.icon-solid-horse:before {
  content: var(--icon-solid-horse);
}

.icon-solid-hospital-alt:before {
  content: var(--icon-solid-hospital-alt);
}

.icon-solid-hospital-symbol:before {
  content: var(--icon-solid-hospital-symbol);
}

.icon-solid-hospital:before {
  content: var(--icon-solid-hospital);
}

.icon-solid-hot-tub:before {
  content: var(--icon-solid-hot-tub);
}

.icon-solid-hotdog:before {
  content: var(--icon-solid-hotdog);
}

.icon-solid-hotel:before {
  content: var(--icon-solid-hotel);
}

.icon-solid-hourglass-end:before {
  content: var(--icon-solid-hourglass-end);
}

.icon-solid-hourglass-half:before {
  content: var(--icon-solid-hourglass-half);
}

.icon-solid-hourglass-start:before {
  content: var(--icon-solid-hourglass-start);
}

.icon-solid-hourglass:before {
  content: var(--icon-solid-hourglass);
}

.icon-solid-house-damage:before {
  content: var(--icon-solid-house-damage);
}

.icon-solid-hryvnia:before {
  content: var(--icon-solid-hryvnia);
}

.icon-solid-i-cursor:before {
  content: var(--icon-solid-i-cursor);
}

.icon-solid-ice-cream:before {
  content: var(--icon-solid-ice-cream);
}

.icon-solid-icicles:before {
  content: var(--icon-solid-icicles);
}

.icon-solid-id-badge:before {
  content: var(--icon-solid-id-badge);
}

.icon-solid-id-card-alt:before {
  content: var(--icon-solid-id-card-alt);
}

.icon-solid-id-card:before {
  content: var(--icon-solid-id-card);
}

.icon-solid-igloo:before {
  content: var(--icon-solid-igloo);
}

.icon-solid-image:before {
  content: var(--icon-solid-image);
}

.icon-solid-images:before {
  content: var(--icon-solid-images);
}

.icon-solid-inbox:before {
  content: var(--icon-solid-inbox);
}

.icon-solid-indent:before {
  content: var(--icon-solid-indent);
}

.icon-solid-industry:before {
  content: var(--icon-solid-industry);
}

.icon-solid-infinity:before {
  content: var(--icon-solid-infinity);
}

.icon-solid-info-circle:before {
  content: var(--icon-solid-info-circle);
}

.icon-solid-info:before {
  content: var(--icon-solid-info);
}

.icon-solid-italic:before {
  content: var(--icon-solid-italic);
}

.icon-solid-jedi:before {
  content: var(--icon-solid-jedi);
}

.icon-solid-joint:before {
  content: var(--icon-solid-joint);
}

.icon-solid-journal-whills:before {
  content: var(--icon-solid-journal-whills);
}

.icon-solid-kaaba:before {
  content: var(--icon-solid-kaaba);
}

.icon-solid-key:before {
  content: var(--icon-solid-key);
}

.icon-solid-keyboard:before {
  content: var(--icon-solid-keyboard);
}

.icon-solid-khanda:before {
  content: var(--icon-solid-khanda);
}

.icon-solid-kiss-beam:before {
  content: var(--icon-solid-kiss-beam);
}

.icon-solid-kiss-wink-heart:before {
  content: var(--icon-solid-kiss-wink-heart);
}

.icon-solid-kiss:before {
  content: var(--icon-solid-kiss);
}

.icon-solid-kiwi-bird:before {
  content: var(--icon-solid-kiwi-bird);
}

.icon-solid-landmark:before {
  content: var(--icon-solid-landmark);
}

.icon-solid-language:before {
  content: var(--icon-solid-language);
}

.icon-solid-laptop-code:before {
  content: var(--icon-solid-laptop-code);
}

.icon-solid-laptop-medical:before {
  content: var(--icon-solid-laptop-medical);
}

.icon-solid-laptop:before {
  content: var(--icon-solid-laptop);
}

.icon-solid-laugh-beam:before {
  content: var(--icon-solid-laugh-beam);
}

.icon-solid-laugh-squint:before {
  content: var(--icon-solid-laugh-squint);
}

.icon-solid-laugh-wink:before {
  content: var(--icon-solid-laugh-wink);
}

.icon-solid-laugh:before {
  content: var(--icon-solid-laugh);
}

.icon-solid-layer-group:before {
  content: var(--icon-solid-layer-group);
}

.icon-solid-leaf:before {
  content: var(--icon-solid-leaf);
}

.icon-solid-lemon:before {
  content: var(--icon-solid-lemon);
}

.icon-solid-less-than-equal:before {
  content: var(--icon-solid-less-than-equal);
}

.icon-solid-less-than:before {
  content: var(--icon-solid-less-than);
}

.icon-solid-level-down-alt:before {
  content: var(--icon-solid-level-down-alt);
}

.icon-solid-level-up-alt:before {
  content: var(--icon-solid-level-up-alt);
}

.icon-solid-life-ring:before {
  content: var(--icon-solid-life-ring);
}

.icon-solid-lightbulb:before {
  content: var(--icon-solid-lightbulb);
}

.icon-solid-link:before {
  content: var(--icon-solid-link);
}

.icon-solid-lira-sign:before {
  content: var(--icon-solid-lira-sign);
}

.icon-solid-list-alt:before {
  content: var(--icon-solid-list-alt);
}

.icon-solid-list-ol:before {
  content: var(--icon-solid-list-ol);
}

.icon-solid-list-ul:before {
  content: var(--icon-solid-list-ul);
}

.icon-solid-list:before {
  content: var(--icon-solid-list);
}

.icon-solid-location-arrow:before {
  content: var(--icon-solid-location-arrow);
}

.icon-solid-lock-open:before {
  content: var(--icon-solid-lock-open);
}

.icon-solid-lock:before {
  content: var(--icon-solid-lock);
}

.icon-solid-long-arrow-alt-down:before {
  content: var(--icon-solid-long-arrow-alt-down);
}

.icon-solid-long-arrow-alt-left:before {
  content: var(--icon-solid-long-arrow-alt-left);
}

.icon-solid-long-arrow-alt-right:before {
  content: var(--icon-solid-long-arrow-alt-right);
}

.icon-solid-long-arrow-alt-up:before {
  content: var(--icon-solid-long-arrow-alt-up);
}

.icon-solid-low-vision:before {
  content: var(--icon-solid-low-vision);
}

.icon-solid-luggage-cart:before {
  content: var(--icon-solid-luggage-cart);
}

.icon-solid-magic:before {
  content: var(--icon-solid-magic);
}

.icon-solid-magnet:before {
  content: var(--icon-solid-magnet);
}

.icon-solid-mail-bulk:before {
  content: var(--icon-solid-mail-bulk);
}

.icon-solid-male:before {
  content: var(--icon-solid-male);
}

.icon-solid-map-marked-alt:before {
  content: var(--icon-solid-map-marked-alt);
}

.icon-solid-map-marked:before {
  content: var(--icon-solid-map-marked);
}

.icon-solid-map-marker-alt:before {
  content: var(--icon-solid-map-marker-alt);
}

.icon-solid-map-marker:before {
  content: var(--icon-solid-map-marker);
}

.icon-solid-map-pin:before {
  content: var(--icon-solid-map-pin);
}

.icon-solid-map-signs:before {
  content: var(--icon-solid-map-signs);
}

.icon-solid-map:before {
  content: var(--icon-solid-map);
}

.icon-solid-marker:before {
  content: var(--icon-solid-marker);
}

.icon-solid-mars-double:before {
  content: var(--icon-solid-mars-double);
}

.icon-solid-mars-stroke-h:before {
  content: var(--icon-solid-mars-stroke-h);
}

.icon-solid-mars-stroke-v:before {
  content: var(--icon-solid-mars-stroke-v);
}

.icon-solid-mars-stroke:before {
  content: var(--icon-solid-mars-stroke);
}

.icon-solid-mars:before {
  content: var(--icon-solid-mars);
}

.icon-solid-mask:before {
  content: var(--icon-solid-mask);
}

.icon-solid-medal:before {
  content: var(--icon-solid-medal);
}

.icon-solid-medkit:before {
  content: var(--icon-solid-medkit);
}

.icon-solid-meh-blank:before {
  content: var(--icon-solid-meh-blank);
}

.icon-solid-meh-rolling-eyes:before {
  content: var(--icon-solid-meh-rolling-eyes);
}

.icon-solid-meh:before {
  content: var(--icon-solid-meh);
}

.icon-solid-memory:before {
  content: var(--icon-solid-memory);
}

.icon-solid-menorah:before {
  content: var(--icon-solid-menorah);
}

.icon-solid-mercury:before {
  content: var(--icon-solid-mercury);
}

.icon-solid-meteor:before {
  content: var(--icon-solid-meteor);
}

.icon-solid-microchip:before {
  content: var(--icon-solid-microchip);
}

.icon-solid-microphone-alt-slash:before {
  content: var(--icon-solid-microphone-alt-slash);
}

.icon-solid-microphone-alt:before {
  content: var(--icon-solid-microphone-alt);
}

.icon-solid-microphone-slash:before {
  content: var(--icon-solid-microphone-slash);
}

.icon-solid-microphone:before {
  content: var(--icon-solid-microphone);
}

.icon-solid-microscope:before {
  content: var(--icon-solid-microscope);
}

.icon-solid-minus-circle:before {
  content: var(--icon-solid-minus-circle);
}

.icon-solid-minus-square:before {
  content: var(--icon-solid-minus-square);
}

.icon-solid-minus:before {
  content: var(--icon-solid-minus);
}

.icon-solid-mitten:before {
  content: var(--icon-solid-mitten);
}

.icon-solid-mobile-alt:before {
  content: var(--icon-solid-mobile-alt);
}

.icon-solid-mobile:before {
  content: var(--icon-solid-mobile);
}

.icon-solid-money-bill-alt:before {
  content: var(--icon-solid-money-bill-alt);
}

.icon-solid-money-bill-wave-alt:before {
  content: var(--icon-solid-money-bill-wave-alt);
}

.icon-solid-money-bill-wave:before {
  content: var(--icon-solid-money-bill-wave);
}

.icon-solid-money-bill:before {
  content: var(--icon-solid-money-bill);
}

.icon-solid-money-check-alt:before {
  content: var(--icon-solid-money-check-alt);
}

.icon-solid-money-check:before {
  content: var(--icon-solid-money-check);
}

.icon-solid-monument:before {
  content: var(--icon-solid-monument);
}

.icon-solid-moon:before {
  content: var(--icon-solid-moon);
}

.icon-solid-mortar-pestle:before {
  content: var(--icon-solid-mortar-pestle);
}

.icon-solid-mosque:before {
  content: var(--icon-solid-mosque);
}

.icon-solid-motorcycle:before {
  content: var(--icon-solid-motorcycle);
}

.icon-solid-mountain:before {
  content: var(--icon-solid-mountain);
}

.icon-solid-mouse-pointer:before {
  content: var(--icon-solid-mouse-pointer);
}

.icon-solid-mug-hot:before {
  content: var(--icon-solid-mug-hot);
}

.icon-solid-music:before {
  content: var(--icon-solid-music);
}

.icon-solid-network-wired:before {
  content: var(--icon-solid-network-wired);
}

.icon-solid-neuter:before {
  content: var(--icon-solid-neuter);
}

.icon-solid-newspaper:before {
  content: var(--icon-solid-newspaper);
}

.icon-solid-not-equal:before {
  content: var(--icon-solid-not-equal);
}

.icon-solid-notes-medical:before {
  content: var(--icon-solid-notes-medical);
}

.icon-solid-object-group:before {
  content: var(--icon-solid-object-group);
}

.icon-solid-object-ungroup:before {
  content: var(--icon-solid-object-ungroup);
}

.icon-solid-oil-can:before {
  content: var(--icon-solid-oil-can);
}

.icon-solid-om:before {
  content: var(--icon-solid-om);
}

.icon-solid-otter:before {
  content: var(--icon-solid-otter);
}

.icon-solid-outdent:before {
  content: var(--icon-solid-outdent);
}

.icon-solid-pager:before {
  content: var(--icon-solid-pager);
}

.icon-solid-paint-brush:before {
  content: var(--icon-solid-paint-brush);
}

.icon-solid-paint-roller:before {
  content: var(--icon-solid-paint-roller);
}

.icon-solid-palette:before {
  content: var(--icon-solid-palette);
}

.icon-solid-pallet:before {
  content: var(--icon-solid-pallet);
}

.icon-solid-paper-plane:before {
  content: var(--icon-solid-paper-plane);
}

.icon-solid-paperclip:before {
  content: var(--icon-solid-paperclip);
}

.icon-solid-parachute-box:before {
  content: var(--icon-solid-parachute-box);
}

.icon-solid-paragraph:before {
  content: var(--icon-solid-paragraph);
}

.icon-solid-parking:before {
  content: var(--icon-solid-parking);
}

.icon-solid-passport:before {
  content: var(--icon-solid-passport);
}

.icon-solid-pastafarianism:before {
  content: var(--icon-solid-pastafarianism);
}

.icon-solid-paste:before {
  content: var(--icon-solid-paste);
}

.icon-solid-pause-circle:before {
  content: var(--icon-solid-pause-circle);
}

.icon-solid-pause:before {
  content: var(--icon-solid-pause);
}

.icon-solid-paw:before {
  content: var(--icon-solid-paw);
}

.icon-solid-peace:before {
  content: var(--icon-solid-peace);
}

.icon-solid-pen-alt:before {
  content: var(--icon-solid-pen-alt);
}

.icon-solid-pen-fancy:before {
  content: var(--icon-solid-pen-fancy);
}

.icon-solid-pen-nib:before {
  content: var(--icon-solid-pen-nib);
}

.icon-solid-pen-square:before {
  content: var(--icon-solid-pen-square);
}

.icon-solid-pen:before {
  content: var(--icon-solid-pen);
}

.icon-solid-pencil-alt:before {
  content: var(--icon-solid-pencil-alt);
}

.icon-solid-pencil-ruler:before {
  content: var(--icon-solid-pencil-ruler);
}

.icon-solid-people-carry:before {
  content: var(--icon-solid-people-carry);
}

.icon-solid-pepper-hot:before {
  content: var(--icon-solid-pepper-hot);
}

.icon-solid-percent:before {
  content: var(--icon-solid-percent);
}

.icon-solid-percentage:before {
  content: var(--icon-solid-percentage);
}

.icon-solid-person-booth:before {
  content: var(--icon-solid-person-booth);
}

.icon-solid-phone-slash:before {
  content: var(--icon-solid-phone-slash);
}

.icon-solid-phone-square:before {
  content: var(--icon-solid-phone-square);
}

.icon-solid-phone-volume:before {
  content: var(--icon-solid-phone-volume);
}

.icon-solid-phone:before {
  content: var(--icon-solid-phone);
}

.icon-solid-piggy-bank:before {
  content: var(--icon-solid-piggy-bank);
}

.icon-solid-pills:before {
  content: var(--icon-solid-pills);
}

.icon-solid-pizza-slice:before {
  content: var(--icon-solid-pizza-slice);
}

.icon-solid-place-of-worship:before {
  content: var(--icon-solid-place-of-worship);
}

.icon-solid-plane-arrival:before {
  content: var(--icon-solid-plane-arrival);
}

.icon-solid-plane-departure:before {
  content: var(--icon-solid-plane-departure);
}

.icon-solid-plane:before {
  content: var(--icon-solid-plane);
}

.icon-solid-play-circle:before {
  content: var(--icon-solid-play-circle);
}

.icon-solid-play:before {
  content: var(--icon-solid-play);
}

.icon-solid-plug:before {
  content: var(--icon-solid-plug);
}

.icon-solid-plus-circle:before {
  content: var(--icon-solid-plus-circle);
}

.icon-solid-plus-square:before {
  content: var(--icon-solid-plus-square);
}

.icon-solid-plus:before {
  content: var(--icon-solid-plus);
}

.icon-solid-podcast:before {
  content: var(--icon-solid-podcast);
}

.icon-solid-poll-h:before {
  content: var(--icon-solid-poll-h);
}

.icon-solid-poll:before {
  content: var(--icon-solid-poll);
}

.icon-solid-poo-storm:before {
  content: var(--icon-solid-poo-storm);
}

.icon-solid-poo:before {
  content: var(--icon-solid-poo);
}

.icon-solid-poop:before {
  content: var(--icon-solid-poop);
}

.icon-solid-portrait:before {
  content: var(--icon-solid-portrait);
}

.icon-solid-pound-sign:before {
  content: var(--icon-solid-pound-sign);
}

.icon-solid-power-off:before {
  content: var(--icon-solid-power-off);
}

.icon-solid-pray:before {
  content: var(--icon-solid-pray);
}

.icon-solid-praying-hands:before {
  content: var(--icon-solid-praying-hands);
}

.icon-solid-prescription-bottle-alt:before {
  content: var(--icon-solid-prescription-bottle-alt);
}

.icon-solid-prescription-bottle:before {
  content: var(--icon-solid-prescription-bottle);
}

.icon-solid-prescription:before {
  content: var(--icon-solid-prescription);
}

.icon-solid-print:before {
  content: var(--icon-solid-print);
}

.icon-solid-procedures:before {
  content: var(--icon-solid-procedures);
}

.icon-solid-project-diagram:before {
  content: var(--icon-solid-project-diagram);
}

.icon-solid-puzzle-piece:before {
  content: var(--icon-solid-puzzle-piece);
}

.icon-solid-qrcode:before {
  content: var(--icon-solid-qrcode);
}

.icon-solid-question-circle:before {
  content: var(--icon-solid-question-circle);
}

.icon-solid-question:before {
  content: var(--icon-solid-question);
}

.icon-solid-quidditch:before {
  content: var(--icon-solid-quidditch);
}

.icon-solid-quote-left:before {
  content: var(--icon-solid-quote-left);
}

.icon-solid-quote-right:before {
  content: var(--icon-solid-quote-right);
}

.icon-solid-quran:before {
  content: var(--icon-solid-quran);
}

.icon-solid-radiation-alt:before {
  content: var(--icon-solid-radiation-alt);
}

.icon-solid-radiation:before {
  content: var(--icon-solid-radiation);
}

.icon-solid-rainbow:before {
  content: var(--icon-solid-rainbow);
}

.icon-solid-random:before {
  content: var(--icon-solid-random);
}

.icon-solid-receipt:before {
  content: var(--icon-solid-receipt);
}

.icon-solid-recycle:before {
  content: var(--icon-solid-recycle);
}

.icon-solid-redo-alt:before {
  content: var(--icon-solid-redo-alt);
}

.icon-solid-redo:before {
  content: var(--icon-solid-redo);
}

.icon-solid-registered:before {
  content: var(--icon-solid-registered);
}

.icon-solid-reply-all:before {
  content: var(--icon-solid-reply-all);
}

.icon-solid-reply:before {
  content: var(--icon-solid-reply);
}

.icon-solid-republican:before {
  content: var(--icon-solid-republican);
}

.icon-solid-restroom:before {
  content: var(--icon-solid-restroom);
}

.icon-solid-retweet:before {
  content: var(--icon-solid-retweet);
}

.icon-solid-ribbon:before {
  content: var(--icon-solid-ribbon);
}

.icon-solid-ring:before {
  content: var(--icon-solid-ring);
}

.icon-solid-road:before {
  content: var(--icon-solid-road);
}

.icon-solid-robot:before {
  content: var(--icon-solid-robot);
}

.icon-solid-rocket:before {
  content: var(--icon-solid-rocket);
}

.icon-solid-route:before {
  content: var(--icon-solid-route);
}

.icon-solid-rss-square:before {
  content: var(--icon-solid-rss-square);
}

.icon-solid-rss:before {
  content: var(--icon-solid-rss);
}

.icon-solid-ruble-sign:before {
  content: var(--icon-solid-ruble-sign);
}

.icon-solid-ruler-combined:before {
  content: var(--icon-solid-ruler-combined);
}

.icon-solid-ruler-horizontal:before {
  content: var(--icon-solid-ruler-horizontal);
}

.icon-solid-ruler-vertical:before {
  content: var(--icon-solid-ruler-vertical);
}

.icon-solid-ruler:before {
  content: var(--icon-solid-ruler);
}

.icon-solid-running:before {
  content: var(--icon-solid-running);
}

.icon-solid-rupee-sign:before {
  content: var(--icon-solid-rupee-sign);
}

.icon-solid-sad-cry:before {
  content: var(--icon-solid-sad-cry);
}

.icon-solid-sad-tear:before {
  content: var(--icon-solid-sad-tear);
}

.icon-solid-satellite-dish:before {
  content: var(--icon-solid-satellite-dish);
}

.icon-solid-satellite:before {
  content: var(--icon-solid-satellite);
}

.icon-solid-save:before {
  content: var(--icon-solid-save);
}

.icon-solid-school:before {
  content: var(--icon-solid-school);
}

.icon-solid-screwdriver:before {
  content: var(--icon-solid-screwdriver);
}

.icon-solid-scroll:before {
  content: var(--icon-solid-scroll);
}

.icon-solid-sd-card:before {
  content: var(--icon-solid-sd-card);
}

.icon-solid-search-dollar:before {
  content: var(--icon-solid-search-dollar);
}

.icon-solid-search-location:before {
  content: var(--icon-solid-search-location);
}

.icon-solid-search-minus:before {
  content: var(--icon-solid-search-minus);
}

.icon-solid-search-plus:before {
  content: var(--icon-solid-search-plus);
}

.icon-solid-search:before {
  content: var(--icon-solid-search);
}

.icon-solid-seedling:before {
  content: var(--icon-solid-seedling);
}

.icon-solid-server:before {
  content: var(--icon-solid-server);
}

.icon-solid-shapes:before {
  content: var(--icon-solid-shapes);
}

.icon-solid-share-alt-square:before {
  content: var(--icon-solid-share-alt-square);
}

.icon-solid-share-alt:before {
  content: var(--icon-solid-share-alt);
}

.icon-solid-share-square:before {
  content: var(--icon-solid-share-square);
}

.icon-solid-share:before {
  content: var(--icon-solid-share);
}

.icon-solid-shekel-sign:before {
  content: var(--icon-solid-shekel-sign);
}

.icon-solid-shield-alt:before {
  content: var(--icon-solid-shield-alt);
}

.icon-solid-ship:before {
  content: var(--icon-solid-ship);
}

.icon-solid-shipping-fast:before {
  content: var(--icon-solid-shipping-fast);
}

.icon-solid-shoe-prints:before {
  content: var(--icon-solid-shoe-prints);
}

.icon-solid-shopping-bag:before {
  content: var(--icon-solid-shopping-bag);
}

.icon-solid-shopping-basket:before {
  content: var(--icon-solid-shopping-basket);
}

.icon-solid-shopping-cart:before {
  content: var(--icon-solid-shopping-cart);
}

.icon-solid-shower:before {
  content: var(--icon-solid-shower);
}

.icon-solid-shuttle-van:before {
  content: var(--icon-solid-shuttle-van);
}

.icon-solid-sign-in-alt:before {
  content: var(--icon-solid-sign-in-alt);
}

.icon-solid-sign-language:before {
  content: var(--icon-solid-sign-language);
}

.icon-solid-sign-out-alt:before {
  content: var(--icon-solid-sign-out-alt);
}

.icon-solid-sign:before {
  content: var(--icon-solid-sign);
}

.icon-solid-signal:before {
  content: var(--icon-solid-signal);
}

.icon-solid-signature:before {
  content: var(--icon-solid-signature);
}

.icon-solid-sim-card:before {
  content: var(--icon-solid-sim-card);
}

.icon-solid-sitemap:before {
  content: var(--icon-solid-sitemap);
}

.icon-solid-skating:before {
  content: var(--icon-solid-skating);
}

.icon-solid-skiing-nordic:before {
  content: var(--icon-solid-skiing-nordic);
}

.icon-solid-skiing:before {
  content: var(--icon-solid-skiing);
}

.icon-solid-skull-crossbones:before {
  content: var(--icon-solid-skull-crossbones);
}

.icon-solid-skull:before {
  content: var(--icon-solid-skull);
}

.icon-solid-slash:before {
  content: var(--icon-solid-slash);
}

.icon-solid-sleigh:before {
  content: var(--icon-solid-sleigh);
}

.icon-solid-sliders-h:before {
  content: var(--icon-solid-sliders-h);
}

.icon-solid-smile-beam:before {
  content: var(--icon-solid-smile-beam);
}

.icon-solid-smile-wink:before {
  content: var(--icon-solid-smile-wink);
}

.icon-solid-smile:before {
  content: var(--icon-solid-smile);
}

.icon-solid-smog:before {
  content: var(--icon-solid-smog);
}

.icon-solid-smoking-ban:before {
  content: var(--icon-solid-smoking-ban);
}

.icon-solid-smoking:before {
  content: var(--icon-solid-smoking);
}

.icon-solid-sms:before {
  content: var(--icon-solid-sms);
}

.icon-solid-snowboarding:before {
  content: var(--icon-solid-snowboarding);
}

.icon-solid-snowflake:before {
  content: var(--icon-solid-snowflake);
}

.icon-solid-snowman:before {
  content: var(--icon-solid-snowman);
}

.icon-solid-snowplow:before {
  content: var(--icon-solid-snowplow);
}

.icon-solid-socks:before {
  content: var(--icon-solid-socks);
}

.icon-solid-solar-panel:before {
  content: var(--icon-solid-solar-panel);
}

.icon-solid-sort-alpha-down:before {
  content: var(--icon-solid-sort-alpha-down);
}

.icon-solid-sort-alpha-up:before {
  content: var(--icon-solid-sort-alpha-up);
}

.icon-solid-sort-amount-down:before {
  content: var(--icon-solid-sort-amount-down);
}

.icon-solid-sort-amount-up:before {
  content: var(--icon-solid-sort-amount-up);
}

.icon-solid-sort-down:before {
  content: var(--icon-solid-sort-down);
}

.icon-solid-sort-numeric-down:before {
  content: var(--icon-solid-sort-numeric-down);
}

.icon-solid-sort-numeric-up:before {
  content: var(--icon-solid-sort-numeric-up);
}

.icon-solid-sort-up:before {
  content: var(--icon-solid-sort-up);
}

.icon-solid-sort:before {
  content: var(--icon-solid-sort);
}

.icon-solid-spa:before {
  content: var(--icon-solid-spa);
}

.icon-solid-space-shuttle:before {
  content: var(--icon-solid-space-shuttle);
}

.icon-solid-spider:before {
  content: var(--icon-solid-spider);
}

.icon-solid-spinner:before {
  content: var(--icon-solid-spinner);
}

.icon-solid-splotch:before {
  content: var(--icon-solid-splotch);
}

.icon-solid-spray-can:before {
  content: var(--icon-solid-spray-can);
}

.icon-solid-square-full:before {
  content: var(--icon-solid-square-full);
}

.icon-solid-square-root-alt:before {
  content: var(--icon-solid-square-root-alt);
}

.icon-solid-square:before {
  content: var(--icon-solid-square);
}

.icon-solid-stamp:before {
  content: var(--icon-solid-stamp);
}

.icon-solid-star-and-crescent:before {
  content: var(--icon-solid-star-and-crescent);
}

.icon-solid-star-half-alt:before {
  content: var(--icon-solid-star-half-alt);
}

.icon-solid-star-half:before {
  content: var(--icon-solid-star-half);
}

.icon-solid-star-of-david:before {
  content: var(--icon-solid-star-of-david);
}

.icon-solid-star-of-life:before {
  content: var(--icon-solid-star-of-life);
}

.icon-solid-star:before {
  content: var(--icon-solid-star);
}

.icon-solid-step-backward:before {
  content: var(--icon-solid-step-backward);
}

.icon-solid-step-forward:before {
  content: var(--icon-solid-step-forward);
}

.icon-solid-stethoscope:before {
  content: var(--icon-solid-stethoscope);
}

.icon-solid-sticky-note:before {
  content: var(--icon-solid-sticky-note);
}

.icon-solid-stop-circle:before {
  content: var(--icon-solid-stop-circle);
}

.icon-solid-stop:before {
  content: var(--icon-solid-stop);
}

.icon-solid-stopwatch:before {
  content: var(--icon-solid-stopwatch);
}

.icon-solid-store-alt:before {
  content: var(--icon-solid-store-alt);
}

.icon-solid-store:before {
  content: var(--icon-solid-store);
}

.icon-solid-stream:before {
  content: var(--icon-solid-stream);
}

.icon-solid-street-view:before {
  content: var(--icon-solid-street-view);
}

.icon-solid-strikethrough:before {
  content: var(--icon-solid-strikethrough);
}

.icon-solid-stroopwafel:before {
  content: var(--icon-solid-stroopwafel);
}

.icon-solid-subscript:before {
  content: var(--icon-solid-subscript);
}

.icon-solid-subway:before {
  content: var(--icon-solid-subway);
}

.icon-solid-suitcase-rolling:before {
  content: var(--icon-solid-suitcase-rolling);
}

.icon-solid-suitcase:before {
  content: var(--icon-solid-suitcase);
}

.icon-solid-sun:before {
  content: var(--icon-solid-sun);
}

.icon-solid-superscript:before {
  content: var(--icon-solid-superscript);
}

.icon-solid-surprise:before {
  content: var(--icon-solid-surprise);
}

.icon-solid-swatchbook:before {
  content: var(--icon-solid-swatchbook);
}

.icon-solid-swimmer:before {
  content: var(--icon-solid-swimmer);
}

.icon-solid-swimming-pool:before {
  content: var(--icon-solid-swimming-pool);
}

.icon-solid-synagogue:before {
  content: var(--icon-solid-synagogue);
}

.icon-solid-sync-alt:before {
  content: var(--icon-solid-sync-alt);
}

.icon-solid-sync:before {
  content: var(--icon-solid-sync);
}

.icon-solid-syringe:before {
  content: var(--icon-solid-syringe);
}

.icon-solid-table-tennis:before {
  content: var(--icon-solid-table-tennis);
}

.icon-solid-table:before {
  content: var(--icon-solid-table);
}

.icon-solid-tablet-alt:before {
  content: var(--icon-solid-tablet-alt);
}

.icon-solid-tablet:before {
  content: var(--icon-solid-tablet);
}

.icon-solid-tablets:before {
  content: var(--icon-solid-tablets);
}

.icon-solid-tachometer-alt:before {
  content: var(--icon-solid-tachometer-alt);
}

.icon-solid-tag:before {
  content: var(--icon-solid-tag);
}

.icon-solid-tags:before {
  content: var(--icon-solid-tags);
}

.icon-solid-tape:before {
  content: var(--icon-solid-tape);
}

.icon-solid-tasks:before {
  content: var(--icon-solid-tasks);
}

.icon-solid-taxi:before {
  content: var(--icon-solid-taxi);
}

.icon-solid-teeth-open:before {
  content: var(--icon-solid-teeth-open);
}

.icon-solid-teeth:before {
  content: var(--icon-solid-teeth);
}

.icon-solid-temperature-high:before {
  content: var(--icon-solid-temperature-high);
}

.icon-solid-temperature-low:before {
  content: var(--icon-solid-temperature-low);
}

.icon-solid-tenge:before {
  content: var(--icon-solid-tenge);
}

.icon-solid-terminal:before {
  content: var(--icon-solid-terminal);
}

.icon-solid-text-height:before {
  content: var(--icon-solid-text-height);
}

.icon-solid-text-width:before {
  content: var(--icon-solid-text-width);
}

.icon-solid-th-large:before {
  content: var(--icon-solid-th-large);
}

.icon-solid-th-list:before {
  content: var(--icon-solid-th-list);
}

.icon-solid-th:before {
  content: var(--icon-solid-th);
}

.icon-solid-theater-masks:before {
  content: var(--icon-solid-theater-masks);
}

.icon-solid-thermometer-empty:before {
  content: var(--icon-solid-thermometer-empty);
}

.icon-solid-thermometer-full:before {
  content: var(--icon-solid-thermometer-full);
}

.icon-solid-thermometer-half:before {
  content: var(--icon-solid-thermometer-half);
}

.icon-solid-thermometer-quarter:before {
  content: var(--icon-solid-thermometer-quarter);
}

.icon-solid-thermometer-three-quarters:before {
  content: var(--icon-solid-thermometer-three-quarters);
}

.icon-solid-thermometer:before {
  content: var(--icon-solid-thermometer);
}

.icon-solid-thumbs-down:before {
  content: var(--icon-solid-thumbs-down);
}

.icon-solid-thumbs-up:before {
  content: var(--icon-solid-thumbs-up);
}

.icon-solid-thumbtack:before {
  content: var(--icon-solid-thumbtack);
}

.icon-solid-ticket-alt:before {
  content: var(--icon-solid-ticket-alt);
}

.icon-solid-times-circle:before {
  content: var(--icon-solid-times-circle);
}

.icon-solid-times:before {
  content: var(--icon-solid-times);
}

.icon-solid-tint-slash:before {
  content: var(--icon-solid-tint-slash);
}

.icon-solid-tint:before {
  content: var(--icon-solid-tint);
}

.icon-solid-tired:before {
  content: var(--icon-solid-tired);
}

.icon-solid-toggle-off:before {
  content: var(--icon-solid-toggle-off);
}

.icon-solid-toggle-on:before {
  content: var(--icon-solid-toggle-on);
}

.icon-solid-toilet-paper:before {
  content: var(--icon-solid-toilet-paper);
}

.icon-solid-toilet:before {
  content: var(--icon-solid-toilet);
}

.icon-solid-toolbox:before {
  content: var(--icon-solid-toolbox);
}

.icon-solid-tools:before {
  content: var(--icon-solid-tools);
}

.icon-solid-tooth:before {
  content: var(--icon-solid-tooth);
}

.icon-solid-torah:before {
  content: var(--icon-solid-torah);
}

.icon-solid-torii-gate:before {
  content: var(--icon-solid-torii-gate);
}

.icon-solid-tractor:before {
  content: var(--icon-solid-tractor);
}

.icon-solid-trademark:before {
  content: var(--icon-solid-trademark);
}

.icon-solid-traffic-light:before {
  content: var(--icon-solid-traffic-light);
}

.icon-solid-train:before {
  content: var(--icon-solid-train);
}

.icon-solid-tram:before {
  content: var(--icon-solid-tram);
}

.icon-solid-transgender-alt:before {
  content: var(--icon-solid-transgender-alt);
}

.icon-solid-transgender:before {
  content: var(--icon-solid-transgender);
}

.icon-solid-trash-alt:before {
  content: var(--icon-solid-trash-alt);
}

.icon-solid-trash-restore-alt:before {
  content: var(--icon-solid-trash-restore-alt);
}

.icon-solid-trash-restore:before {
  content: var(--icon-solid-trash-restore);
}

.icon-solid-trash:before {
  content: var(--icon-solid-trash);
}

.icon-solid-tree:before {
  content: var(--icon-solid-tree);
}

.icon-solid-trophy:before {
  content: var(--icon-solid-trophy);
}

.icon-solid-truck-loading:before {
  content: var(--icon-solid-truck-loading);
}

.icon-solid-truck-monster:before {
  content: var(--icon-solid-truck-monster);
}

.icon-solid-truck-moving:before {
  content: var(--icon-solid-truck-moving);
}

.icon-solid-truck-pickup:before {
  content: var(--icon-solid-truck-pickup);
}

.icon-solid-truck:before {
  content: var(--icon-solid-truck);
}

.icon-solid-tshirt:before {
  content: var(--icon-solid-tshirt);
}

.icon-solid-tty:before {
  content: var(--icon-solid-tty);
}

.icon-solid-tv:before {
  content: var(--icon-solid-tv);
}

.icon-solid-umbrella-beach:before {
  content: var(--icon-solid-umbrella-beach);
}

.icon-solid-umbrella:before {
  content: var(--icon-solid-umbrella);
}

.icon-solid-underline:before {
  content: var(--icon-solid-underline);
}

.icon-solid-undo-alt:before {
  content: var(--icon-solid-undo-alt);
}

.icon-solid-undo:before {
  content: var(--icon-solid-undo);
}

.icon-solid-universal-access:before {
  content: var(--icon-solid-universal-access);
}

.icon-solid-university:before {
  content: var(--icon-solid-university);
}

.icon-solid-unlink:before {
  content: var(--icon-solid-unlink);
}

.icon-solid-unlock-alt:before {
  content: var(--icon-solid-unlock-alt);
}

.icon-solid-unlock:before {
  content: var(--icon-solid-unlock);
}

.icon-solid-upload:before {
  content: var(--icon-solid-upload);
}

.icon-solid-user-alt-slash:before {
  content: var(--icon-solid-user-alt-slash);
}

.icon-solid-user-alt:before {
  content: var(--icon-solid-user-alt);
}

.icon-solid-user-astronaut:before {
  content: var(--icon-solid-user-astronaut);
}

.icon-solid-user-check:before {
  content: var(--icon-solid-user-check);
}

.icon-solid-user-circle:before {
  content: var(--icon-solid-user-circle);
}

.icon-solid-user-clock:before {
  content: var(--icon-solid-user-clock);
}

.icon-solid-user-cog:before {
  content: var(--icon-solid-user-cog);
}

.icon-solid-user-edit:before {
  content: var(--icon-solid-user-edit);
}

.icon-solid-user-friends:before {
  content: var(--icon-solid-user-friends);
}

.icon-solid-user-graduate:before {
  content: var(--icon-solid-user-graduate);
}

.icon-solid-user-injured:before {
  content: var(--icon-solid-user-injured);
}

.icon-solid-user-lock:before {
  content: var(--icon-solid-user-lock);
}

.icon-solid-user-md:before {
  content: var(--icon-solid-user-md);
}

.icon-solid-user-minus:before {
  content: var(--icon-solid-user-minus);
}

.icon-solid-user-ninja:before {
  content: var(--icon-solid-user-ninja);
}

.icon-solid-user-nurse:before {
  content: var(--icon-solid-user-nurse);
}

.icon-solid-user-plus:before {
  content: var(--icon-solid-user-plus);
}

.icon-solid-user-secret:before {
  content: var(--icon-solid-user-secret);
}

.icon-solid-user-shield:before {
  content: var(--icon-solid-user-shield);
}

.icon-solid-user-slash:before {
  content: var(--icon-solid-user-slash);
}

.icon-solid-user-tag:before {
  content: var(--icon-solid-user-tag);
}

.icon-solid-user-tie:before {
  content: var(--icon-solid-user-tie);
}

.icon-solid-user-times:before {
  content: var(--icon-solid-user-times);
}

.icon-solid-user:before {
  content: var(--icon-solid-user);
}

.icon-solid-users-cog:before {
  content: var(--icon-solid-users-cog);
}

.icon-solid-users:before {
  content: var(--icon-solid-users);
}

.icon-solid-utensil-spoon:before {
  content: var(--icon-solid-utensil-spoon);
}

.icon-solid-utensils:before {
  content: var(--icon-solid-utensils);
}

.icon-solid-vector-square:before {
  content: var(--icon-solid-vector-square);
}

.icon-solid-venus-double:before {
  content: var(--icon-solid-venus-double);
}

.icon-solid-venus-mars:before {
  content: var(--icon-solid-venus-mars);
}

.icon-solid-venus:before {
  content: var(--icon-solid-venus);
}

.icon-solid-vial:before {
  content: var(--icon-solid-vial);
}

.icon-solid-vials:before {
  content: var(--icon-solid-vials);
}

.icon-solid-video-slash:before {
  content: var(--icon-solid-video-slash);
}

.icon-solid-video:before {
  content: var(--icon-solid-video);
}

.icon-solid-vihara:before {
  content: var(--icon-solid-vihara);
}

.icon-solid-volleyball-ball:before {
  content: var(--icon-solid-volleyball-ball);
}

.icon-solid-volume-down:before {
  content: var(--icon-solid-volume-down);
}

.icon-solid-volume-mute:before {
  content: var(--icon-solid-volume-mute);
}

.icon-solid-volume-off:before {
  content: var(--icon-solid-volume-off);
}

.icon-solid-volume-up:before {
  content: var(--icon-solid-volume-up);
}

.icon-solid-vote-yea:before {
  content: var(--icon-solid-vote-yea);
}

.icon-solid-vr-cardboard:before {
  content: var(--icon-solid-vr-cardboard);
}

.icon-solid-walking:before {
  content: var(--icon-solid-walking);
}

.icon-solid-wallet:before {
  content: var(--icon-solid-wallet);
}

.icon-solid-warehouse:before {
  content: var(--icon-solid-warehouse);
}

.icon-solid-water:before {
  content: var(--icon-solid-water);
}

.icon-solid-weight-hanging:before {
  content: var(--icon-solid-weight-hanging);
}

.icon-solid-weight:before {
  content: var(--icon-solid-weight);
}

.icon-solid-wheelchair:before {
  content: var(--icon-solid-wheelchair);
}

.icon-solid-wifi:before {
  content: var(--icon-solid-wifi);
}

.icon-solid-wind:before {
  content: var(--icon-solid-wind);
}

.icon-solid-window-close:before {
  content: var(--icon-solid-window-close);
}

.icon-solid-window-maximize:before {
  content: var(--icon-solid-window-maximize);
}

.icon-solid-window-minimize:before {
  content: var(--icon-solid-window-minimize);
}

.icon-solid-window-restore:before {
  content: var(--icon-solid-window-restore);
}

.icon-solid-wine-bottle:before {
  content: var(--icon-solid-wine-bottle);
}

.icon-solid-wine-glass-alt:before {
  content: var(--icon-solid-wine-glass-alt);
}

.icon-solid-wine-glass:before {
  content: var(--icon-solid-wine-glass);
}

.icon-solid-won-sign:before {
  content: var(--icon-solid-won-sign);
}

.icon-solid-wrench:before {
  content: var(--icon-solid-wrench);
}

.icon-solid-x-ray:before {
  content: var(--icon-solid-x-ray);
}

.icon-solid-yen-sign:before {
  content: var(--icon-solid-yen-sign);
}

.icon-solid-yin-yang:before {
  content: var(--icon-solid-yin-yang);
}

.icon-xs {
  font-size: 0.75em;
}

.icon-sm {
  font-size: 0.875em;
}

.icon-1x {
  font-size: 1em;
}

.icon-2x {
  font-size: 2em;
}

.icon-3x {
  font-size: 3em;
}

.icon-4x {
  font-size: 4em;
}

.icon-5x {
  font-size: 5em;
}

.icon-6x {
  font-size: 6em;
}

.icon-7x {
  font-size: 7em;
}

.icon-8x {
  font-size: 8em;
}

.icon-9x {
  font-size: 9em;
}

.icon-10x {
  font-size: 10em;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.icon-spin {
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}

.icon-pulse {
  -webkit-animation: spin 1s infinite steps(8);
  animation: spin 1s infinite steps(8);
}

.icon-rotate-90 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.icon-rotate-180 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.icon-rotate-270 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.icon-flip-horizontal {
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.icon-flip-vertical {
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}

.icon-flip-both, .icon-flip-horizontal.icon-flip-vertical {
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}

:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical,
:root .icon-flip-both {
  -webkit-filter: none;
  filter: none;
}
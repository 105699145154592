#informations-qrcode {
	height: 90%;
	max-width: 80%;	
}

@media (max-width: 40rem) {
	#informations-qrcode {
		height: 100%;
		max-width: 100%;
		margin: 0;
		padding: 0;
	}
}

#preview-qrcode {
	width: 376px;
	height: 168px;
	transform: rotate(270deg);
}
[page="user"] {
	.page-subtitle {
		font-size: 18px;
		margin-bottom: 1rem;
		border-bottom: 1px solid #dee2e6;
		padding-bottom: 0.5rem;
		font-weight: 600;
	}

	#profile-picture-preview {
		img {
			max-width: 100%;
			max-height: 100%;
		}
	}
}

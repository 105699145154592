.home-mobile {

    display: flex;
    flex-direction: column;
    background-color: #F2F2F2;

    .title {

        display: flex;
        flex-direction: column;

        min-height: 150px;

        padding: 1.5rem;
        margin: 1rem;

        border-radius: 10px;

        color: white;

        font-size: 18px;
        font-weight: bold;

        background-image: url('../../../img/logo_mobile.png');
        background-position: 100% 0%;
        background-repeat: no-repeat;
        background-color: #3586FF;

        .login {
            margin-top: auto;
        }

    }

    .buttons {

        display: flex;
        overflow-x: auto;
        flex-wrap: wrap;

        padding: 0.75rem;

        &>div {
            display: flex;
            align-items: center;

            width: calc(50% - 10px);

            max-height: 53px;

            padding-top: 1rem;
            padding-bottom: 1rem;
            padding-left: 1.25rem;
            padding-right: 1.25rem;

            margin: 0.25rem;
            border-radius: 10px;
            background-color: white;

            font-weight: bold;
            font-size: 14px;

            i {
                margin-right: 0.5rem;
                min-width: 1.5rem;
            }

            &[data-link="products"],
            &[data-link="sites_projects"] {
                i {
                    color: #FA3E3E;
                }
            }

            &[data-link="customers_providers"],
            &[data-link="deliveries"] {
                i {
                    color: #3586FF;
                }
            }

            &[data-link="quotes"],
            &[data-link="stocks"] {
                i {
                    color: #A2A2A2;
                }
            }

            &[data-link="interventions"] {
                i {
                    color: #33C257;
                }
            }
        }
    }

}
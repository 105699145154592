.page-tab-mobile {
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 1px 1px 0px;

    font-size: 14px;
    font-weight: 600;


    &:not(:last-child) {
        margin-bottom: 0.5rem;
    }
}
.custom-multiselect select {
	width: 100%;
	height: 100%;
}

.custom-multiselect select optgroup {
	padding-left: 10px;
	padding-right: 10px;
}

.custom-multiselect select option {
	height: 50px;
	text-align: center;
	padding-top: 15px;
}

.custom-multiselect select option:hover {
	background-color: #e5e5e5;
}

.custom-multiselect {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.sites-multiselect {
	height: 500px;
	max-height: 500px;
}

.sites-multiselect .custom-multiselect {
	max-height: 440px;
}
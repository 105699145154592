.js-copytextarea,
.copyStatus {
  position: absolute;
  top: -10000px;
}

.copyStatus {
  text-align: center;
  font-size: 50px;
  vertical-align: middle;
  transition: opacity 500ms;
  opacity: 0;

  &.show {
    opacity: 1;
  }

  &.hide {
    opacity: 0;
  }
}

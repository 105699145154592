.box {
	background-color: #f1f3f4;
	border-radius: 10px;
	padding: 1.5rem;
	margin-bottom: 10px;
	cursor: pointer;

	.box-title {
		font-size: 1.2em;
		font-weight: bold;
		display: flex;
		align-items: center;

		.btn {
			padding: 0;
		}
	}

	&:hover {
		text-decoration: none;
		background-color: #77a5ff;
	}

	&.active {
		color: white;
		background-color: #3475f8;

		* {
			color: white !important;
		}

		.dropdown-menu {
			* {
				color: black !important;
			}
		}
	}

	.box-description {
		margin-top: 10px;
		font-size: 1em;
	}

	.box-config {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: 10px;
		font-size: 1em;
	}
}

.select2-container {

    .selection {
        width: 100%;
        height: 100%;
    }

    &[data-select2-id] {
        width: 100% !important;
        color: #495057 !important;
        border: 1px solid #ced4da !important;
        border-radius: 0.25rem !important;
    }

    .select2-results__option {
        white-space: nowrap !important;
    }

    .select2-selection {
        display: flex;
    }

    .select2-selection__rendered {
        height: 100% !important;
        display: flex !important;
        align-items: center !important;
    }

    .select2-selection__arrow {
        height: 100% !important;
    }

    .select2-selection__clear {
        height: 100% !important;
    }

    .select2-selection__choice {
        margin-top: 0 !important;
    }

    .select2-selection {
        border: none !important;
    }

    .select2-selection--multiple {
        .select2-selection__clear {
            margin-top: 0 !important;
        }
    }

    .select2-selection--single,
    .select2-selection--multiple {
        height: 100%;
        min-height: initial;
        padding-bottom: 0;
    }
}

#app-content,
[edit-group] {
    .select2-container {
        .select2-dropdown {
            &.select2-dropdown--below {
                //width: auto !important;
                max-width: 500px;
                min-width: 70px;
            }
        }
    }

    .select2-results__option {
        line-height: 12px;
        font-size: 12px;
        padding: 4px;
    }
}

.w-100.ag-input-text-wrapper * {
    height: 100%;
    box-shadow: none;
    border-radius: 0px;
}

.w-100.ag-input-text-wrapper {
    height: 100%;

    .select2-container {
        margin-top: -3px;
    }
}
[page="settings-companies"] {

    display: flex;
    width: 100%;
    height: 100%;

    overflow: hidden;

    .companies-list {
        min-width: 200px;
        width: 200px;
        height: 100%;
        padding: 1rem;

        overflow: auto;

        .companies-list-item {
            &.selected {
                font-weight: 600;
                color: #3475F8;
                border-right: 4px solid #3475F8;
            }

            &:hover {
                opacity: 0.8;
            }

            cursor: pointer;
            background: white;
            padding: 0.5rem 0.75rem;
            border-radius: 8px;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 0.75rem;
        }

        #no-company-message {
            display: flex;
            justify-content: center;
            border-radius: 8px;
            background: #f7f7f7;
            padding: 0.5rem 0.75rem;
            color: #7c7c7c;
        }
    }

    .company-content {
        width: 100%;
        height: 100%;
        padding: 1rem;

        overflow: auto;
    }

    .company-block {
        &:not(:last-child) {
            margin-bottom: 2rem;
        }

        .company-block-subtitle {
            &:not(:first-child) {
                margin-top: 1rem;
            }

            margin-bottom: 0.25rem;
            font-size: 18px;
            font-weight: 600;
        }

        .company-block-header {
            margin-bottom: 1rem;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #DADADA;
            height: 40px;
            display: flex;
            align-items: center;

            .company-block-title {
                font-size: 22px;
                font-weight: 500;

                #selection-message {
                    color: #7c7c7c;
                }

            }

            .company-block-buttons {
                display: flex;
                gap: 1rem;
            }

        }

        .company-block-body {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            .company-block-information {
                display: flex;
                border-bottom: 1px dotted #dadada;
                padding: 0.5rem 0;
            }

            .company-block-label {
                font-weight: 600;
                width: 50%;
            }

            .company-block-value {
                color: #7c7c7c;
                width: 50%;
            }
        }

        #company-logo {
            height: 200px;
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 1rem;
            align-items: center;
            color: #7c7c7c;
            border: 1px dashed #d1d1d1;
            border-radius: 0.5rem;

            img {
                max-width: 100%;
                max-height: 100%;
            }

        }

        #company-address-list {
            height: 250px;
            width: 100%;
        }

        #no-address-message {
            width: 100%;
            padding: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

}
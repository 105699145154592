:root {
	--ap-aside-background: #363a45;
	--ap-navbar-background: #363a45;
	--ap-page-title-background: #3475f8;
	--ap-cover-page-aside-background: #3475f8;
	--ap-cover-page-background: #3475f8;
}

:root .is-admin {
	--ap-aside-background: #3f51b5;
	--ap-navbar-background: #3f51b5;
	--ap-page-title-background: #363a45;
	--ap-cover-page-aside-background: #363a45;
	--ap-cover-page-background: #363a45;
}

/*
	Masquage de l'onglet "utilisateur" de Core
*/
[permission="MAIN_SETTINGS.USERS_SYSTEM.USERS.SHOW"] {
	display: none;
}

#login-btn {
	--background: #3475f8;
	--color: white;
	--border-color: white;
	--hover-background: #1762fa;
	--hover-color: white;
	--active-background: #1762fa;
	--active-color: white;
}

#login-form {
	height: 100%;
	justify-content: center;
}

.btn-white {
	color: black;
}

.dropdown-item {
	display: flex;
	align-items: center;
	cursor: pointer;

	ap-icon {
		margin-right: 0.5rem;
	}
}

.modal {
	&.modal-static {
		.modal-dialog {
			transform: none !important;
		}
	}
}

button {
	outline: none !important;
}

.btn-xs {
	--size: 2.285em !important;
	font-size: 0.8em !important;
}

#aside {
	.aside-nav {
		a {
			cursor: pointer;
		}

		.separator {
			margin-left: 0.7rem;
			margin-right: 0.7rem;

			&::before,
			&::after {
				min-width: 5.25rem !important;
			}

			.label {
				font-size: 1.1rem;
				margin-right: 1.5rem !important;
				margin-left: 1.5rem !important;
			}
		}
	}
}

.dropdown-item {
	&.disabled {
		color: #6c757d !important;
	}
}

.tabs-container {
	align-items: end;

	.btn-tab {
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}

	.tab-active {
		background-color: #f1f5f8;
		z-index: 900 !important;
	}
}

#app-content {
	#page-title {
		.title {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	#page-content {
		background-color: #f1f5f8;
	}
}

#app {
	&.no-navbar {
		padding-top: 0;
	}
}

.navbar-bottom {
	background: white;
}

.slimscroll-container {
	overflow: auto;
}
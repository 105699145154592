.card-custom {
    width: 270px;
    border: 0;
    border-radius: 0.25rem;
    box-shadow: 0 10px 40px 0 #3e396b12, 0 2px 9px 0 #3e396b0f;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

.card-title {
    font-size: 12px;
}

.card-icon {
    font-size: 1.5em;
}

.card-value {
    font-weight: 600;
}

.commands-subtitle {
    font-variant: all-small-caps;
    font-weight: 500;
}